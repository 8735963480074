import createTheme from "styled-components-theme";
import { createGlobalStyle } from "styled-components/macro";
import { popoverTooltip } from "./ExtensionV2/styles/zIndexes";

export const Colors = {
  primary: "#3399FF",
  secondary: "#121212",
  primaryAction: "#FF9842",
  current: "#4e79a7",
  previous: "#dcdcdc",
  red: "#FF5B48",
  orange: "#f2711c",
  yellow: "#fbbd08",
  olive: "#b5cc18",
  green: "#00B15B",
  teal: "#00b5ad",
  blue: "#2155b0",
  violet: "#6435c9",
  purple: "#a333c8",
  pink: "#e03997",
  brown: "#a5673f",
  lightGrey: "#FAFAFA",
  grey: "#CCCCCC",
  black: "#000000",
  white: "#FFFFFF",

  chartCurrent: "#0277BD",
  chartPrevious: "#999",

  positive: "#000",
  negative: "#FF5B48",

  border: "rgba(0, 0, 0, 0.13)",
  solidBorder: "#DDDDDD",
  internalBorder: "rgba(0, 0, 0, 0.06)",
  selectedBorder: "#51A7E8",

  link: "#4078C0",
  linkHover: "#4078C0",

  tableHeaderBackground: "#F9FAFB",
  tableHeaderHoverBackground: "#F4F4F4",

  text: "rgba(0, 0, 0, 0.87)"
};

export const Theme = createTheme(...Object.keys(Colors));

// The &:has(>div.disablePerspective) selector applies properties to work around a bug found in
// Chrome 121.0.6167.139 that was causing weird rendering issues with some of our modals. This
// can potentially be removed in the future if the bug is fixed. Check that AdvancedKeywordPicker
// renders correctly after removing this.
export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    background-color: #f4f6f8;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
  }
  
  a {
    color: #005c9c;
    &:hover {
      color: #005c9c;
      text-decoration: underline;
    }
  }

  .button-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #005c9c;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
    &:hover {
      color: #005c9c;
      text-decoration: underline;
    }
    /* Semantic UI font family */
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  .ui.button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  .testimonial {
    background-color: #fff;
    tborder-radius: 20px;
    tbox-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    overflow: hidden;
    max-width: 100%;
    width: 400px;
  }

  .testimonial-body {
    padding: 40px 40px 80px;
  }

  .testimonial-body p {
    color: #555;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }

  .testimonial-body .fa-quote-right {
    color: #eee;
    font-size: 40px;
    float: right;
  }

  .testimonial-footer {
    background-color: #eee;
    color: #fff;
    padding: 25px;
    text-align: center;
  }

  .testimonial-footer img {
    border-radius: 50%;
    border: 5px solid #fff;
    height: 120px;
    width: 120px;
    margin-top: -100px;
  }

  .testimonial-footer h3 {
    margin: 10px 0;
    letter-spacing: 2px;
    color: #333;
  }

  .testimonial-footer h4 {
    color: #777;
    margin: 0;
    letter-spacing: 1px;
    font-size: 15px
  }

  // Highcharts tooltip (dynamically added at the body) in a modal requires this
  &&& body > .highcharts-tooltip-container {
    z-index: ${popoverTooltip} !important;
  }

  div {
    &:has(>div.disablePerspective) {
      perspective: none !important;
    }
  }
`;

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  DateRangeV2,
  Date as DateProto
} from "Common/proto/common/datetime_pb";
import {
  GetFacebookAdPerformanceReply,
  GetFacebookAdPerformanceRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Facebook_pb";
import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { GRPCWebClient } from "Common/utils/grpc";
import { None } from "Common/utils/tsUtils";
import { useMemo } from "react";

export const useFacebookPerformanceData = (
  adIds: Array<string>,
  startDate: string,
  endDate: string,
  enabled?: boolean
): UseQueryResult<GetFacebookAdPerformanceReply.AsObject> => {
  const siteAlias = getSiteAliasFromURL("/t");

  if (!startDate || !endDate) {
    throw new Error("startDate and endDate are required");
  }

  const queryStart = new Date(Date.parse(startDate));
  const startYear = queryStart.getFullYear();
  // month is 0-indexed...
  const startMonth = queryStart.getMonth() + 1;
  const startDay = queryStart.getDate();

  const queryEnd = new Date(Date.parse(endDate));
  const endYear = queryEnd.getFullYear();
  const endMonth = queryEnd.getMonth() + 1;
  const endDay = queryEnd.getDate();

  return useQuery({
    queryKey: ["facebookAdPerformance", siteAlias, startDate, endDate, adIds],
    staleTime: 15 * 60 * 1_000, // 15 minutes
    enabled: enabled && !!siteAlias,
    queryFn: async () => {
      const startDate = new DateProto()
        .setYear(startYear)
        .setMonth(startMonth)
        .setDay(startDay);

      const endDate = new DateProto()
        .setYear(endYear)
        .setMonth(endMonth)
        .setDay(endDay);

      const dateRange = new DateRangeV2()
        .setStartDate(startDate)
        .setEndDate(endDate);

      const req = new GetFacebookAdPerformanceRequest();
      req.setSiteAlias(siteAlias);
      req.setAdIdsList(adIds);
      req.setDateRange(dateRange);

      const reply = await GRPCWebClient.getFacebookAdPerformance(req, {});
      return reply.toObject();
    }
  });
};

export type FB_DATA_ROLLUP_LEVELS = "adId" | "adSetId" | "campaignId";

export type FacebookPerformanceRollup = {
  addToCarts: number;
  addToCartRate: number;
  marketplaceClickRate: number;
  marketplaceClicks: number;
  marketplaceCostPerClick: number;
  brandReferralBonus: number;
  conversions: number;
  conversionValue: number;
  costPerAddToCart: number;
  costPerDetailPageView: number;
  costPerMille: number;
  detailPageViews: number;
  detailPageViewRate: number;
  impressions: number;
  facebookClickRate: number;
  facebookClicks: number;
  facebookCostPerClick: number;
  // TODO: NTB is not currently being returned by the API
  ntbConversions: number;
  ntbUnitsSold: number;
  ntbRevenue: number;
  ntbConversionRate: number;
  ntbUnitsSoldRate: number;
  ntbRevenueRate: number;
  ntbROAS: number;
  roas: number;
  spend: number;
  unitsSold: number;
};

function emptyFbPerformanceMetrics(): FacebookPerformanceRollup {
  return {
    addToCarts: 0,
    addToCartRate: 0,
    marketplaceClickRate: 0,
    marketplaceClicks: 0,
    marketplaceCostPerClick: 0,
    brandReferralBonus: 0,
    conversions: 0,
    conversionValue: 0,
    costPerAddToCart: 0,
    costPerDetailPageView: 0,
    costPerMille: 0,
    detailPageViews: 0,
    detailPageViewRate: 0,
    impressions: 0,
    facebookClickRate: 0,
    facebookClicks: 0,
    facebookCostPerClick: 0,
    ntbConversions: 0,
    ntbUnitsSold: 0,
    ntbRevenue: 0,
    ntbConversionRate: 0,
    ntbUnitsSoldRate: 0,
    ntbRevenueRate: 0,
    ntbROAS: 0,
    roas: 0,
    spend: 0,
    unitsSold: 0
  };
}

export function usePerformanceDataRollupByLevel(
  facebookPerformance: GetFacebookAdPerformanceReply.AsObject | None,
  groupBy: FB_DATA_ROLLUP_LEVELS,
  // Ensure we have a row for for every id in this list, even if there is no
  // data for that id in the dailyPerformanceList. This is somewhat equivalent
  // to how we filter by "ampd campaigns" and "non-ampd campaigns" in the G2A
  // dashboard, but we don't have an Entity entry (for now) for these campaigns
  // so we need to ensure we have a row for each Ampd Facebook campaign
  // manually.
  ensureIds: Array<string>
): Record<string, FacebookPerformanceRollup> {
  return useMemo(() => {
    if (!facebookPerformance) {
      return {};
    }

    const rollupById: Record<string, FacebookPerformanceRollup> = {};
    for (const id of ensureIds) {
      if (!rollupById[id]) {
        rollupById[id] = emptyFbPerformanceMetrics();
      }
    }
    const { dailyPerformanceList, dailyProductList } = facebookPerformance;

    // Convert the proto response our table row
    for (const dailyPerformance of dailyPerformanceList) {
      const rollupId = dailyPerformance[groupBy];
      const rollup = rollupById[rollupId] || emptyFbPerformanceMetrics();

      rollup.addToCarts += dailyPerformance.addToCartClicks;
      rollup.marketplaceClicks += dailyPerformance.clickThroughs;
      rollup.brandReferralBonus += dailyPerformance.brandReferralBonus;
      rollup.conversions += dailyPerformance.conversions;
      rollup.conversionValue += dailyPerformance.conversionValue;
      rollup.detailPageViews += dailyPerformance.detailPageViewClicks;
      rollup.impressions += dailyPerformance.impressions;
      rollup.facebookClicks += dailyPerformance.clicks;
      rollup.spend += dailyPerformance.spend;
      rollup.unitsSold += dailyPerformance.unitsSold;
      rollupById[rollupId] = rollup;
    }

    // Calculate derived metrics
    for (const rollup of Object.values(rollupById)) {
      rollup.addToCartRate = calcAddToCartRate(rollup);
      rollup.marketplaceClickRate = calcMarketplaceClickRate(rollup);
      rollup.marketplaceCostPerClick = calcMarketplaceCostPerClick(rollup);
      rollup.costPerAddToCart = calcCostPerAddToCart(rollup);
      rollup.costPerDetailPageView = calcCostPerDetailPageView(rollup);
      rollup.costPerMille = calcCostPerMille(rollup);
      rollup.detailPageViewRate = calcDetailPageViewRate(rollup);
      rollup.facebookClickRate = calcFacebookClickRate(rollup);
      rollup.facebookCostPerClick = calcFacebookCostPerClick(rollup);
      rollup.roas = calcROAS(rollup);
    }

    //  Product attribution cannot be applied at the Ad level
    if (groupBy === "adId") {
      for (const rollup of Object.values(rollupById)) {
        rollup.ntbConversions = NaN;
        rollup.ntbUnitsSold = NaN;
        rollup.ntbRevenue = NaN;
        rollup.ntbConversionRate = NaN;
        rollup.ntbUnitsSoldRate = NaN;
        rollup.ntbRevenueRate = NaN;
        rollup.ntbROAS = NaN;
      }
    } else {
      for (const productPerformance of dailyProductList) {
        const rollupId = productPerformance[groupBy];
        const rollup = rollupById[rollupId] || emptyFbPerformanceMetrics();

        rollup.ntbConversions += productPerformance.newToBrandConversions;
        rollup.ntbUnitsSold += productPerformance.newToBrandUnitsSold;
        rollup.ntbRevenue += productPerformance.newToBrandConversionValue;
        rollupById[rollupId] = rollup;
      }

      // Calculate product attribution derived metrics
      for (const rollup of Object.values(rollupById)) {
        rollup.ntbConversionRate = calcNewToBrandConversionRate(rollup);
        rollup.ntbUnitsSoldRate = calcNewToBrandUnitsSoldRate(rollup);
        rollup.ntbRevenueRate = calcNewToBrandRevenueRate(rollup);
        rollup.ntbROAS = calcNewToBrandROAS(rollup);
      }
    }

    return rollupById;
  }, [facebookPerformance, groupBy, ensureIds]);
}

// Click Rates are the number of clicks per Facebook impression.
function calcFacebookClickRate(performance: FacebookPerformanceRollup): number {
  return performance.facebookClicks / performance.impressions;
}

// Be aware that sometimes we see more Amazon attributed clicks than Facebook
// clicks (which should not be impossible), so we consider the Amazon click rate
// more as a second Click Rate metric than an attribution metric.
function calcMarketplaceClickRate(
  performance: FacebookPerformanceRollup
): number {
  return performance.marketplaceClicks / performance.impressions;
}

// The attributed metrics rates are the rate of attributed action per Facebook click
function calcAddToCartRate(performance: FacebookPerformanceRollup): number {
  return performance.addToCarts / performance.facebookClicks;
}

function calcDetailPageViewRate(
  performance: FacebookPerformanceRollup
): number {
  return performance.detailPageViews / performance.facebookClicks;
}

// Cost metrics are calculated as a ratio of spend to the relevant metric
function calcMarketplaceCostPerClick(
  performance: FacebookPerformanceRollup
): number {
  return performance.spend / performance.marketplaceClicks;
}

function calcFacebookCostPerClick(
  performance: FacebookPerformanceRollup
): number {
  return performance.spend / performance.facebookClicks;
}

const MILLE = 1_000;
// "cost per 1,000" impressions
function calcCostPerMille(performance: FacebookPerformanceRollup): number {
  return (performance.spend / performance.impressions) * MILLE;
}

function calcCostPerDetailPageView(
  performance: FacebookPerformanceRollup
): number {
  return performance.spend / performance.detailPageViews;
}

function calcCostPerAddToCart(performance: FacebookPerformanceRollup): number {
  return performance.spend / performance.addToCarts;
}

function calcROAS(performance: FacebookPerformanceRollup): number {
  return performance.conversionValue / performance.spend;
}

function calcNewToBrandConversionRate(
  performance: FacebookPerformanceRollup
): number {
  return performance.ntbConversions / performance.conversions;
}

function calcNewToBrandUnitsSoldRate(
  performance: FacebookPerformanceRollup
): number {
  return performance.ntbUnitsSold / performance.unitsSold;
}

function calcNewToBrandRevenueRate(
  performance: FacebookPerformanceRollup
): number {
  return performance.ntbRevenue / performance.conversionValue;
}

function calcNewToBrandROAS(performance: FacebookPerformanceRollup): number {
  return performance.ntbRevenue / performance.spend;
}

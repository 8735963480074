import _ from "lodash";
import moment from "moment";

import React from "react";
import { Icon, Loader, Table } from "semantic-ui-react";
import styled from "styled-components/macro";
import { formatMetric } from "Common/utils/metrics";
import { convertValueToUSD } from "Common/utils/googleAds";
import { getCurrencyMetricDef } from "Common/utils/money";
import { comparisonGreen, comparisonRed } from "../styles/colors";
import SimpleTooltip from "./SimpleTooltip";

export const STATUS_ENABLED_STR = "ENABLED";
export const STATUS_PAUSED_STR = "PAUSED";
export const STATUS_REMOVED_STR = "REMOVED";

export const SORT_ASCENDING = "ascending";
export const SORT_DESCENDING = "descending";
export const SORT_NONE = "none";

export const CAMPAIGN_NAME_COL = "campaign";
export const CAMPAIGN_TYPE_COL = "type";
export const CAMPAIGN_STATUS_COL = "status";
export const DAILY_BUDGET_COL = "budget";

export const KEYWORD_TEXT_COL = "keyword";
export const KEYWORD_MATCH_TYPE_COL = "keyword_match_type";
export const KEYWORD_STATUS_COL = "keyword_status";

export const IMPRESSIONS_COL = "impressions";
export const CLICKS_COL = "clicks";
export const CLICK_THROUGH_RATE_COL = "click_through_rate";
export const COST_COL = "cost";
export const ADJUSTED_COST_COL = "adjustedCost";
export const ADD_TO_CART_CLICKS_COL = "add_to_cart_clicks";
export const ADD_TO_CART_RATE_COL = "add_to_cart_rate";
export const CONVERSIONS_COL = "conversions";
export const CONVERSION_RATE_COL = "conversion_rate";
export const UNITS_SOLD_COL = "units_sold";
export const REVENUE_COL = "revenue";
export const ATTRIBUTED_CLICKS_COL = "attributed_clicks";
export const DETAIL_PAGE_VIEWS_COL = "detail_page_views";
export const NEW_TO_BRAND_CONVERSIONS_COL = "ntb_conversions";
export const NEW_TO_BRAND_CONVERSIONS_PERCENTAGE_COL = "ntb_conversions_pct";
export const NEW_TO_BRAND_REVENUE_COL = "ntb_revenue";
export const NEW_TO_BRAND_REVENUE_PERCENTAGE_COL = "ntb_revenue_pct";
export const NEW_TO_BRAND_UNITS_SOLD_COL = "ntb_units_sold";
export const NEW_TO_BRAND_UNITS_SOLD_PERCENTAGE_COL = "ntb_units_sold_pct";
export const ROAS_COL = "roas";
export const AROAS_COL = "aroas";
export const NEW_TO_BRAND_ROAS_COL = "ntb_roas";
export const ACOS_COL = "acos";
export const BRAND_REFERRAL_BONUS_COL = "brand_referral_bonus";
export const AACOS_COL = "aacos";
export const TACOS_COL = "tacos";
export const AVERAGE_CPC_COL = "average_cpc";
export const CPC_BID_COL = "cpc_bid";
export const EFFECTIVE_CPC_BID_COL = "effective_cpc_bid";
export const DEFAULT_CPC_BID_COL = "default_cpc_bid";
export const EFFECTIVE_DEFAULT_CPC_BID_COL = "effective_default_cpc_bid";
export const MAX_CPC_BID_COL = "max_cpc_bid";
export const BID_AUTOMATION_COL = "bid_automation";
export const PURCHASE_FREQUENCY_COL = "purchase_frequency";
export const ANNUALIZED_REVENUE_COL = "annualized_revenue";
export const ANNUALIZED_ROAS_COL = "annualized_roas";
export const ANNUALIZED_AROAS_COL = "annualized_aroas";
export const ANNUALIZED_ACOS_COL = "annualized_acos";
export const ANNUALIZED_AACOS_COL = "annualized_aacos";

export const COLUMN_DATA_KEYS = {
  aacos: "aacos",
  acos: "acos",
  cartRate: "addToCartRate",
  averageCpc: "averageCpc",
  cpcBid: "cpcBid",
  effectiveCpcBid: "effectiveCpcBid",
  defaultCpcBid: "defaultCpcBid",
  effectiveDefaultCpcBid: "effectiveDefaultCpcBid",
  maxCpcBid: "maxCpcBid",
  carts: "addToCartClicks",
  clicks: "clicks",
  clickThroughRate: "clickThroughRate",
  conversionRate: "conversionRate",
  conversions: "conversions",
  cost: "cost",
  adjustedCost: "adjustedCost",
  criticalInformation: "criticalInformation",
  impressions: "impressions",
  keywordMatchType: "matchType",
  keywords: "text",
  revenue: "revenue",
  brandReferralBonus: "brandReferralBonus",
  roas: "roas",
  aroas: "aroas",
  newToBrandRoas: "newToBrandRoas",
  status: "status",
  campaignName: "name",
  dailyBudget: "budgetAmount",
  unitsSold: "unitsSold",
  attributedClicks: "attributedClicks",
  detailPageViews: "detailPageViewClicks",
  newToBrandConversions: "newToBrandConversions",
  newToBrandRevenue: "newToBrandRevenue",
  newToBrandUnitsSold: "newToBrandUnitsSold",
  newToBrandConversionsPercentage: "newToBrandConversionsPercentage",
  newToBrandRevenuePercentage: "newToBrandRevenuePercentage",
  newToBrandUnitsSoldPercentage: "newToBrandUnitsSoldPercentage",
  campaignStartDate: "campaignStartDate",
  biddingStrategy: "biddingStrategy",
  adGroupId: "adGroupId",
  criteriaId: "criteriaId",
  adTarget: "adTarget",
  impressionShare: "impressionShare",
  lostISLowRank: "lostImpressionShareLowRank",
  lostISLowBudget: "lostImpressionShareLowBudget",
  clickShare: "clickShare",
  firstPageCpc: "firstPageCpc",
  firstPositionCpc: "firstPositionCpc",
  topOfPageCpc: "topOfPageCpc",
  unconvertedRevenueCurrencyCode: "unconvertedRevenueCurrencyCode",
  unconvertedRevenue: "unconvertedRevenue",
  unconvertedBrandReferralBonus: "unconvertedBrandReferralBonus",
  checked: "checked",
  clientSiteName: "clientSiteName",
  googleAdsCustomerId: "googleAdsCustomerId",
  billingStatusDescription: "billingStatusDescription",
  clientCampaignsDesc: "clientCampaignsDesc",
  aacosRange: "aacosRange",
  queryText: "queryText",
  tacos: "tacos",
  parentAsinRevenue: "parentAsinRevenue",
  parentAsinAdjustedAdsCost: "parentAsinAdjustedAdsCost",
  bidAutomationStatus: "bidAutomation",
  purchaseFrequency: "purchaseFrequency",
  annualizedRevenue: "annualizedRevenue",
  annualizedRoas: "annualizedRoas",
  annualizedAroas: "annualizedAroas",
  annualizedAcos: "annualizedAcos",
  annualizedAacos: "annualizedAacos"
};

export const AMPD_PRO_ONLY_COLUMNS = [COLUMN_DATA_KEYS.attributedClicks];

export const COLUMN_DISPLAY_NAME_FROM_DATA_KEY = {
  [COLUMN_DATA_KEYS.aacos]: "AACOS",
  [COLUMN_DATA_KEYS.acos]: "ACOS",
  [COLUMN_DATA_KEYS.cartRate]: "Cart Rate",
  [COLUMN_DATA_KEYS.averageCpc]: "Average CPC",
  [COLUMN_DATA_KEYS.cpcBid]: "CPC Bid",
  [COLUMN_DATA_KEYS.effectiveCpcBid]: "Effective CPC Bid",
  [COLUMN_DATA_KEYS.defaultCpcBid]: "Default CPC Bid",
  [COLUMN_DATA_KEYS.effectiveDefaultCpcBid]: "Effective Default CPC Bid",
  [COLUMN_DATA_KEYS.maxCpcBid]: "Campaign Max CPC Bid",
  [COLUMN_DATA_KEYS.carts]: "Carts",
  [COLUMN_DATA_KEYS.clicks]: "Clicks",
  [COLUMN_DATA_KEYS.clickThroughRate]: "Click Through Rate",
  [COLUMN_DATA_KEYS.conversionRate]: "Conversion Rate",
  [COLUMN_DATA_KEYS.conversions]: "Conversions",
  [COLUMN_DATA_KEYS.cost]: "Cost",
  [COLUMN_DATA_KEYS.adjustedCost]: "Adjusted Cost",
  [COLUMN_DATA_KEYS.criticalInformation]: <Icon name="exclamation triangle" />,
  [COLUMN_DATA_KEYS.impressions]: "Impressions",
  [COLUMN_DATA_KEYS.keywordMatchType]: "Match Type",
  [COLUMN_DATA_KEYS.keywords]: "Keywords",
  [COLUMN_DATA_KEYS.revenue]: "Revenue",
  [COLUMN_DATA_KEYS.brandReferralBonus]: "Brand Referral Bonus",
  [COLUMN_DATA_KEYS.roas]: "ROAS",
  [COLUMN_DATA_KEYS.aroas]: "AROAS",
  [COLUMN_DATA_KEYS.newToBrandRoas]: "NTB ROAS",
  [COLUMN_DATA_KEYS.status]: "Status",
  [COLUMN_DATA_KEYS.campaignName]: "Campaign Name",
  [COLUMN_DATA_KEYS.dailyBudget]: "Daily Budget",
  [COLUMN_DATA_KEYS.unitsSold]: "Units Sold",
  [COLUMN_DATA_KEYS.detailPageViews]: "Detail Page Views",
  [COLUMN_DATA_KEYS.newToBrandConversions]: "New-to-Brand Conversions",
  [COLUMN_DATA_KEYS.newToBrandRevenue]: "New-to-Brand Revenue",
  [COLUMN_DATA_KEYS.newToBrandUnitsSold]: "New-to-Brand Units Sold",
  [COLUMN_DATA_KEYS.newToBrandConversionsPercentage]: "% of Conversions NTB",
  [COLUMN_DATA_KEYS.newToBrandRevenuePercentage]: "% of Revenue NTB",
  [COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage]: "% of Units Sold NTB",
  [COLUMN_DATA_KEYS.campaignStartDate]: "Start Date",
  [COLUMN_DATA_KEYS.biddingStrategy]: "Bidding Strategy",
  [COLUMN_DATA_KEYS.adGroupId]: "Ad Group ID",
  [COLUMN_DATA_KEYS.criteriaId]: "Criteria ID",
  [COLUMN_DATA_KEYS.adTarget]: "Ad Target",
  [COLUMN_DATA_KEYS.impressionShare]: "Impression Share",
  [COLUMN_DATA_KEYS.lostISLowRank]: "Lost Impression Share (poor ad rank)",
  [COLUMN_DATA_KEYS.lostISLowBudget]: "Lost Impression Share (low budget)",
  [COLUMN_DATA_KEYS.clickShare]: "Click Share",
  [COLUMN_DATA_KEYS.firstPageCpc]: "First Page CPC",
  [COLUMN_DATA_KEYS.firstPositionCpc]: "First Position CPC",
  [COLUMN_DATA_KEYS.topOfPageCpc]: "Top Of Page CPC",
  [COLUMN_DATA_KEYS.checked]: "Checked",
  [COLUMN_DATA_KEYS.clientSiteName]: "Client Account",
  [COLUMN_DATA_KEYS.googleAdsCustomerId]: "Google Ads Customer ID",
  [COLUMN_DATA_KEYS.billingStatusDescription]: "Ampd Billing Status",
  [COLUMN_DATA_KEYS.clientCampaignsDesc]: "Active Campaigns",
  [COLUMN_DATA_KEYS.aacosRange]: "AACOS Range",
  [COLUMN_DATA_KEYS.tacos]: "TACoS",
  [COLUMN_DATA_KEYS.bidAutomationStatus]: "Bid Automation Status",
  [COLUMN_DATA_KEYS.purchaseFrequency]: "Annual Purchase Frequency",
  [COLUMN_DATA_KEYS.annualizedRevenue]: "Annualized Revenue",
  [COLUMN_DATA_KEYS.annualizedRoas]: "Annualized ROAS",
  [COLUMN_DATA_KEYS.annualizedAroas]: "Annualized AROAS",
  [COLUMN_DATA_KEYS.annualizedAcos]: "Annualized ACOS",
  [COLUMN_DATA_KEYS.annualizedAacos]: "Annualized AACOS",

  [COLUMN_DATA_KEYS.attributedClicks]: "Attributed Clicks (Pro Only)"
};

export const METRIC_COLUMNS = [
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.adjustedCost,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.impressionShare,
  COLUMN_DATA_KEYS.lostISLowRank,
  COLUMN_DATA_KEYS.lostISLowBudget,
  COLUMN_DATA_KEYS.clickShare,
  COLUMN_DATA_KEYS.firstPageCpc,
  COLUMN_DATA_KEYS.firstPositionCpc,
  COLUMN_DATA_KEYS.topOfPageCpc,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.attributedClicks,
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.newToBrandConversions,
  COLUMN_DATA_KEYS.newToBrandRevenue,
  COLUMN_DATA_KEYS.newToBrandUnitsSold,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage,
  COLUMN_DATA_KEYS.roas,
  COLUMN_DATA_KEYS.aroas,
  COLUMN_DATA_KEYS.newToBrandRoas,
  COLUMN_DATA_KEYS.acos,
  COLUMN_DATA_KEYS.aacos,
  COLUMN_DATA_KEYS.tacos,
  COLUMN_DATA_KEYS.purchaseFrequency,
  COLUMN_DATA_KEYS.annualizedRevenue,
  COLUMN_DATA_KEYS.annualizedRoas,
  COLUMN_DATA_KEYS.annualizedAroas,
  COLUMN_DATA_KEYS.annualizedAcos,
  COLUMN_DATA_KEYS.annualizedAacos
];

export const CompareSpan = styled.span`
  font-size: small;
  font-style: italic;
`;

export const CompareNeutral = styled(CompareSpan)`
  opacity: 0.6;
`;

export const CompareGood = styled(CompareSpan)`
  font-weight: 500;
  color: ${comparisonGreen};
`;

export const CompareBad = styled(CompareSpan)`
  font-weight: 500;
  color: ${comparisonRed};
`;

// The default inline loader has a z-index of 1000, which puts it above the
// table header, so clear it by setting it to zero.
export const InlineLoadingSpinner = ({ size = "tiny" }) => (
  <Loader style={{ zIndex: 0 }} active inline size={size} />
);

// Some metrics are "good" when they go up, and some when they go down.
// The order of the styles is [<negative change>, <zero change>, <positive change>]
// Columns without an entry should not show a comparison.
export const COLUMN_COMPARE_STYLES = {
  [COLUMN_DATA_KEYS.aacos]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.acos]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.tacos]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.cartRate]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.averageCpc]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.carts]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.clicks]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.clickThroughRate]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.conversionRate]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.conversions]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.cost]: [CompareNeutral, CompareNeutral, CompareNeutral],
  [COLUMN_DATA_KEYS.impressions]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.revenue]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.brandReferralBonus]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.adjustedCost]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.roas]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.aroas]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.newToBrandRoas]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.unitsSold]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.attributedClicks]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.detailPageViews]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.newToBrandConversionsPercentage]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.newToBrandRevenuePercentage]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.purchaseFrequency]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.annualizedRevenue]: [
    CompareBad,
    CompareNeutral,
    CompareGood
  ],
  [COLUMN_DATA_KEYS.annualizedRoas]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.annualizedAroas]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.annualizedAcos]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.annualizedAacos]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.impressionShare]: [CompareBad, CompareNeutral, CompareGood],
  [COLUMN_DATA_KEYS.lostISLowRank]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.lostISLowBudget]: [CompareGood, CompareNeutral, CompareBad],
  [COLUMN_DATA_KEYS.clickShare]: [CompareBad, CompareNeutral, CompareGood]
};

export const COLUMN_TOOLTIP_FROM_DATA_KEY = {
  [COLUMN_DATA_KEYS.aacos]: (
    <p>
      "Adjusted ACOS" <br />
      <br />
      This includes any Brand Referral Bonus that Amazon provides to help offset
      the cost of external ads.
    </p>
  ),
  [COLUMN_DATA_KEYS.aroas]: (
    <p>
      "Adjusted ROAS" <br />
      <br />
      This includes any Brand Referral Bonus that Amazon provides to help offset
      the cost of external ads.
    </p>
  ),
  [COLUMN_DATA_KEYS.purchaseFrequency]: (
    <p>
      The average annual purchases per customer for this product, based on
      Amazon's reported values.
    </p>
  ),
  [COLUMN_DATA_KEYS.annualizedRevenue]: (
    <p>
      Revenue in consideration of the annual purchase frequency of this product.
      Calculated by multiplying revenue over the selected time frame by the
      annual purchase frequency.
    </p>
  ),
  [COLUMN_DATA_KEYS.annualizedRoas]: (
    <p>
      ROAS in consideration of the annual purchase frequency of this product.
      Calculated by dividing the "Annualized Revenue" of this product by the
      advertising cost.
    </p>
  ),
  [COLUMN_DATA_KEYS.annualizedAroas]: (
    <p>
      AROAS in consideration of the annual purchase frequency of this product.
      Calculated by dividing the "Annualized Revenue" of this product by the
      advertising cost (offset by any Brand Referral Bonus).
    </p>
  ),
  [COLUMN_DATA_KEYS.annualizedAcos]: (
    <p>
      ACOS in consideration of the annualized revenue for this product.
      Calculated by dividing the advertising cost by the "Annualized Revenue" of
      this product.
    </p>
  ),
  [COLUMN_DATA_KEYS.annualizedAacos]: (
    <p>
      AACOS in consideration of the annualized revenue for this product.
      Calculated by dividing the advertising cost (offset by any Brand Referral
      Bonus) by the "Annualized Revenue" of this product.
    </p>
  ),
  [COLUMN_DATA_KEYS.tacos]: (
    <p>
      "Total Advertising Cost of Sale (TACoS)" <br />
      <br /> A parent product's total advertising cost as a percentage of its
      total revenue. Calculated by taking the total ads cost for all variants of
      the parent product (total ads cost = Amazon Sponsored Product Ads cost +
      Google Ads cost - Brand Referral Bonus) and dividing by the total revenue
      for all variants of the parent product.
    </p>
  ),
  [COLUMN_DATA_KEYS.newToBrandConversionsPercentage]: (
    <p>
      "% of Conversions, New-to-Brand" <br />
      <br />
      New-to-brand conversions divided by total conversions. A purchase is
      considered new-to-brand if the customer has not bought a product from the
      brand at the marketplace within a year.
    </p>
  ),
  [COLUMN_DATA_KEYS.newToBrandRevenuePercentage]: (
    <p>
      "% of Revenue, New-to-Brand" <br />
      <br />
      New-to-brand revenue divided by total revenue. A purchase is considered
      new-to-brand if the customer has not bought a product from the brand at
      the marketplace within a year.
    </p>
  ),
  [COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage]: (
    <p>
      "% of Units Sold, New-to-Brand" <br />
      <br />
      New-to-brand units sold divided by total units sold. A purchase is
      considered new-to-brand if the customer has not bought a product from the
      brand at the marketplace within a year.
    </p>
  ),
  [COLUMN_DATA_KEYS.newToBrandRoas]: (
    <p>
      "New-to-Brand Return on Ad Spend"
      <br />
      <br />
      New-to-brand revenue as a percentage of ad cost. A purchase is considered
      new-to-brand if the customer has not bought a product from the brand at
      the marketplace within a year.
    </p>
  )
};

export const COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY = {
  [COLUMN_DATA_KEYS.aacos]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.acos]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.tacos]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.cartRate]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.averageCpc]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.cpcBid]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.effectiveCpcBid]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.defaultCpcBid]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.effectiveDefaultCpcBid]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.maxCpcBid]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.carts]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.clicks]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.clickThroughRate]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.conversionRate]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.conversions]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.cost]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.impressions]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.revenue]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.roas]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.aroas]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.newToBrandRoas]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.dailyBudget]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.unitsSold]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.purchaseFrequency]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.annualizedRevenue]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.annualizedRoas]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.annualizedAroas]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.annualizedAcos]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.annualizedAacos]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.attributedClicks]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.detailPageViews]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.newToBrandConversionsPercentage]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.newToBrandRevenuePercentage]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.campaignStartDate]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.biddingStrategy]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.adGroupId]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.criteriaId]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.keywordMatchType]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.keywords]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.status]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.campaignName]: SORT_ASCENDING,
  [COLUMN_DATA_KEYS.impressionShare]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.lostISLowRank]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.lostISLowBudget]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.clickShare]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.firstPageCpc]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.firstPositionCpc]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.topOfPageCpc]: SORT_DESCENDING,
  [COLUMN_DATA_KEYS.bidAutomationStatus]: SORT_DESCENDING
};

export const CURRENCY_CONVERSION_TOOLTIP =
  "Your Amazon revenue has been approximately converted to match your Google Ads currency." +
  " This allows Ampd to calculate an approximate AACOS value.";

/**
 * Returns the calculated ratio of the numerator & denominator.
 * @param { Number | null | undefined } numerator
 * @param { Number | null | undefined } denominator
 * @returns Number | undefined
 */
export function calculateRatio(numerator, denominator) {
  if (!_.isFinite(numerator) || !_.isNumber(numerator)) {
    return undefined;
  }

  if (
    denominator === 0 ||
    !_.isFinite(denominator) ||
    !_.isNumber(denominator)
  ) {
    return undefined;
  }

  return numerator / denominator;
}

/**
 * Returns the total product of the two args.
 * @param { Number | null | undefined) } numA
 * @param { Number | null | undefined) } numB
 * @returns Number | undefined
 */
export function calculateProduct(numA, numB) {
  if (
    !_.isNumber(numA) ||
    !_.isNumber(numB) ||
    !_.isFinite(numA) ||
    !_.isFinite(numB)
  ) {
    return;
  }

  return numA * numB;
}

export function calculateMetricColumnValue(column, metrics = {}) {
  switch (column) {
    case COLUMN_DATA_KEYS.clickThroughRate:
      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.clicks],
        metrics[COLUMN_DATA_KEYS.impressions]
      );
    case COLUMN_DATA_KEYS.averageCpc:
      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.cost],
        metrics[COLUMN_DATA_KEYS.clicks]
      );
    case COLUMN_DATA_KEYS.cartRate:
      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.carts],
        metrics[COLUMN_DATA_KEYS.clicks]
      );
    case COLUMN_DATA_KEYS.conversionRate:
      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.conversions],
        metrics[COLUMN_DATA_KEYS.clicks]
      );
    case COLUMN_DATA_KEYS.newToBrandConversionsPercentage: {
      const value = calculateRatio(
        metrics[COLUMN_DATA_KEYS.newToBrandConversions],
        metrics[COLUMN_DATA_KEYS.conversions]
      );

      return value == null ? value : Math.min(value, 1);
    }
    case COLUMN_DATA_KEYS.newToBrandRevenuePercentage: {
      const value = calculateRatio(
        metrics[COLUMN_DATA_KEYS.newToBrandRevenue],
        metrics[COLUMN_DATA_KEYS.revenue]
      );
      return value == null ? value : Math.min(value, 1);
    }
    case COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage: {
      const value = calculateRatio(
        metrics[COLUMN_DATA_KEYS.newToBrandUnitsSold],
        metrics[COLUMN_DATA_KEYS.unitsSold]
      );
      return value == null ? value : Math.min(value, 1);
    }
    case COLUMN_DATA_KEYS.roas: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.revenue],
        metrics[COLUMN_DATA_KEYS.cost]
      );
    }
    case COLUMN_DATA_KEYS.aroas: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.revenue],
        metrics[COLUMN_DATA_KEYS.adjustedCost]
      );
    }
    case COLUMN_DATA_KEYS.newToBrandRoas: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.newToBrandRevenue],
        metrics[COLUMN_DATA_KEYS.cost]
      );
    }
    case COLUMN_DATA_KEYS.acos: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.cost],
        metrics[COLUMN_DATA_KEYS.revenue]
      );
    }
    case COLUMN_DATA_KEYS.aacos: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.adjustedCost],
        metrics[COLUMN_DATA_KEYS.revenue]
      );
    }
    case COLUMN_DATA_KEYS.tacos:
      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.parentAsinAdjustedAdsCost],
        metrics[COLUMN_DATA_KEYS.parentAsinRevenue]
      );
    case COLUMN_DATA_KEYS.annualizedRoas: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.annualizedRevenue],
        metrics[COLUMN_DATA_KEYS.cost]
      );
    }
    case COLUMN_DATA_KEYS.annualizedAroas: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.annualizedRevenue],
        metrics[COLUMN_DATA_KEYS.adjustedCost]
      );
    }
    case COLUMN_DATA_KEYS.annualizedAcos: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.cost],
        metrics[COLUMN_DATA_KEYS.annualizedRevenue]
      );
    }
    case COLUMN_DATA_KEYS.annualizedAacos: {
      if (metrics.costCurrencyCode !== metrics.revenueCurrencyCode) {
        return undefined;
      }

      return calculateRatio(
        metrics[COLUMN_DATA_KEYS.adjustedCost],
        metrics[COLUMN_DATA_KEYS.annualizedRevenue]
      );
    }
  }

  return metrics[column];
}

// Calculates any derived metric columns that depend on more than one metric value.
export function calculateDerivedMetrics(metrics, columns) {
  columns.forEach(column => {
    metrics[column] = calculateMetricColumnValue(column, metrics);
  });
}

export function formatMetricColumnValue(
  column,
  metric,
  costCurrencyCode,
  revenueCurrencyCode,
  showFractions
) {
  switch (column) {
    // Simple rounded numbers
    case COLUMN_DATA_KEYS.impressions:
    case COLUMN_DATA_KEYS.clicks:
    case COLUMN_DATA_KEYS.carts:
    case COLUMN_DATA_KEYS.conversions:
    case COLUMN_DATA_KEYS.unitsSold:
    case COLUMN_DATA_KEYS.attributedClicks:
    case COLUMN_DATA_KEYS.detailPageViews:
      return formatMetric(roundedNumberMetricDef, metric);

    // Simple numbers, rounded to 2 decimal precision
    case COLUMN_DATA_KEYS.purchaseFrequency:
      return formatMetric(fractionalNumberMetricDef, metric);

    // Cost-side Currency Values
    case COLUMN_DATA_KEYS.cost:
    case COLUMN_DATA_KEYS.adjustedCost:
    case COLUMN_DATA_KEYS.dailyBudget:
      return formatMetric(
        getCurrencyMetricDef(costCurrencyCode, showFractions),
        metric
      );

    case COLUMN_DATA_KEYS.averageCpc:
    case COLUMN_DATA_KEYS.cpcBid:
    case COLUMN_DATA_KEYS.effectiveCpcBid:
    case COLUMN_DATA_KEYS.defaultCpcBid:
    case COLUMN_DATA_KEYS.effectiveDefaultCpcBid:
    case COLUMN_DATA_KEYS.maxCpcBid:
    case COLUMN_DATA_KEYS.firstPageCpc:
    case COLUMN_DATA_KEYS.firstPositionCpc:
    case COLUMN_DATA_KEYS.topOfPageCpc:
      return formatMetric(getCurrencyMetricDef(costCurrencyCode, true), metric);

    // Revenue-side Currency Values
    case COLUMN_DATA_KEYS.revenue:
    case COLUMN_DATA_KEYS.annualizedRevenue:
    case COLUMN_DATA_KEYS.brandReferralBonus:
      return formatMetric(
        getCurrencyMetricDef(revenueCurrencyCode, showFractions),
        metric
      );

    // Rates (rounded to nearest tenths of percentage point if not showing fractions)
    case COLUMN_DATA_KEYS.clickThroughRate:
    case COLUMN_DATA_KEYS.cartRate:
    case COLUMN_DATA_KEYS.conversionRate:
      return formatMetric(
        showFractions
          ? fractionalPercentageMetricDef
          : oneDecimalPercentageMetricDef,
        metric
      );

    // Percentages (rounded to nearest percentage point if not showing fractions)
    case COLUMN_DATA_KEYS.roas:
    case COLUMN_DATA_KEYS.aroas:
    case COLUMN_DATA_KEYS.newToBrandRoas:
    case COLUMN_DATA_KEYS.acos:
    case COLUMN_DATA_KEYS.aacos:
    case COLUMN_DATA_KEYS.tacos:
    case COLUMN_DATA_KEYS.annualizedRoas:
    case COLUMN_DATA_KEYS.annualizedAroas:
    case COLUMN_DATA_KEYS.annualizedAacos:
    case COLUMN_DATA_KEYS.annualizedAcos:
    case COLUMN_DATA_KEYS.impressionShare:
    case COLUMN_DATA_KEYS.lostISLowRank:
    case COLUMN_DATA_KEYS.lostISLowBudget:
    case COLUMN_DATA_KEYS.clickShare:
    case COLUMN_DATA_KEYS.newToBrandConversionsPercentage:
    case COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage:
    case COLUMN_DATA_KEYS.newToBrandRevenuePercentage:
      return formatMetric(
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef,
        metric
      );

    default:
      return metric;
  }
}

export function convertMetricColumnToUSD(
  column,
  metric,
  costCurrencyCode,
  revenueCurrencyCode
) {
  switch (column) {
    // Cost-side Currency Values
    case COLUMN_DATA_KEYS.adjustedCost:
    case COLUMN_DATA_KEYS.cost:
    case COLUMN_DATA_KEYS.dailyBudget:
    case COLUMN_DATA_KEYS.averageCpc:
    case COLUMN_DATA_KEYS.cpcBid:
    case COLUMN_DATA_KEYS.effectiveCpcBid:
    case COLUMN_DATA_KEYS.defaultCpcBid:
    case COLUMN_DATA_KEYS.effectiveDefaultCpcBid:
    case COLUMN_DATA_KEYS.maxCpcBid:
    case COLUMN_DATA_KEYS.firstPageCpc:
    case COLUMN_DATA_KEYS.firstPositionCpc:
    case COLUMN_DATA_KEYS.topOfPageCpc:
      return convertValueToUSD({ value: metric, currency: costCurrencyCode });

    // Revenue-side Currency Values (stuff from Amazon)
    case COLUMN_DATA_KEYS.revenue:
    case COLUMN_DATA_KEYS.annualizedRevenue:
    case COLUMN_DATA_KEYS.brandReferralBonus:
    case COLUMN_DATA_KEYS.parentAsinAdjustedAdsCost: // Combo of google+amazon in google currency
    case COLUMN_DATA_KEYS.parentAsinRevenue: // Started in Amazon currency, now in google
      return convertValueToUSD({
        value: metric,
        currency: revenueCurrencyCode
      });

    default:
      return metric;
  }
}

// If appropriate for the column, returns a secondary 'annotation' value that
// can be displayed or included alongside the primary value for that column.
export function getMetricColumnAnnotation(
  column,
  dataObject,
  revenueCurrencyCode,
  showFractions,
  showUnconvertedRevenue
) {
  switch (column) {
    case COLUMN_DATA_KEYS.revenue: {
      if (!showUnconvertedRevenue || !revenueCurrencyCode) {
        return undefined;
      }
      const formattedValue = formatMetric(
        getCurrencyMetricDef(revenueCurrencyCode, showFractions),
        dataObject[COLUMN_DATA_KEYS.unconvertedRevenue]
      );
      formattedValue.tooltip = CURRENCY_CONVERSION_TOOLTIP;
      return formattedValue;
    }

    case COLUMN_DATA_KEYS.brandReferralBonus: {
      if (!showUnconvertedRevenue || !revenueCurrencyCode) {
        return undefined;
      }
      const formattedValue = formatMetric(
        getCurrencyMetricDef(revenueCurrencyCode, showFractions),
        dataObject[COLUMN_DATA_KEYS.unconvertedBrandReferralBonus]
      );
      formattedValue.tooltip = CURRENCY_CONVERSION_TOOLTIP;
      return formattedValue;
    }

    case COLUMN_DATA_KEYS.newToBrandConversionsPercentage: {
      const value = dataObject[COLUMN_DATA_KEYS.newToBrandConversions];
      if (isNaN(value)) {
        return undefined;
      }

      const formattedValue = formatMetric(roundedNumberMetricDef, value);
      formattedValue.tooltip = "New-to-brand conversions";
      return formattedValue;
    }

    case COLUMN_DATA_KEYS.newToBrandRevenuePercentage: {
      if (!revenueCurrencyCode) {
        return undefined;
      }

      const value = dataObject[COLUMN_DATA_KEYS.newToBrandRevenue];
      if (isNaN(value)) {
        return undefined;
      }

      const formattedValue = formatMetric(
        getCurrencyMetricDef(revenueCurrencyCode, showFractions),
        value
      );
      formattedValue.tooltip = "New-to-brand revenue";
      return formattedValue;
    }

    case COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage: {
      const value = dataObject[COLUMN_DATA_KEYS.newToBrandUnitsSold];
      if (isNaN(value)) {
        return undefined;
      }

      const formattedValue = formatMetric(roundedNumberMetricDef, value);
      formattedValue.tooltip = "New-to-brand units sold";
      return formattedValue;
    }
  }

  return undefined;
}

// Returns a compareInfo object if the dataObject has a 'compareMetricsLoading'
// or 'compareMetrics' field.  Also, the compareMetrics must have a non-zero,
// non-NaN previous value.  Any returned compareInfo object specifies how to
// render the comparison annotation in metric table cells.
export function getMetricColumnCompareInfo(
  column,
  dataObject,
  costCurrencyCode,
  revenueCurrencyCode
) {
  if (dataObject?.compareMetricsLoading) {
    return { loading: true };
  }

  const compareMetrics = dataObject?.compareMetrics;
  if (!compareMetrics) {
    return null;
  }

  const compareStyles = COLUMN_COMPARE_STYLES[column];
  if (!compareStyles) {
    return null;
  }

  const previousValue = compareMetrics[column] || 0;
  const currentValue = dataObject[column] || 0;
  const diffValue = (currentValue - previousValue) / previousValue;

  let diffString = "";
  let compareStyle = CompareNeutral;
  if (_.isFinite(diffValue)) {
    diffString = `(${formatMetric(diffPercentageMetricDef, diffValue)})`;
    compareStyle = compareStyles[Math.sign(diffValue) + 1];
  } else if (_.isNaN(diffValue)) {
    diffString = "(+0%)";
  } else {
    diffString = "(+)";
  }

  return {
    diffString,
    compareStyle,
    previousFormatted: formatMetricColumnValue(
      column,
      previousValue,
      costCurrencyCode,
      revenueCurrencyCode,
      true
    ),
    diffFormatted: formatMetricColumnValue(
      column,
      currentValue - previousValue,
      costCurrencyCode,
      revenueCurrencyCode,
      true
    )
  };
}

export const COLUMN_TITLES = {
  [CAMPAIGN_NAME_COL]: "",
  [CAMPAIGN_TYPE_COL]: "Campaign Type",
  [CAMPAIGN_STATUS_COL]: "Campaign Status",
  [DAILY_BUDGET_COL]: "Daily Budget",

  [KEYWORD_TEXT_COL]: "",
  [KEYWORD_MATCH_TYPE_COL]: "Keyword Match Type",
  [KEYWORD_STATUS_COL]: "Keyword Status",

  [IMPRESSIONS_COL]: "Impressions",
  [CLICKS_COL]: "Clicks",
  [CLICK_THROUGH_RATE_COL]: "Click-through Rate",
  [COST_COL]: "Cost",
  [AVERAGE_CPC_COL]: "Average CPC",
  [CPC_BID_COL]: "CPC Bid",
  [EFFECTIVE_CPC_BID_COL]: "Effective CPC Bid",
  [DEFAULT_CPC_BID_COL]: "Default CPC Bid",
  [EFFECTIVE_DEFAULT_CPC_BID_COL]: "Effective Default CPC Bid",
  [MAX_CPC_BID_COL]: "Max CPC Bid",
  [ADD_TO_CART_CLICKS_COL]: "Add to Carts",
  [ADD_TO_CART_RATE_COL]: "Add to Cart Rate",
  [CONVERSIONS_COL]: "Conversions",
  [CONVERSION_RATE_COL]: "Conversion Rate",
  [UNITS_SOLD_COL]: "Units Sold",
  [REVENUE_COL]: "Revenue",
  [DETAIL_PAGE_VIEWS_COL]: "Detail Page Views",
  [NEW_TO_BRAND_CONVERSIONS_COL]: "NTB Conversions",
  [NEW_TO_BRAND_CONVERSIONS_PERCENTAGE_COL]: "% of Conversions NTB",
  [NEW_TO_BRAND_REVENUE_COL]: "NTB Revenue",
  [NEW_TO_BRAND_REVENUE_PERCENTAGE_COL]: "% of Revenue NTB",
  [NEW_TO_BRAND_UNITS_SOLD_COL]: "NTB Units Sold",
  [NEW_TO_BRAND_UNITS_SOLD_PERCENTAGE_COL]: "% of Units Sold NTB",
  [ROAS_COL]: "ROAS",
  [AROAS_COL]: "AROAS",
  [NEW_TO_BRAND_ROAS_COL]: "NTB ROAS",
  [ACOS_COL]: "ACOS",
  [BRAND_REFERRAL_BONUS_COL]: "Brand Referral Bonus",
  [AACOS_COL]: "AACOS",
  [TACOS_COL]: "Total ACOS",
  [PURCHASE_FREQUENCY_COL]: "Annual Purchase Frequency",
  [ANNUALIZED_REVENUE_COL]: "Annualized Revenue",
  [ANNUALIZED_ROAS_COL]: "Annualized ROAS",
  [ANNUALIZED_AROAS_COL]: "Annualized AROAS",
  [ANNUALIZED_ACOS_COL]: "Annualized ACOS",
  [ANNUALIZED_AACOS_COL]: "Annualized AACOS"
};

// Columns that format based on the revenue currency code.
export const REVENUE_CURRENCY_COLUMNS = {
  [REVENUE_COL]: true,
  [ANNUALIZED_REVENUE_COL]: true,
  [BRAND_REFERRAL_BONUS_COL]: true
};

// Columns that should only be calculated if the cost and revenue currencies
// are the same.  (In theory different currencies could be compatible because
// one is pegged to the other, but we don't handle that.)
export const COMPATIBLE_CURRENCIES_ONLY_COLUMNS = {
  [ROAS_COL]: true,
  [AROAS_COL]: true,
  [NEW_TO_BRAND_ROAS_COL]: true,
  [ACOS_COL]: true,
  [AACOS_COL]: true,
  [ANNUALIZED_ROAS_COL]: true,
  [ANNUALIZED_AROAS_COL]: true,
  [ANNUALIZED_ACOS_COL]: true,
  [ANNUALIZED_AACOS_COL]: true
};

const EXCEL_INTEGER_NUMBER_FORMAT = "#,##0";
const EXCEL_PERCENT_NUMBER_FORMAT = "#,##0.00%";
const EXCEL_DECIMAL_NUMBER_FORMAT = "#,##0.00";
const EXCEL_CURRENCY_NUMBER_FORMAT = "$#,##0.00";

export const EXCEL_METRIC_FORMATS = {
  [IMPRESSIONS_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [CLICKS_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [CLICK_THROUGH_RATE_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [COST_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [AVERAGE_CPC_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [CPC_BID_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [EFFECTIVE_CPC_BID_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [DEFAULT_CPC_BID_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [EFFECTIVE_DEFAULT_CPC_BID_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [MAX_CPC_BID_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [ADD_TO_CART_CLICKS_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [ADD_TO_CART_RATE_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [CONVERSIONS_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [CONVERSION_RATE_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [UNITS_SOLD_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [REVENUE_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [DETAIL_PAGE_VIEWS_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [NEW_TO_BRAND_CONVERSIONS_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [NEW_TO_BRAND_CONVERSIONS_PERCENTAGE_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [NEW_TO_BRAND_REVENUE_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [NEW_TO_BRAND_REVENUE_PERCENTAGE_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [NEW_TO_BRAND_UNITS_SOLD_COL]: EXCEL_INTEGER_NUMBER_FORMAT,
  [NEW_TO_BRAND_UNITS_SOLD_PERCENTAGE_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [ROAS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [AROAS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [NEW_TO_BRAND_ROAS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [ACOS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [BRAND_REFERRAL_BONUS_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [AACOS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [TACOS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [PURCHASE_FREQUENCY_COL]: EXCEL_DECIMAL_NUMBER_FORMAT,
  [ANNUALIZED_REVENUE_COL]: EXCEL_CURRENCY_NUMBER_FORMAT,
  [ANNUALIZED_ROAS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [ANNUALIZED_AROAS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [ANNUALIZED_ACOS_COL]: EXCEL_PERCENT_NUMBER_FORMAT,
  [ANNUALIZED_AACOS_COL]: EXCEL_PERCENT_NUMBER_FORMAT
};

export const StickyTableHeader = styled(
  ({ tableHeaderBackground: _tableHeaderBackground, ...rest }) => (
    <Table.Header {...rest} />
  )
)`
  &&& tr:first-child th {
    position: sticky;
    top: 0px;
    background-color: ${props =>
      props.tableHeaderBackground || props.theme.tableHeaderBackground};
    z-index: 2;
  }
  &&& th:hover {
    background: ${props => props.theme.tableHeaderHoverBackground} !important;
  }
  &&& th.sorted {
    background: ${props => props.theme.tableHeaderHoverBackground} !important;
  }
  &&& th.sorted:hover {
    background: ${props => props.theme.tableHeaderHoverBackground} !important;
  }
`;

export const MetricAnnotationSpan = styled.span`
  font-size: 80%;
  display: block;
`;

export const NO_METRICS_FOUND = {
  impressions: NaN,
  clicks: NaN,
  cost: NaN,
  adjustedCost: NaN,
  conversions: NaN,
  revenue: NaN,
  brandReferralBonus: NaN,
  addToCartClicks: NaN,
  unitsSold: NaN,
  purchaseFrequency: NaN,
  annualizedRevenue: NaN,
  attributedClicks: NaN,
  detailPageViewClicks: NaN,
  newToBrandConversions: NaN,
  newToBrandRevenue: NaN,
  newToBrandUnitsSold: NaN,
  maxDataWithheldPercent: 0
};

export const roundedNumberMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: false,
    isPercent: false,
    precision: 0
  },
  nanString: " --"
};

export const roundedPercentageMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: false,
    isPercent: true,
    precision: 0
  },
  nanString: " --"
};

export const diffPercentageMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: true,
    isPercent: true,
    precision: 0
  },
  nanString: " --"
};

export const oneDecimalPercentageMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: false,
    isPercent: true,
    precision: 1
  },
  nanString: " --"
};

export const fractionalNumberMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: false,
    isPercent: false,
    precision: 2
  },
  nanString: " --"
};

export const oneDecimalNumberMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: false,
    isPercent: false,
    precision: 1
  },
  nanString: " --"
};

export const fractionalPercentageMetricDef = {
  isCurrency: false,
  formatting: {
    postfix: "",
    sign: false,
    isPercent: true,
    precision: 2
  },
  nanString: " --"
};

export function formatSimpleMetricRounded(metrics, field, metDef) {
  if (!metrics) {
    return "";
  }

  const value = metrics[field];
  if (_.isNaN(value) || _.isNil(value)) {
    return " --";
  }

  return formatMetric(metDef, value);
}

export function formatSimpleMetricRatioRounded(
  metrics,
  numeratorField,
  denominatorField,
  metDef
) {
  if (!metrics) {
    return "";
  }

  const num = metrics[numeratorField];
  const denom = metrics[denominatorField];
  if (_.isNaN(num) || _.isNil(num) || _.isNaN(denom) || denom === 0) {
    return " --";
  }

  return formatMetric(metDef, num / denom);
}

// Returns the MetricDefs to use with revenue side metrics (revenue,
// brandReferralBonus) and whether those metrics are compatible
// with the cost side metrics (cost, CPC) which will use the site-wide Google Ads
// currency code.  Only if the currencies are compatible can we calculate complex
// metrics like ROAS and ACOS.
export function determineRevenueCurrencyMetricDefs(
  metrics,
  currencyWithCentsMetricDef,
  currencyNoCentsMetricDef
) {
  if (!metrics) {
    // If there is no metrics object, just return the account current MetricDefs
    // and assume they are compatible.
    return {
      compatible: true,
      currencyWithCentsMetricDef,
      currencyNoCentsMetricDef
    };
  }

  let revenueCurrencyWithCentsMetricDef = currencyWithCentsMetricDef;
  let revenueCurrencyNoCentsMetricDef = currencyNoCentsMetricDef;

  // If there is no listed revenueCurrencyCode, assume it is the same as
  // the account's currencyCode.
  const compatible =
    !metrics.revenueCurrencyCode ||
    metrics.revenueCurrencyCode === metrics.costCurrencyCode ||
    metrics.revenueCurrencyCode === currencyWithCentsMetricDef?.currencyCode;

  if (!compatible || !currencyWithCentsMetricDef) {
    revenueCurrencyWithCentsMetricDef = getCurrencyMetricDef(
      metrics.revenueCurrencyCode,
      true
    );
    revenueCurrencyNoCentsMetricDef = getCurrencyMetricDef(
      metrics.revenueCurrencyCode,
      false
    );
  }

  let unconvertedRevenueCurrencyCode = undefined;
  let unconvertedRevenueCurrencyWithCentsMetricDef = undefined;
  let unconvertedRevenueCurrencyNoCentsMetricDef = undefined;
  if (compatible && metrics.unconvertedRevenueCurrencyCode) {
    unconvertedRevenueCurrencyCode = metrics.unconvertedRevenueCurrencyCode;
    unconvertedRevenueCurrencyWithCentsMetricDef = getCurrencyMetricDef(
      metrics.unconvertedRevenueCurrencyCode,
      true
    );
    unconvertedRevenueCurrencyNoCentsMetricDef = getCurrencyMetricDef(
      metrics.unconvertedRevenueCurrencyCode,
      false
    );
  }

  return {
    compatible,
    currencyWithCentsMetricDef: revenueCurrencyWithCentsMetricDef,
    currencyNoCentsMetricDef: revenueCurrencyNoCentsMetricDef,
    unconvertedRevenueCurrencyCode,
    unconvertedRevenueCurrencyWithCentsMetricDef,
    unconvertedRevenueCurrencyNoCentsMetricDef
  };
}

// Format the metric value for the specified column as appropriate.
export function formatColumnMetric(
  column,
  metrics,
  currencyWithCentsMetricDef,
  currencyNoCentsMetricDef,
  showFractions,
  showUnconvertedRevenue,
  noMetricsValue
) {
  if (!metrics && noMetricsValue != null) {
    return noMetricsValue;
  }

  const revenueMetricDefs = determineRevenueCurrencyMetricDefs(
    metrics,
    currencyWithCentsMetricDef,
    currencyNoCentsMetricDef
  );

  let formattedValue = noMetricsValue;
  switch (column) {
    case IMPRESSIONS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "impressions",
        roundedNumberMetricDef
      );
    case CLICKS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "clicks",
        roundedNumberMetricDef
      );
    case CLICK_THROUGH_RATE_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "clicks",
        "impressions",
        showFractions
          ? fractionalPercentageMetricDef
          : oneDecimalPercentageMetricDef
      );
    case COST_COL:
      return formatSimpleMetricRounded(
        metrics,
        "cost",
        showFractions ? currencyWithCentsMetricDef : currencyNoCentsMetricDef
      );
    case ADJUSTED_COST_COL:
      return formatSimpleMetricRounded(
        metrics,
        "adjustedCost",
        showFractions ? currencyWithCentsMetricDef : currencyNoCentsMetricDef
      );
    case CPC_BID_COL:
      return formatSimpleMetricRounded(
        metrics,
        "cpcBid",
        currencyWithCentsMetricDef
      );
    case EFFECTIVE_CPC_BID_COL:
      return formatSimpleMetricRounded(
        metrics,
        "effectiveCpcBid",
        currencyWithCentsMetricDef
      );
    case DEFAULT_CPC_BID_COL:
      return formatSimpleMetricRounded(
        metrics,
        "defaultCpcBid",
        currencyWithCentsMetricDef
      );
    case EFFECTIVE_DEFAULT_CPC_BID_COL:
      return formatSimpleMetricRounded(
        metrics,
        "effectiveDefaultCpcBid",
        currencyWithCentsMetricDef
      );
    case MAX_CPC_BID_COL:
      return formatSimpleMetricRounded(
        metrics,
        "maxCpcBid",
        currencyWithCentsMetricDef
      );
    case AVERAGE_CPC_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "cost",
        "clicks",
        currencyWithCentsMetricDef
      );
    case ADD_TO_CART_CLICKS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "addToCartClicks",
        roundedNumberMetricDef
      );
    case ADD_TO_CART_RATE_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "addToCartClicks",
        "clicks",
        showFractions
          ? fractionalPercentageMetricDef
          : oneDecimalPercentageMetricDef
      );
    case CONVERSIONS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "conversions",
        roundedNumberMetricDef
      );
    case CONVERSION_RATE_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "conversions",
        "clicks",
        showFractions
          ? fractionalPercentageMetricDef
          : oneDecimalPercentageMetricDef
      );
    case UNITS_SOLD_COL:
      return formatSimpleMetricRounded(
        metrics,
        "unitsSold",
        roundedNumberMetricDef
      );
    case PURCHASE_FREQUENCY_COL:
      return formatSimpleMetricRounded(
        metrics,
        "purchaseFrequency",
        fractionalNumberMetricDef
      );
    case REVENUE_COL:
      formattedValue = formatSimpleMetricRounded(
        metrics,
        "revenue",
        showFractions
          ? revenueMetricDefs.currencyWithCentsMetricDef
          : revenueMetricDefs.currencyNoCentsMetricDef
      );

      if (
        showUnconvertedRevenue &&
        revenueMetricDefs.unconvertedRevenueCurrencyCode
      ) {
        formattedValue.annotation = formatSimpleMetricRounded(
          metrics,
          "unconvertedRevenue",
          showFractions
            ? revenueMetricDefs.unconvertedRevenueCurrencyWithCentsMetricDef
            : revenueMetricDefs.unconvertedRevenueCurrencyNoCentsMetricDef
        );
      }

      return formattedValue;
    case ANNUALIZED_REVENUE_COL:
      return formatSimpleMetricRounded(
        metrics,
        "annualizedRevenue",
        showFractions
          ? revenueMetricDefs.currencyWithCentsMetricDef
          : revenueMetricDefs.currencyNoCentsMetricDef
      );
    case ATTRIBUTED_CLICKS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "attributedClicks",
        roundedNumberMetricDef
      );
    case DETAIL_PAGE_VIEWS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "detailPageViewClicks",
        roundedNumberMetricDef
      );
    case NEW_TO_BRAND_CONVERSIONS_COL:
      return formatSimpleMetricRounded(
        metrics,
        "newToBrandConversions",
        roundedNumberMetricDef
      );
    case NEW_TO_BRAND_CONVERSIONS_PERCENTAGE_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "newToBrandConversions",
        "conversions",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case NEW_TO_BRAND_REVENUE_COL:
      return formatSimpleMetricRounded(
        metrics,
        "newToBrandRevenue",
        showFractions
          ? revenueMetricDefs.currencyWithCentsMetricDef
          : revenueMetricDefs.currencyNoCentsMetricDef
      );
    case NEW_TO_BRAND_REVENUE_PERCENTAGE_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "newToBrandRevenue",
        "revenue",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case NEW_TO_BRAND_UNITS_SOLD_COL:
      return formatSimpleMetricRounded(
        metrics,
        "newToBrandUnitsSold",
        roundedNumberMetricDef
      );
    case NEW_TO_BRAND_UNITS_SOLD_PERCENTAGE_COL:
      return formatSimpleMetricRatioRounded(
        metrics,
        "newToBrandUnitsSold",
        "unitsSold",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case ROAS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "revenue",
        "cost",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case AROAS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "revenue",
        "adjustedCost",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case NEW_TO_BRAND_ROAS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }
      return formatSimpleMetricRatioRounded(
        metrics,
        "newToBrandRevenue",
        "cost",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case ANNUALIZED_ROAS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "annualizedRevenue",
        "cost",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case ANNUALIZED_AROAS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "annualizedRevenue",
        "adjustedCost",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case ACOS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "cost",
        "revenue",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case AACOS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "adjustedCost",
        "revenue",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case ANNUALIZED_ACOS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "cost",
        "annualizedRevenue",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case ANNUALIZED_AACOS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "adjustedCost",
        "annualizedRevenue",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case TACOS_COL:
      if (!revenueMetricDefs.compatible) {
        return noMetricsValue;
      }

      return formatSimpleMetricRatioRounded(
        metrics,
        "parentAsinAdjustedAdsCost",
        "parentAsinRevenue",
        showFractions
          ? fractionalPercentageMetricDef
          : roundedPercentageMetricDef
      );
    case BRAND_REFERRAL_BONUS_COL:
      formattedValue = formatSimpleMetricRounded(
        metrics,
        "brandReferralBonus",
        showFractions
          ? revenueMetricDefs.currencyWithCentsMetricDef
          : revenueMetricDefs.currencyNoCentsMetricDef
      );

      if (
        showUnconvertedRevenue &&
        revenueMetricDefs.unconvertedRevenueCurrencyCode
      ) {
        formattedValue.annotation = formatSimpleMetricRounded(
          metrics,
          "unconvertedBrandReferralBonus",
          showFractions
            ? revenueMetricDefs.unconvertedRevenueCurrencyWithCentsMetricDef
            : revenueMetricDefs.unconvertedRevenueCurrencyNoCentsMetricDef
        );
      }

      return formattedValue;

    default:
      break;
  }

  return noMetricsValue || "";
}

// Renders the formattedValue.  If annotationValue is specified or if the
// formattedValue has an 'annotation' field, the that value is rendered after
// a <br> in parentheses.  Similarly, if a compareInfo object is specified,
// then either a spinner or a difference percentage is rendered below the
// formattedValue.
export function renderFormattedValue(
  formattedValue,
  annotationValue,
  compareInfo
) {
  // TODO: prettier update prettier config to handle latest JS features
  // eslint-disable-next-line prettier/prettier
  annotationValue ??= formattedValue?.annotation;
  if (annotationValue == null && !compareInfo) {
    return formattedValue;
  }

  const CompareStyle = compareInfo?.compareStyle || CompareNeutral;
  const annotationTooltip = annotationValue?.tooltip;

  return (
    <span>
      {formattedValue}
      <br />
      {annotationValue != null && (
        <SimpleTooltip
          position="bottom right"
          disabled={!annotationTooltip}
          tooltip={annotationTooltip}
        >
          <MetricAnnotationSpan>[{annotationValue}]</MetricAnnotationSpan>
        </SimpleTooltip>
      )}
      {compareInfo &&
        (compareInfo.loading ? (
          <InlineLoadingSpinner />
        ) : (
          <SimpleTooltip
            position="bottom right"
            tooltip={
              <div>
                Difference: {compareInfo.diffFormatted}
                <br />
                Previous: {compareInfo.previousFormatted}
              </div>
            }
          >
            <CompareStyle>{compareInfo.diffString}</CompareStyle>
          </SimpleTooltip>
        ))}
    </span>
  );
}

// Returns the formattedValue if an annotationValue is not specified or the
// formattedValue does not have an 'annotation' field.  Otherwise, it returns
// the two values separated by a space, and with the annotation value in parentheses.
export function exportFormattedValue(formattedValue, annotationValue) {
  annotationValue ??= formattedValue?.annotation;
  if (annotationValue == null) {
    return formattedValue;
  }

  return `${formattedValue} (${annotationValue})`;
}

export function getMetricsAccumulator() {
  return {
    impressions: 0,
    clicks: 0,
    cost: 0,
    conversions: 0,
    revenue: 0,
    brandReferralBonus: 0,
    adjustedCost: 0,
    unconvertedRevenue: 0,
    unconvertedBrandReferralBonus: 0,
    addToCartClicks: 0,
    unitsSold: 0,
    attributedClicks: 0,
    detailPageViewClicks: 0,
    newToBrandConversions: 0,
    newToBrandRevenue: 0,
    newToBrandUnitsSold: 0,
    maxDataWithheldPercent: 0
  };
}

export function accumulateMetrics(metricsAggregate, metrics) {
  if (!metrics) {
    return;
  }

  if (!metricsAggregate.costCurrencyCode) {
    metricsAggregate.costCurrencyCode = metrics.costCurrencyCode;
  } else if (
    metrics.costCurrencyCode &&
    metricsAggregate.costCurrencyCode !== metrics.costCurrencyCode
  ) {
    // Mixed cost currencies in the aggregate, so use the special "No currency" code.
    metricsAggregate.costCurrencyCode = "XXX";
  }

  if (!metricsAggregate.revenueCurrencyCode) {
    metricsAggregate.revenueCurrencyCode = metrics.revenueCurrencyCode;
  } else if (
    metrics.revenueCurrencyCode &&
    metricsAggregate.revenueCurrencyCode !== metrics.revenueCurrencyCode
  ) {
    // Mixed cost currencies in the aggregate, so use the special "No currency" code.
    metricsAggregate.revenueCurrencyCode = "XXX";
  }

  if (!metricsAggregate.unconvertedRevenueCurrencyCode) {
    metricsAggregate.unconvertedRevenueCurrencyCode =
      metrics.unconvertedRevenueCurrencyCode;
  } else if (
    metrics.unconvertedRevenueCurrencyCode &&
    metricsAggregate.unconvertedRevenueCurrencyCode !==
      metrics.unconvertedRevenueCurrencyCode
  ) {
    // Mixed cost currencies in the aggregate, so use the special "No currency" code.
    metricsAggregate.unconvertedRevenueCurrencyCode = "XXX";
  }

  if (!isNaN(metrics.impressions)) {
    metricsAggregate.impressions += metrics.impressions;
  }
  if (!isNaN(metrics.clicks)) {
    metricsAggregate.clicks += metrics.clicks;
  }
  if (!isNaN(metrics.cost)) {
    metricsAggregate.cost += metrics.cost;
  }
  if (!isNaN(metrics.conversions)) {
    metricsAggregate.conversions += metrics.conversions;
  }
  if (!isNaN(metrics.revenue)) {
    metricsAggregate.revenue += metrics.revenue;
  }
  if (!isNaN(metrics.brandReferralBonus)) {
    metricsAggregate.brandReferralBonus += metrics.brandReferralBonus;
  }
  if (!isNaN(metrics.adjustedCost)) {
    metricsAggregate.adjustedCost += metrics.adjustedCost;
  }
  if (!isNaN(metrics.unconvertedRevenue)) {
    metricsAggregate.unconvertedRevenue += metrics.unconvertedRevenue;
  }
  if (!isNaN(metrics.unconvertedBrandReferralBonus)) {
    metricsAggregate.unconvertedBrandReferralBonus +=
      metrics.unconvertedBrandReferralBonus;
  }
  if (!isNaN(metrics.addToCartClicks)) {
    metricsAggregate.addToCartClicks += metrics.addToCartClicks;
  }
  if (!isNaN(metrics.unitsSold)) {
    metricsAggregate.unitsSold += metrics.unitsSold;
  }
  if (!isNaN(metrics.attributedClicks)) {
    metricsAggregate.attributedClicks += metrics.attributedClicks;
  }
  if (!isNaN(metrics.detailPageViewClicks)) {
    metricsAggregate.detailPageViewClicks += metrics.detailPageViewClicks;
  }
  if (!isNaN(metrics.newToBrandConversions)) {
    metricsAggregate.newToBrandConversions += metrics.newToBrandConversions;
  }
  if (!isNaN(metrics.newToBrandRevenue)) {
    metricsAggregate.newToBrandRevenue += metrics.newToBrandRevenue;
  }
  if (!isNaN(metrics.newToBrandUnitsSold)) {
    metricsAggregate.newToBrandUnitsSold += metrics.newToBrandUnitsSold;
  }
  if (metrics.maxDataWithheldPercent) {
    metricsAggregate.maxDataWithheldPercent = Math.max(
      metricsAggregate.maxDataWithheldPercent,
      metrics.maxDataWithheldPercent
    );
  }
}

// Return a simple string that describes the number of days between the two dates,
// for example "1 Day", "2 Day", "11 Day".
export function formatDayCountLabel(startDate, endDate) {
  if (!startDate || !endDate) {
    return "";
  }

  if (startDate === endDate) {
    return "1-Day";
  }

  const diff = moment(endDate)
    .startOf("day")
    .diff(moment(startDate), "day");

  return `${diff + 1}-Day`;
}

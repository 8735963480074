// source: proto/edge/grpcwebPb/grpcweb_Campaigns.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_resources_ad_group_criterion_pb = require('../../../google/ads/googleads/v16/resources/ad_group_criterion_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_criterion_pb);
var proto_ampdPb_resourceConfiguration_pb = require('../../../proto/ampdPb/resourceConfiguration_pb.js');
goog.object.extend(proto, proto_ampdPb_resourceConfiguration_pb);
var proto_common_objectID_pb = require('../../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
var proto_common_productDetails_pb = require('../../../proto/common/productDetails_pb.js');
goog.object.extend(proto, proto_common_productDetails_pb);
var proto_entity_campaign_pb = require('../../../proto/entity/campaign_pb.js');
goog.object.extend(proto, proto_entity_campaign_pb);
var proto_errorPb_error_pb = require('../../../proto/errorPb/error_pb.js');
goog.object.extend(proto, proto_errorPb_error_pb);
var proto_googleAdsPb_ad_pb = require('../../../proto/googleAdsPb/ad_pb.js');
goog.object.extend(proto, proto_googleAdsPb_ad_pb);
var proto_googleAdsPb_adGroup_pb = require('../../../proto/googleAdsPb/adGroup_pb.js');
goog.object.extend(proto, proto_googleAdsPb_adGroup_pb);
var proto_googleAdsPb_adGroupCriteria_pb = require('../../../proto/googleAdsPb/adGroupCriteria_pb.js');
goog.object.extend(proto, proto_googleAdsPb_adGroupCriteria_pb);
var proto_googleAdsPb_campaign_pb = require('../../../proto/googleAdsPb/campaign_pb.js');
goog.object.extend(proto, proto_googleAdsPb_campaign_pb);
var proto_googleAdsPb_campaignBudget_pb = require('../../../proto/googleAdsPb/campaignBudget_pb.js');
goog.object.extend(proto, proto_googleAdsPb_campaignBudget_pb);
var proto_googleAdsPb_campaignCriteria_pb = require('../../../proto/googleAdsPb/campaignCriteria_pb.js');
goog.object.extend(proto, proto_googleAdsPb_campaignCriteria_pb);
var proto_googleAdsPb_keyword_pb = require('../../../proto/googleAdsPb/keyword_pb.js');
goog.object.extend(proto, proto_googleAdsPb_keyword_pb);
goog.exportSymbol('proto.grpcwebPb.GetCampaignBidAutomationResultsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignBidAutomationResultsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignConfigurationsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.Option', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCampaignConfigurationsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction.ActionCase', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateCampaignsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignConfigurationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignConfigurationsRequest.displayName = 'proto.grpcwebPb.GetCampaignConfigurationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignConfigurationsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCampaignConfigurationsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignConfigurationsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignConfigurationsReply.displayName = 'proto.grpcwebPb.GetCampaignConfigurationsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.displayName = 'proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.displayName = 'proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.displayName = 'proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignBidAutomationResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.displayName = 'proto.grpcwebPb.GetCampaignBidAutomationResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCampaignBidAutomationResultsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignBidAutomationResultsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignBidAutomationResultsReply.displayName = 'proto.grpcwebPb.GetCampaignBidAutomationResultsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.displayName = 'proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateCampaignsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignsRequest.displayName = 'proto.grpcwebPb.UpdateCampaignsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignAction.displayName = 'proto.grpcwebPb.UpdateCampaignAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.displayName = 'proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.displayName = 'proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.displayName = 'proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.displayName = 'proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.displayName = 'proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateCampaignsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateCampaignsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateCampaignsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateCampaignsReply.displayName = 'proto.grpcwebPb.UpdateCampaignsReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignConfigurationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    returnStoredAmpdCampaignConfigurations: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    doUpdateStoredAmpdCampaigns: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    returnUpdatedAmpdCampaignConfigurations: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    returnUpToDateAmpdCampaignConfigurations: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    returnAllPartialCampaignConfigurations: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    doIncludeRemovedCampaigns: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignConfigurationsRequest;
  return proto.grpcwebPb.GetCampaignConfigurationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnStoredAmpdCampaignConfigurations(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoUpdateStoredAmpdCampaigns(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnUpdatedAmpdCampaignConfigurations(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnUpToDateAmpdCampaignConfigurations(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnAllPartialCampaignConfigurations(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoIncludeRemovedCampaigns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignConfigurationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReturnStoredAmpdCampaignConfigurations();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDoUpdateStoredAmpdCampaigns();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReturnUpdatedAmpdCampaignConfigurations();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getReturnUpToDateAmpdCampaignConfigurations();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getReturnAllPartialCampaignConfigurations();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDoIncludeRemovedCampaigns();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool return_stored_ampd_campaign_configurations = 2;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getReturnStoredAmpdCampaignConfigurations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setReturnStoredAmpdCampaignConfigurations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool do_update_stored_ampd_campaigns = 3;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getDoUpdateStoredAmpdCampaigns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setDoUpdateStoredAmpdCampaigns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool return_updated_ampd_campaign_configurations = 4;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getReturnUpdatedAmpdCampaignConfigurations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setReturnUpdatedAmpdCampaignConfigurations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool return_up_to_date_ampd_campaign_configurations = 5;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getReturnUpToDateAmpdCampaignConfigurations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setReturnUpToDateAmpdCampaignConfigurations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool return_all_partial_campaign_configurations = 6;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getReturnAllPartialCampaignConfigurations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setReturnAllPartialCampaignConfigurations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool do_include_removed_campaigns = 7;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.getDoIncludeRemovedCampaigns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsRequest} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsRequest.prototype.setDoIncludeRemovedCampaigns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignConfigurationsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignConfigurationsList: jspb.Message.toObjectList(msg.getCampaignConfigurationsList(),
    proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignConfigurationsReply;
  return proto.grpcwebPb.GetCampaignConfigurationsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.Option} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration;
      reader.readMessage(value,proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.deserializeBinaryFromReader);
      msg.addCampaignConfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignConfigurationsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCampaignConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType;
  return proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.Option = {
  UNSPECIFIED: 0,
  STORED: 1,
  UPDATED: 2,
  UP_TO_DATE: 3,
  PARTIAL: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    creationActionId: (f = msg.getCreationActionId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    ampdProductDetails: (f = msg.getAmpdProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f),
    ampdResourceConfiguration: (f = msg.getAmpdResourceConfiguration()) && proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && proto_entity_campaign_pb.CampaignSettings.toObject(includeInstance, f),
    bidAutomationTrainingStatus: (f = msg.getBidAutomationTrainingStatus()) && proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration;
  return proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setCreationActionId(value);
      break;
    case 1:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setAmpdProductDetails(value);
      break;
    case 2:
      var value = new proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration;
      reader.readMessage(value,proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration.deserializeBinaryFromReader);
      msg.setAmpdResourceConfiguration(value);
      break;
    case 3:
      var value = new proto_entity_campaign_pb.CampaignSettings;
      reader.readMessage(value,proto_entity_campaign_pb.CampaignSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus;
      reader.readMessage(value,proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.deserializeBinaryFromReader);
      msg.setBidAutomationTrainingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreationActionId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getAmpdProductDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
  f = message.getAmpdResourceConfiguration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entity_campaign_pb.CampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getBidAutomationTrainingStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID creation_action_id = 4;
 * @return {?proto.common.ObjectID}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.getCreationActionId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 4));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
*/
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.setCreationActionId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.clearCreationActionId = function() {
  return this.setCreationActionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.hasCreationActionId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProductDetails ampd_product_details = 1;
 * @return {?proto.common.ProductDetails}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.getAmpdProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 1));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
*/
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.setAmpdProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.clearAmpdProductDetails = function() {
  return this.setAmpdProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.hasAmpdProductDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ampdPb.ResourceConfiguration ampd_resource_configuration = 2;
 * @return {?proto.ampdPb.ResourceConfiguration}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.getAmpdResourceConfiguration = function() {
  return /** @type{?proto.ampdPb.ResourceConfiguration} */ (
    jspb.Message.getWrapperField(this, proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration, 2));
};


/**
 * @param {?proto.ampdPb.ResourceConfiguration|undefined} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
*/
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.setAmpdResourceConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.clearAmpdResourceConfiguration = function() {
  return this.setAmpdResourceConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.hasAmpdResourceConfiguration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional entity.CampaignSettings settings = 3;
 * @return {?proto.entity.CampaignSettings}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.getSettings = function() {
  return /** @type{?proto.entity.CampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto_entity_campaign_pb.CampaignSettings, 3));
};


/**
 * @param {?proto.entity.CampaignSettings|undefined} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
*/
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BidAutomationTrainingStatus bid_automation_training_status = 5;
 * @return {?proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.getBidAutomationTrainingStatus = function() {
  return /** @type{?proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus, 5));
};


/**
 * @param {?proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus|undefined} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
*/
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.setBidAutomationTrainingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.clearBidAutomationTrainingStatus = function() {
  return this.setBidAutomationTrainingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration.prototype.hasBidAutomationTrainingStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastRunTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trainingProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    estimatedCompletionDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recommendedMinimumBudget: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    targetedVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus;
  return proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastRunTime(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option} */ (reader.readEnum());
      msg.setStatusCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrainingProgress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedCompletionDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRecommendedMinimumBudget(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetedVoltage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastRunTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrainingProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getEstimatedCompletionDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecommendedMinimumBudget();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTargetedVoltage();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string last_run_time = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getLastRunTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setLastRunTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option status_code = 2;
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getStatusCode = function() {
  return /** @type {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string status_message = 3;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double training_progress = 4;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getTrainingProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setTrainingProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string estimated_completion_date = 5;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getEstimatedCompletionDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setEstimatedCompletionDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float recommended_minimum_budget = 6;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getRecommendedMinimumBudget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setRecommendedMinimumBudget = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double targeted_voltage = 7;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.getTargetedVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.BidAutomationTrainingStatus.prototype.setTargetedVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional CampaignConfigurationType.Option type = 1;
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.Option}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.getType = function() {
  return /** @type {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfigurationType.Option} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated CampaignConfiguration campaign_configurations = 2;
 * @return {!Array<!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration>}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.getCampaignConfigurationsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration, 2));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration>} value
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply} returns this
*/
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.setCampaignConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration}
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.addCampaignConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpcwebPb.GetCampaignConfigurationsReply.CampaignConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCampaignConfigurationsReply} returns this
 */
proto.grpcwebPb.GetCampaignConfigurationsReply.prototype.clearCampaignConfigurationsList = function() {
  return this.setCampaignConfigurationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignBidAutomationResultsRequest;
  return proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string campaign_id = 3;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsRequest} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignBidAutomationResultsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    qualified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorMessagesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    searchVolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    targetedVoltageLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    voltageCharacteristicsList: jspb.Message.toObjectList(msg.getVoltageCharacteristicsList(),
    proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignBidAutomationResultsReply;
  return proto.grpcwebPb.GetCampaignBidAutomationResultsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQualified(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrorMessages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSearchVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetedVoltageLevel(value);
      break;
    case 6:
      var value = new proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics;
      reader.readMessage(value,proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.deserializeBinaryFromReader);
      msg.addVoltageCharacteristics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignBidAutomationResultsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQualified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSearchVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTargetedVoltageLevel();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getVoltageCharacteristicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.toObject = function(includeInstance, msg) {
  var f, obj = {
    voltageLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    clicksLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clicksMid: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    clicksHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    acosLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    acosMid: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    acosHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    aacosLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    aacosMid: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    aacosHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    costLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    costMid: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    costHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    revenueLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    revenueMid: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    revenueHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    recommendedBudget: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics;
  return proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVoltageLevel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClicksLow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClicksMid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClicksHigh(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAcosLow(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAcosMid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAcosHigh(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAacosLow(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAacosMid(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAacosHigh(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCostLow(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCostMid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCostHigh(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenueLow(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenueMid(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenueHigh(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRecommendedBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVoltageLevel();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getClicksLow();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClicksMid();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getClicksHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAcosLow();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAcosMid();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAcosHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAacosLow();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getAacosMid();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getAacosHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getCostLow();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCostMid();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getCostHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getRevenueLow();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRevenueMid();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getRevenueHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getRecommendedBudget();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
};


/**
 * optional double voltage_level = 1;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getVoltageLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setVoltageLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double clicks_low = 2;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getClicksLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setClicksLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double clicks_mid = 3;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getClicksMid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setClicksMid = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double clicks_high = 4;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getClicksHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setClicksHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double acos_low = 5;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getAcosLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setAcosLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double acos_mid = 6;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getAcosMid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setAcosMid = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double acos_high = 7;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getAcosHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setAcosHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double aacos_low = 15;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getAacosLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setAacosLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double aacos_mid = 16;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getAacosMid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setAacosMid = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double aacos_high = 17;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getAacosHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setAacosHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double cost_low = 8;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getCostLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setCostLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double cost_mid = 9;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getCostMid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setCostMid = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double cost_high = 10;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getCostHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setCostHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double revenue_low = 11;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getRevenueLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setRevenueLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double revenue_mid = 12;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getRevenueMid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setRevenueMid = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double revenue_high = 13;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getRevenueHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setRevenueHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double recommended_budget = 14;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.getRecommendedBudget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics.prototype.setRecommendedBudget = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool qualified = 2;
 * @return {boolean}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.getQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.setQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string error_messages = 3;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.getErrorMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.setErrorMessagesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.addErrorMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.clearErrorMessagesList = function() {
  return this.setErrorMessagesList([]);
};


/**
 * optional double search_volume = 4;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.getSearchVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.setSearchVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double targeted_voltage_level = 5;
 * @return {number}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.getTargetedVoltageLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.setTargetedVoltageLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated VoltageCharacteristics voltage_characteristics = 6;
 * @return {!Array<!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics>}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.getVoltageCharacteristicsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics, 6));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics>} value
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
*/
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.setVoltageCharacteristicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics}
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.addVoltageCharacteristics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.grpcwebPb.GetCampaignBidAutomationResultsReply.VoltageCharacteristics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCampaignBidAutomationResultsReply} returns this
 */
proto.grpcwebPb.GetCampaignBidAutomationResultsReply.prototype.clearVoltageCharacteristicsList = function() {
  return this.setVoltageCharacteristicsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateCampaignsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.grpcwebPb.UpdateCampaignAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignsRequest}
 */
proto.grpcwebPb.UpdateCampaignsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignsRequest;
  return proto.grpcwebPb.UpdateCampaignsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignsRequest}
 */
proto.grpcwebPb.UpdateCampaignsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.UpdateCampaignAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateCampaignAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.grpcwebPb.UpdateCampaignAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignsRequest} returns this
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignsRequest} returns this
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UpdateCampaignAction actions = 3;
 * @return {!Array<!proto.grpcwebPb.UpdateCampaignAction>}
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.UpdateCampaignAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.UpdateCampaignAction, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.UpdateCampaignAction>} value
 * @return {!proto.grpcwebPb.UpdateCampaignsRequest} returns this
*/
proto.grpcwebPb.UpdateCampaignsRequest.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.grpcwebPb.UpdateCampaignAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateCampaignAction}
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.grpcwebPb.UpdateCampaignAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateCampaignsRequest} returns this
 */
proto.grpcwebPb.UpdateCampaignsRequest.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.UpdateCampaignAction.oneofGroups_ = [[1,7,8,15,16,4,19,20,10,11,5,6,9,13,2,3,14,17,18]];

/**
 * @enum {number}
 */
proto.grpcwebPb.UpdateCampaignAction.ActionCase = {
  ACTION_NOT_SET: 0,
  UPDATE_STATUS: 1,
  UPDATE_MAX_CPC: 7,
  UPDATE_CAMPAIGN_NAME: 8,
  UPDATE_BIDDING_STRATEGY: 15,
  ADD_CAMPAIGN_KEYWORD: 16,
  UPDATE_AMAZON_SEARCH_TERMS_TO_TRACK: 4,
  UPDATE_WALMART_SEARCH_TERMS_TO_TRACK: 19,
  UPDATE_PRODUCT_DETAILS: 20,
  UPDATE_PAUSING_AUTOMATION_SETTINGS: 10,
  UPDATE_BID_AUTOMATION_SETTINGS: 11,
  UPDATE_CAMPAIGN_BUDGET_AMOUNT: 5,
  ADD_AD_GROUP_KEYWORD: 6,
  UPDATE_AD_GROUP_NAME: 9,
  UPDATE_AD_GROUP_DEFAULT_CPC_BID: 13,
  UPDATE_AD_TRACKING: 2,
  UPDATE_AD_GROUP_CRITERIA_STATUS: 3,
  UPDATE_AD_GROUP_CRITERIA_CPC_BID: 14,
  REMOVE_AD_GROUP_CRITERIA: 17,
  REMOVE_CAMPAIGN_CRITERIA: 18
};

/**
 * @return {proto.grpcwebPb.UpdateCampaignAction.ActionCase}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getActionCase = function() {
  return /** @type {proto.grpcwebPb.UpdateCampaignAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateStatus: (f = msg.getUpdateStatus()) && proto_googleAdsPb_campaign_pb.UpdateCampaignStatusAction.toObject(includeInstance, f),
    updateMaxCpc: (f = msg.getUpdateMaxCpc()) && proto_googleAdsPb_campaign_pb.UpdateCampaignMaxCPCAction.toObject(includeInstance, f),
    updateCampaignName: (f = msg.getUpdateCampaignName()) && proto_googleAdsPb_campaign_pb.UpdateCampaignNameAction.toObject(includeInstance, f),
    updateBiddingStrategy: (f = msg.getUpdateBiddingStrategy()) && proto_googleAdsPb_campaign_pb.UpdateCampaignBiddingStrategyAction.toObject(includeInstance, f),
    addCampaignKeyword: (f = msg.getAddCampaignKeyword()) && proto_googleAdsPb_keyword_pb.NewKeyword.toObject(includeInstance, f),
    updateAmazonSearchTermsToTrack: (f = msg.getUpdateAmazonSearchTermsToTrack()) && proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.toObject(includeInstance, f),
    updateWalmartSearchTermsToTrack: (f = msg.getUpdateWalmartSearchTermsToTrack()) && proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.toObject(includeInstance, f),
    updateProductDetails: (f = msg.getUpdateProductDetails()) && proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.toObject(includeInstance, f),
    updatePausingAutomationSettings: (f = msg.getUpdatePausingAutomationSettings()) && proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.toObject(includeInstance, f),
    updateBidAutomationSettings: (f = msg.getUpdateBidAutomationSettings()) && proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.toObject(includeInstance, f),
    updateCampaignBudgetAmount: (f = msg.getUpdateCampaignBudgetAmount()) && proto_googleAdsPb_campaignBudget_pb.UpdateCampaignBudgetAmountAction.toObject(includeInstance, f),
    addAdGroupKeyword: (f = msg.getAddAdGroupKeyword()) && proto_googleAdsPb_keyword_pb.NewKeyword.toObject(includeInstance, f),
    updateAdGroupName: (f = msg.getUpdateAdGroupName()) && proto_googleAdsPb_adGroup_pb.UpdateAdGroupNameAction.toObject(includeInstance, f),
    updateAdGroupDefaultCpcBid: (f = msg.getUpdateAdGroupDefaultCpcBid()) && proto_googleAdsPb_adGroup_pb.UpdateAdGroupDefaultCPCBidAction.toObject(includeInstance, f),
    updateAdTracking: (f = msg.getUpdateAdTracking()) && proto_googleAdsPb_ad_pb.UpdateAdTrackingAction.toObject(includeInstance, f),
    updateAdGroupCriteriaStatus: (f = msg.getUpdateAdGroupCriteriaStatus()) && proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaStatusAction.toObject(includeInstance, f),
    updateAdGroupCriteriaCpcBid: (f = msg.getUpdateAdGroupCriteriaCpcBid()) && proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaCPCBidAction.toObject(includeInstance, f),
    removeAdGroupCriteria: (f = msg.getRemoveAdGroupCriteria()) && proto_googleAdsPb_adGroupCriteria_pb.RemoveAdGroupCriteriaAction.toObject(includeInstance, f),
    removeCampaignCriteria: (f = msg.getRemoveCampaignCriteria()) && proto_googleAdsPb_campaignCriteria_pb.RemoveCampaignCriteriaAction.toObject(includeInstance, f),
    scrubNamesAndAddId: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignAction}
 */
proto.grpcwebPb.UpdateCampaignAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignAction;
  return proto.grpcwebPb.UpdateCampaignAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignAction}
 */
proto.grpcwebPb.UpdateCampaignAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_googleAdsPb_campaign_pb.UpdateCampaignStatusAction;
      reader.readMessage(value,proto_googleAdsPb_campaign_pb.UpdateCampaignStatusAction.deserializeBinaryFromReader);
      msg.setUpdateStatus(value);
      break;
    case 7:
      var value = new proto_googleAdsPb_campaign_pb.UpdateCampaignMaxCPCAction;
      reader.readMessage(value,proto_googleAdsPb_campaign_pb.UpdateCampaignMaxCPCAction.deserializeBinaryFromReader);
      msg.setUpdateMaxCpc(value);
      break;
    case 8:
      var value = new proto_googleAdsPb_campaign_pb.UpdateCampaignNameAction;
      reader.readMessage(value,proto_googleAdsPb_campaign_pb.UpdateCampaignNameAction.deserializeBinaryFromReader);
      msg.setUpdateCampaignName(value);
      break;
    case 15:
      var value = new proto_googleAdsPb_campaign_pb.UpdateCampaignBiddingStrategyAction;
      reader.readMessage(value,proto_googleAdsPb_campaign_pb.UpdateCampaignBiddingStrategyAction.deserializeBinaryFromReader);
      msg.setUpdateBiddingStrategy(value);
      break;
    case 16:
      var value = new proto_googleAdsPb_keyword_pb.NewKeyword;
      reader.readMessage(value,proto_googleAdsPb_keyword_pb.NewKeyword.deserializeBinaryFromReader);
      msg.setAddCampaignKeyword(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.deserializeBinaryFromReader);
      msg.setUpdateAmazonSearchTermsToTrack(value);
      break;
    case 19:
      var value = new proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.deserializeBinaryFromReader);
      msg.setUpdateWalmartSearchTermsToTrack(value);
      break;
    case 20:
      var value = new proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.deserializeBinaryFromReader);
      msg.setUpdateProductDetails(value);
      break;
    case 10:
      var value = new proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.deserializeBinaryFromReader);
      msg.setUpdatePausingAutomationSettings(value);
      break;
    case 11:
      var value = new proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.deserializeBinaryFromReader);
      msg.setUpdateBidAutomationSettings(value);
      break;
    case 5:
      var value = new proto_googleAdsPb_campaignBudget_pb.UpdateCampaignBudgetAmountAction;
      reader.readMessage(value,proto_googleAdsPb_campaignBudget_pb.UpdateCampaignBudgetAmountAction.deserializeBinaryFromReader);
      msg.setUpdateCampaignBudgetAmount(value);
      break;
    case 6:
      var value = new proto_googleAdsPb_keyword_pb.NewKeyword;
      reader.readMessage(value,proto_googleAdsPb_keyword_pb.NewKeyword.deserializeBinaryFromReader);
      msg.setAddAdGroupKeyword(value);
      break;
    case 9:
      var value = new proto_googleAdsPb_adGroup_pb.UpdateAdGroupNameAction;
      reader.readMessage(value,proto_googleAdsPb_adGroup_pb.UpdateAdGroupNameAction.deserializeBinaryFromReader);
      msg.setUpdateAdGroupName(value);
      break;
    case 13:
      var value = new proto_googleAdsPb_adGroup_pb.UpdateAdGroupDefaultCPCBidAction;
      reader.readMessage(value,proto_googleAdsPb_adGroup_pb.UpdateAdGroupDefaultCPCBidAction.deserializeBinaryFromReader);
      msg.setUpdateAdGroupDefaultCpcBid(value);
      break;
    case 2:
      var value = new proto_googleAdsPb_ad_pb.UpdateAdTrackingAction;
      reader.readMessage(value,proto_googleAdsPb_ad_pb.UpdateAdTrackingAction.deserializeBinaryFromReader);
      msg.setUpdateAdTracking(value);
      break;
    case 3:
      var value = new proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaStatusAction;
      reader.readMessage(value,proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaStatusAction.deserializeBinaryFromReader);
      msg.setUpdateAdGroupCriteriaStatus(value);
      break;
    case 14:
      var value = new proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaCPCBidAction;
      reader.readMessage(value,proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaCPCBidAction.deserializeBinaryFromReader);
      msg.setUpdateAdGroupCriteriaCpcBid(value);
      break;
    case 17:
      var value = new proto_googleAdsPb_adGroupCriteria_pb.RemoveAdGroupCriteriaAction;
      reader.readMessage(value,proto_googleAdsPb_adGroupCriteria_pb.RemoveAdGroupCriteriaAction.deserializeBinaryFromReader);
      msg.setRemoveAdGroupCriteria(value);
      break;
    case 18:
      var value = new proto_googleAdsPb_campaignCriteria_pb.RemoveCampaignCriteriaAction;
      reader.readMessage(value,proto_googleAdsPb_campaignCriteria_pb.RemoveCampaignCriteriaAction.deserializeBinaryFromReader);
      msg.setRemoveCampaignCriteria(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScrubNamesAndAddId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_googleAdsPb_campaign_pb.UpdateCampaignStatusAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMaxCpc();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_googleAdsPb_campaign_pb.UpdateCampaignMaxCPCAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateCampaignName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_googleAdsPb_campaign_pb.UpdateCampaignNameAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBiddingStrategy();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_googleAdsPb_campaign_pb.UpdateCampaignBiddingStrategyAction.serializeBinaryToWriter
    );
  }
  f = message.getAddCampaignKeyword();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_googleAdsPb_keyword_pb.NewKeyword.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAmazonSearchTermsToTrack();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateWalmartSearchTermsToTrack();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateProductDetails();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdatePausingAutomationSettings();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBidAutomationSettings();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateCampaignBudgetAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_googleAdsPb_campaignBudget_pb.UpdateCampaignBudgetAmountAction.serializeBinaryToWriter
    );
  }
  f = message.getAddAdGroupKeyword();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_googleAdsPb_keyword_pb.NewKeyword.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAdGroupName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_googleAdsPb_adGroup_pb.UpdateAdGroupNameAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAdGroupDefaultCpcBid();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_googleAdsPb_adGroup_pb.UpdateAdGroupDefaultCPCBidAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAdTracking();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_googleAdsPb_ad_pb.UpdateAdTrackingAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAdGroupCriteriaStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaStatusAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAdGroupCriteriaCpcBid();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaCPCBidAction.serializeBinaryToWriter
    );
  }
  f = message.getRemoveAdGroupCriteria();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_googleAdsPb_adGroupCriteria_pb.RemoveAdGroupCriteriaAction.serializeBinaryToWriter
    );
  }
  f = message.getRemoveCampaignCriteria();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_googleAdsPb_campaignCriteria_pb.RemoveCampaignCriteriaAction.serializeBinaryToWriter
    );
  }
  f = message.getScrubNamesAndAddId();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchTermsToTrackList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    updateTrackOrganicRankings: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    trackOrganicRankings: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction;
  return proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSearchTermsToTrack(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateTrackOrganicRankings(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackOrganicRankings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchTermsToTrackList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUpdateTrackOrganicRankings();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTrackOrganicRankings();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string action_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string search_terms_to_track = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.getSearchTermsToTrackList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.setSearchTermsToTrackList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.addSearchTermsToTrack = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.clearSearchTermsToTrackList = function() {
  return this.setSearchTermsToTrackList([]);
};


/**
 * optional bool update_track_organic_rankings = 3;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.getUpdateTrackOrganicRankings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.setUpdateTrackOrganicRankings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool track_organic_rankings = 4;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.getTrackOrganicRankings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction.prototype.setTrackOrganicRankings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchTermsToTrackList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction;
  return proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSearchTermsToTrack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchTermsToTrackList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string action_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string search_terms_to_track = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.getSearchTermsToTrackList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.setSearchTermsToTrackList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.addSearchTermsToTrack = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction.prototype.clearSearchTermsToTrackList = function() {
  return this.setSearchTermsToTrackList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateProductDetails: (f = msg.getUpdateProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f),
    compareProductDetails: (f = msg.getCompareProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction;
  return proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 2:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setUpdateProductDetails(value);
      break;
    case 3:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setCompareProductDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateProductDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
  f = message.getCompareProductDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string action_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ProductDetails update_product_details = 2;
 * @return {?proto.common.ProductDetails}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.getUpdateProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 2));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.setUpdateProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.clearUpdateProductDetails = function() {
  return this.setUpdateProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.hasUpdateProductDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProductDetails compare_product_details = 3;
 * @return {?proto.common.ProductDetails}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.getCompareProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 3));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.setCompareProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.clearCompareProductDetails = function() {
  return this.setCompareProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction.prototype.hasCompareProductDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cost28DayThresholdMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    aacos28DayThresholdPoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pauseScoreThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    reevaluatePausedKeywords: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    minimumNumberOfActiveKeywords: jspb.Message.getFieldWithDefault(msg, 7, 0),
    runFrequencyDays: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction;
  return proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost28DayThresholdMicros(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAacos28DayThresholdPoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPauseScoreThreshold(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReevaluatePausedKeywords(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumNumberOfActiveKeywords(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRunFrequencyDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.CampaignSettings.AutomationStatus.Option status = 2;
 * @return {!proto.entity.CampaignSettings.AutomationStatus.Option}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getStatus = function() {
  return /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.CampaignSettings.AutomationStatus.Option} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 cost_28_day_threshold_micros = 3;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getCost28DayThresholdMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setCost28DayThresholdMicros = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.clearCost28DayThresholdMicros = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.hasCost28DayThresholdMicros = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 aacos_28_day_threshold_points = 4;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getAacos28DayThresholdPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setAacos28DayThresholdPoints = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.clearAacos28DayThresholdPoints = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.hasAacos28DayThresholdPoints = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double pause_score_threshold = 5;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getPauseScoreThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setPauseScoreThreshold = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.clearPauseScoreThreshold = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.hasPauseScoreThreshold = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool reevaluate_paused_keywords = 6;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getReevaluatePausedKeywords = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setReevaluatePausedKeywords = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.clearReevaluatePausedKeywords = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.hasReevaluatePausedKeywords = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 minimum_number_of_active_keywords = 7;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getMinimumNumberOfActiveKeywords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setMinimumNumberOfActiveKeywords = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.clearMinimumNumberOfActiveKeywords = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.hasMinimumNumberOfActiveKeywords = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double run_frequency_days = 8;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.getRunFrequencyDays = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.setRunFrequencyDays = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.clearRunFrequencyDays = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction.prototype.hasRunFrequencyDays = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    targetVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    runFrequencyDays: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction;
  return proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetVoltage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRunFrequencyDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.CampaignSettings.AutomationStatus.Option status = 2;
 * @return {!proto.entity.CampaignSettings.AutomationStatus.Option}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.getStatus = function() {
  return /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.CampaignSettings.AutomationStatus.Option} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double target_voltage = 3;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.getTargetVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.setTargetVoltage = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.clearTargetVoltage = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.hasTargetVoltage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double run_frequency_days = 4;
 * @return {number}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.getRunFrequencyDays = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.setRunFrequencyDays = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.clearRunFrequencyDays = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction.prototype.hasRunFrequencyDays = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional googleAdsPb.UpdateCampaignStatusAction update_status = 1;
 * @return {?proto.googleAdsPb.UpdateCampaignStatusAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateStatus = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignStatusAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_campaign_pb.UpdateCampaignStatusAction, 1));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignStatusAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateStatus = function() {
  return this.setUpdateStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional googleAdsPb.UpdateCampaignMaxCPCAction update_max_cpc = 7;
 * @return {?proto.googleAdsPb.UpdateCampaignMaxCPCAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateMaxCpc = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignMaxCPCAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_campaign_pb.UpdateCampaignMaxCPCAction, 7));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignMaxCPCAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateMaxCpc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateMaxCpc = function() {
  return this.setUpdateMaxCpc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateMaxCpc = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional googleAdsPb.UpdateCampaignNameAction update_campaign_name = 8;
 * @return {?proto.googleAdsPb.UpdateCampaignNameAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateCampaignName = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignNameAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_campaign_pb.UpdateCampaignNameAction, 8));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignNameAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateCampaignName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateCampaignName = function() {
  return this.setUpdateCampaignName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateCampaignName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional googleAdsPb.UpdateCampaignBiddingStrategyAction update_bidding_strategy = 15;
 * @return {?proto.googleAdsPb.UpdateCampaignBiddingStrategyAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateBiddingStrategy = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignBiddingStrategyAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_campaign_pb.UpdateCampaignBiddingStrategyAction, 15));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignBiddingStrategyAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateBiddingStrategy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateBiddingStrategy = function() {
  return this.setUpdateBiddingStrategy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateBiddingStrategy = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional googleAdsPb.NewKeyword add_campaign_keyword = 16;
 * @return {?proto.googleAdsPb.NewKeyword}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getAddCampaignKeyword = function() {
  return /** @type{?proto.googleAdsPb.NewKeyword} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_keyword_pb.NewKeyword, 16));
};


/**
 * @param {?proto.googleAdsPb.NewKeyword|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setAddCampaignKeyword = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearAddCampaignKeyword = function() {
  return this.setAddCampaignKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasAddCampaignKeyword = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional UpdateAmazonSearchTermsToTrackAction update_amazon_search_terms_to_track = 4;
 * @return {?proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateAmazonSearchTermsToTrack = function() {
  return /** @type{?proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction, 4));
};


/**
 * @param {?proto.grpcwebPb.UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateAmazonSearchTermsToTrack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateAmazonSearchTermsToTrack = function() {
  return this.setUpdateAmazonSearchTermsToTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateAmazonSearchTermsToTrack = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdateWalmartSearchTermsToTrackAction update_walmart_search_terms_to_track = 19;
 * @return {?proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateWalmartSearchTermsToTrack = function() {
  return /** @type{?proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction, 19));
};


/**
 * @param {?proto.grpcwebPb.UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateWalmartSearchTermsToTrack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateWalmartSearchTermsToTrack = function() {
  return this.setUpdateWalmartSearchTermsToTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateWalmartSearchTermsToTrack = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional UpdateProductDetailsAction update_product_details = 20;
 * @return {?proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateProductDetails = function() {
  return /** @type{?proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction, 20));
};


/**
 * @param {?proto.grpcwebPb.UpdateCampaignAction.UpdateProductDetailsAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateProductDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateProductDetails = function() {
  return this.setUpdateProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateProductDetails = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional UpdatePausingAutomationSettingsAction update_pausing_automation_settings = 10;
 * @return {?proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdatePausingAutomationSettings = function() {
  return /** @type{?proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction, 10));
};


/**
 * @param {?proto.grpcwebPb.UpdateCampaignAction.UpdatePausingAutomationSettingsAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdatePausingAutomationSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdatePausingAutomationSettings = function() {
  return this.setUpdatePausingAutomationSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdatePausingAutomationSettings = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional UpdateBidAutomationSettingsAction update_bid_automation_settings = 11;
 * @return {?proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateBidAutomationSettings = function() {
  return /** @type{?proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction, 11));
};


/**
 * @param {?proto.grpcwebPb.UpdateCampaignAction.UpdateBidAutomationSettingsAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateBidAutomationSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateBidAutomationSettings = function() {
  return this.setUpdateBidAutomationSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateBidAutomationSettings = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional googleAdsPb.UpdateCampaignBudgetAmountAction update_campaign_budget_amount = 5;
 * @return {?proto.googleAdsPb.UpdateCampaignBudgetAmountAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateCampaignBudgetAmount = function() {
  return /** @type{?proto.googleAdsPb.UpdateCampaignBudgetAmountAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_campaignBudget_pb.UpdateCampaignBudgetAmountAction, 5));
};


/**
 * @param {?proto.googleAdsPb.UpdateCampaignBudgetAmountAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateCampaignBudgetAmount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateCampaignBudgetAmount = function() {
  return this.setUpdateCampaignBudgetAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateCampaignBudgetAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional googleAdsPb.NewKeyword add_ad_group_keyword = 6;
 * @return {?proto.googleAdsPb.NewKeyword}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getAddAdGroupKeyword = function() {
  return /** @type{?proto.googleAdsPb.NewKeyword} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_keyword_pb.NewKeyword, 6));
};


/**
 * @param {?proto.googleAdsPb.NewKeyword|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setAddAdGroupKeyword = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearAddAdGroupKeyword = function() {
  return this.setAddAdGroupKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasAddAdGroupKeyword = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional googleAdsPb.UpdateAdGroupNameAction update_ad_group_name = 9;
 * @return {?proto.googleAdsPb.UpdateAdGroupNameAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateAdGroupName = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupNameAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_adGroup_pb.UpdateAdGroupNameAction, 9));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupNameAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateAdGroupName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateAdGroupName = function() {
  return this.setUpdateAdGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateAdGroupName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional googleAdsPb.UpdateAdGroupDefaultCPCBidAction update_ad_group_default_cpc_bid = 13;
 * @return {?proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateAdGroupDefaultCpcBid = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_adGroup_pb.UpdateAdGroupDefaultCPCBidAction, 13));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateAdGroupDefaultCpcBid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateAdGroupDefaultCpcBid = function() {
  return this.setUpdateAdGroupDefaultCpcBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateAdGroupDefaultCpcBid = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional googleAdsPb.UpdateAdTrackingAction update_ad_tracking = 2;
 * @return {?proto.googleAdsPb.UpdateAdTrackingAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateAdTracking = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdTrackingAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_ad_pb.UpdateAdTrackingAction, 2));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdTrackingAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateAdTracking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateAdTracking = function() {
  return this.setUpdateAdTracking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateAdTracking = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional googleAdsPb.UpdateAdGroupCriteriaStatusAction update_ad_group_criteria_status = 3;
 * @return {?proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateAdGroupCriteriaStatus = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaStatusAction, 3));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupCriteriaStatusAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateAdGroupCriteriaStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateAdGroupCriteriaStatus = function() {
  return this.setUpdateAdGroupCriteriaStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateAdGroupCriteriaStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional googleAdsPb.UpdateAdGroupCriteriaCPCBidAction update_ad_group_criteria_cpc_bid = 14;
 * @return {?proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getUpdateAdGroupCriteriaCpcBid = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_adGroupCriteria_pb.UpdateAdGroupCriteriaCPCBidAction, 14));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupCriteriaCPCBidAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setUpdateAdGroupCriteriaCpcBid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearUpdateAdGroupCriteriaCpcBid = function() {
  return this.setUpdateAdGroupCriteriaCpcBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasUpdateAdGroupCriteriaCpcBid = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional googleAdsPb.RemoveAdGroupCriteriaAction remove_ad_group_criteria = 17;
 * @return {?proto.googleAdsPb.RemoveAdGroupCriteriaAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getRemoveAdGroupCriteria = function() {
  return /** @type{?proto.googleAdsPb.RemoveAdGroupCriteriaAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_adGroupCriteria_pb.RemoveAdGroupCriteriaAction, 17));
};


/**
 * @param {?proto.googleAdsPb.RemoveAdGroupCriteriaAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setRemoveAdGroupCriteria = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearRemoveAdGroupCriteria = function() {
  return this.setRemoveAdGroupCriteria(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasRemoveAdGroupCriteria = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional googleAdsPb.RemoveCampaignCriteriaAction remove_campaign_criteria = 18;
 * @return {?proto.googleAdsPb.RemoveCampaignCriteriaAction}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getRemoveCampaignCriteria = function() {
  return /** @type{?proto.googleAdsPb.RemoveCampaignCriteriaAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_campaignCriteria_pb.RemoveCampaignCriteriaAction, 18));
};


/**
 * @param {?proto.googleAdsPb.RemoveCampaignCriteriaAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
*/
proto.grpcwebPb.UpdateCampaignAction.prototype.setRemoveCampaignCriteria = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.grpcwebPb.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.clearRemoveCampaignCriteria = function() {
  return this.setRemoveCampaignCriteria(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.hasRemoveCampaignCriteria = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool scrub_names_and_add_id = 12;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.getScrubNamesAndAddId = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateCampaignAction} returns this
 */
proto.grpcwebPb.UpdateCampaignAction.prototype.setScrubNamesAndAddId = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateCampaignsReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateCampaignsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateCampaignsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAdGroupKeywordsList: jspb.Message.toObjectList(msg.getNewAdGroupKeywordsList(),
    google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.toObject, includeInstance),
    addAdGroupKeywordFailuresList: jspb.Message.toObjectList(msg.getAddAdGroupKeywordFailuresList(),
    proto_errorPb_error_pb.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateCampaignsReply}
 */
proto.grpcwebPb.UpdateCampaignsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateCampaignsReply;
  return proto.grpcwebPb.UpdateCampaignsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateCampaignsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateCampaignsReply}
 */
proto.grpcwebPb.UpdateCampaignsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.deserializeBinaryFromReader);
      msg.addNewAdGroupKeywords(value);
      break;
    case 2:
      var value = new proto_errorPb_error_pb.Error;
      reader.readMessage(value,proto_errorPb_error_pb.Error.deserializeBinaryFromReader);
      msg.addAddAdGroupKeywordFailures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateCampaignsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateCampaignsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateCampaignsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAdGroupKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.serializeBinaryToWriter
    );
  }
  f = message.getAddAdGroupKeywordFailuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_errorPb_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated google.ads.googleads.v16.resources.AdGroupCriterion new_ad_group_keywords = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.AdGroupCriterion>}
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.getNewAdGroupKeywordsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.AdGroupCriterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.AdGroupCriterion>} value
 * @return {!proto.grpcwebPb.UpdateCampaignsReply} returns this
*/
proto.grpcwebPb.UpdateCampaignsReply.prototype.setNewAdGroupKeywordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.AdGroupCriterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.AdGroupCriterion}
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.addNewAdGroupKeywords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v16.resources.AdGroupCriterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateCampaignsReply} returns this
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.clearNewAdGroupKeywordsList = function() {
  return this.setNewAdGroupKeywordsList([]);
};


/**
 * repeated errorPb.Error add_ad_group_keyword_failures = 2;
 * @return {!Array<!proto.errorPb.Error>}
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.getAddAdGroupKeywordFailuresList = function() {
  return /** @type{!Array<!proto.errorPb.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_errorPb_error_pb.Error, 2));
};


/**
 * @param {!Array<!proto.errorPb.Error>} value
 * @return {!proto.grpcwebPb.UpdateCampaignsReply} returns this
*/
proto.grpcwebPb.UpdateCampaignsReply.prototype.setAddAdGroupKeywordFailuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.errorPb.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.errorPb.Error}
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.addAddAdGroupKeywordFailures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.errorPb.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateCampaignsReply} returns this
 */
proto.grpcwebPb.UpdateCampaignsReply.prototype.clearAddAdGroupKeywordFailuresList = function() {
  return this.setAddAdGroupKeywordFailuresList([]);
};


goog.object.extend(exports, proto.grpcwebPb);

/* 
  Shared Table Preferences
*/
import {
  getLocalStorageListByKey,
  setLocalStorageValue
} from "./localStorageUtil";

const EXCLUDE_LAG_PERIOD_STORAGE_KEY = "excludeLagPeriod";

export const getStoredExcludeLagPeriod = () => {
  let boolString = localStorage.getItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  if (!boolString) {
    // default behavior is to exclude the lag period if a preference has not been set by the user
    return true;
  }

  return boolString === "true";
};

export const setStoredExcludeLagPeriod = bool => {
  setLocalStorageValue(EXCLUDE_LAG_PERIOD_STORAGE_KEY, bool);
};

const SHOW_FRACTIONS_STORAGE_KEY = "showFractions";

export const getStoredShowFractions = () => {
  let boolString = localStorage.getItem(SHOW_FRACTIONS_STORAGE_KEY);
  if (!boolString) {
    // default behavior is to NOT show fractions if a preference has not been set by the user
    return false;
  }

  return boolString === "true";
};

export const setStoredShowFractions = bool => {
  setLocalStorageValue(SHOW_FRACTIONS_STORAGE_KEY, bool);
};

const SHOW_UNCONVERTED_REVENUE_STORAGE_KEY = "showUnconvertedRevenue";

export const getStoredShowUnconvertedRevenue = () => {
  let boolString = localStorage.getItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
  if (!boolString) {
    // default behavior is to show unconverted revenue numbers, if a preference has not been set by the user.
    return true;
  }

  return boolString === "true";
};

export const setStoredShowUnconvertedRevenue = bool => {
  setLocalStorageValue(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY, bool);
};

/*
  Campaigns Table Preferences
*/
const CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY = "campaignsTableDataColumns";

export const getStoredCampaignsTableDataColumns = () => {
  return getLocalStorageListByKey(CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY);
};

export const setStoredCampaignsTableDateColumns = columnsArray => {
  setLocalStorageValue(CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY, columnsArray);
};

const CAMPAIGN_STATUS_STORAGE_KEY = "campaignStatus";

export const getStoredCampaignStatusOption = () => {
  return localStorage.getItem(CAMPAIGN_STATUS_STORAGE_KEY);
};

export const setStoredCampaignStatusOption = option => {
  setLocalStorageValue(CAMPAIGN_STATUS_STORAGE_KEY, option);
};

export const resetAllCampaignsTableOptions = () => {
  localStorage.removeItem(CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY);
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Campaigns Details Table Preferences
*/

const CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY =
  "campaignDetailsTableDataColumns";

export const getStoredCampaignDetailsTableDataColumns = () => {
  return getLocalStorageListByKey(
    CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
};

export const setStoredCampaignDetailsTableDateColumns = columnsArray => {
  setLocalStorageValue(
    CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY,
    columnsArray
  );
};

export const resetAllCampaignDetailsTableOptions = () => {
  localStorage.removeItem(CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY);
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Accounts Table Preferences
*/

const ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY = "accountsTableDataColumns";

export const getStoredAccountsTableDataColumns = () => {
  return getLocalStorageListByKey(ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY);
};

export const setStoredAccountsTableDateColumns = columnsArray => {
  setLocalStorageValue(ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY, columnsArray);
};

export const resetAllAccountsTableOptions = () => {
  localStorage.removeItem(ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY);
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Facebook Table Preferences
*/
const FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY = "facebookTableDataColumns";

export const getStoredFacebookTableDataColumns = () => {
  return getLocalStorageListByKey(FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY);
};

export const setStoredFacebookTableDataColumns = columnsArray => {
  setLocalStorageValue(FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY, columnsArray);
};

export const resetAllFacebookTableOptions = () => {
  localStorage.removeItem(FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY);
};

// source: proto/entity/campaign.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_crudFields_pb = require('../../proto/common/crudFields_pb.js');
goog.object.extend(proto, proto_common_crudFields_pb);
var proto_common_objectID_pb = require('../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
var proto_common_productDetails_pb = require('../../proto/common/productDetails_pb.js');
goog.object.extend(proto, proto_common_productDetails_pb);
var proto_common_campaignPlatform_pb = require('../../proto/common/campaignPlatform_pb.js');
goog.object.extend(proto, proto_common_campaignPlatform_pb);
var proto_entity_site_pb = require('../../proto/entity/site_pb.js');
goog.object.extend(proto, proto_entity_site_pb);
var proto_ampdPb_resourceConfiguration_pb = require('../../proto/ampdPb/resourceConfiguration_pb.js');
goog.object.extend(proto, proto_ampdPb_resourceConfiguration_pb);
goog.exportSymbol('proto.entity.BidAutomationMetricSnapshot', null, global);
goog.exportSymbol('proto.entity.Campaign', null, global);
goog.exportSymbol('proto.entity.CampaignDetails', null, global);
goog.exportSymbol('proto.entity.CampaignSettings', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.AutomationDateInfo', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.AutomationStatus', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.AutomationStatus.Option', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.GoogleAds', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.TargetCase', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings', null, global);
goog.exportSymbol('proto.entity.CampaignSettings.TypeCase', null, global);
goog.exportSymbol('proto.entity.InternalCampaignDetails', null, global);
goog.exportSymbol('proto.entity.InternalCampaignDetails.BidAutomationInternalDetails', null, global);
goog.exportSymbol('proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode', null, global);
goog.exportSymbol('proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option', null, global);
goog.exportSymbol('proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo', null, global);
goog.exportSymbol('proto.entity.NewCampaign', null, global);
goog.exportSymbol('proto.entity.UpdateCampaignAction', null, global);
goog.exportSymbol('proto.entity.UpdateCampaignAction.ActionCase', null, global);
goog.exportSymbol('proto.entity.UpdateCampaignAction.SetDeletedAction', null, global);
goog.exportSymbol('proto.entity.UpdateCampaignAction.SetDetailsAction', null, global);
goog.exportSymbol('proto.entity.UpdateCampaignAction.SetInternalDetailsAction', null, global);
goog.exportSymbol('proto.entity.UpdateCampaignAction.SetSettingsAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.NewCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.NewCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.NewCampaign.displayName = 'proto.entity.NewCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Campaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Campaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Campaign.displayName = 'proto.entity.Campaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.CampaignDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignDetails.displayName = 'proto.entity.CampaignDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.CampaignSettings.oneofGroups_);
};
goog.inherits(proto.entity.CampaignSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignSettings.displayName = 'proto.entity.CampaignSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignSettings.GoogleAds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.CampaignSettings.GoogleAds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignSettings.GoogleAds.displayName = 'proto.entity.CampaignSettings.GoogleAds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.repeatedFields_, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_);
};
goog.inherits(proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.displayName = 'proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.displayName = 'proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignSettings.AutomationDateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.CampaignSettings.AutomationDateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignSettings.AutomationDateInfo.displayName = 'proto.entity.CampaignSettings.AutomationDateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.CampaignSettings.AutomationStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.CampaignSettings.AutomationStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.CampaignSettings.AutomationStatus.displayName = 'proto.entity.CampaignSettings.AutomationStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BidAutomationMetricSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BidAutomationMetricSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BidAutomationMetricSnapshot.displayName = 'proto.entity.BidAutomationMetricSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalCampaignDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalCampaignDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalCampaignDetails.displayName = 'proto.entity.InternalCampaignDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.repeatedFields_, null);
};
goog.inherits(proto.entity.InternalCampaignDetails.BidAutomationInternalDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.displayName = 'proto.entity.InternalCampaignDetails.BidAutomationInternalDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.displayName = 'proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.displayName = 'proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateCampaignAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UpdateCampaignAction.oneofGroups_);
};
goog.inherits(proto.entity.UpdateCampaignAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateCampaignAction.displayName = 'proto.entity.UpdateCampaignAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateCampaignAction.SetDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateCampaignAction.SetDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateCampaignAction.SetDetailsAction.displayName = 'proto.entity.UpdateCampaignAction.SetDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateCampaignAction.SetSettingsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateCampaignAction.SetSettingsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateCampaignAction.SetSettingsAction.displayName = 'proto.entity.UpdateCampaignAction.SetSettingsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateCampaignAction.SetInternalDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateCampaignAction.SetInternalDetailsAction.displayName = 'proto.entity.UpdateCampaignAction.SetInternalDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateCampaignAction.SetDeletedAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateCampaignAction.SetDeletedAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateCampaignAction.SetDeletedAction.displayName = 'proto.entity.UpdateCampaignAction.SetDeletedAction';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.NewCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.NewCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.NewCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_entity_site_pb.SiteSelector.toObject(includeInstance, f),
    externalAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalCampaignId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaignPlatform: jspb.Message.getFieldWithDefault(msg, 4, 0),
    details: (f = msg.getDetails()) && proto.entity.CampaignDetails.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && proto.entity.CampaignSettings.toObject(includeInstance, f),
    internalDetails: (f = msg.getInternalDetails()) && proto.entity.InternalCampaignDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.NewCampaign}
 */
proto.entity.NewCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.NewCampaign;
  return proto.entity.NewCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.NewCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.NewCampaign}
 */
proto.entity.NewCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.SiteSelector;
      reader.readMessage(value,proto_entity_site_pb.SiteSelector.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalCampaignId(value);
      break;
    case 4:
      var value = /** @type {!proto.common.CampaignPlatform.Option} */ (reader.readEnum());
      msg.setCampaignPlatform(value);
      break;
    case 5:
      var value = new proto.entity.CampaignDetails;
      reader.readMessage(value,proto.entity.CampaignDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 6:
      var value = new proto.entity.CampaignSettings;
      reader.readMessage(value,proto.entity.CampaignSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 7:
      var value = new proto.entity.InternalCampaignDetails;
      reader.readMessage(value,proto.entity.InternalCampaignDetails.deserializeBinaryFromReader);
      msg.setInternalDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.NewCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.NewCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.NewCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.SiteSelector.serializeBinaryToWriter
    );
  }
  f = message.getExternalAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalCampaignId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCampaignPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.CampaignDetails.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.CampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getInternalDetails();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.InternalCampaignDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteSelector site = 1;
 * @return {?proto.entity.SiteSelector}
 */
proto.entity.NewCampaign.prototype.getSite = function() {
  return /** @type{?proto.entity.SiteSelector} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.SiteSelector, 1));
};


/**
 * @param {?proto.entity.SiteSelector|undefined} value
 * @return {!proto.entity.NewCampaign} returns this
*/
proto.entity.NewCampaign.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewCampaign.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string external_account_id = 2;
 * @return {string}
 */
proto.entity.NewCampaign.prototype.getExternalAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.setExternalAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_campaign_id = 3;
 * @return {string}
 */
proto.entity.NewCampaign.prototype.getExternalCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.setExternalCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.CampaignPlatform.Option campaign_platform = 4;
 * @return {!proto.common.CampaignPlatform.Option}
 */
proto.entity.NewCampaign.prototype.getCampaignPlatform = function() {
  return /** @type {!proto.common.CampaignPlatform.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.CampaignPlatform.Option} value
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.setCampaignPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CampaignDetails details = 5;
 * @return {?proto.entity.CampaignDetails}
 */
proto.entity.NewCampaign.prototype.getDetails = function() {
  return /** @type{?proto.entity.CampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignDetails, 5));
};


/**
 * @param {?proto.entity.CampaignDetails|undefined} value
 * @return {!proto.entity.NewCampaign} returns this
*/
proto.entity.NewCampaign.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewCampaign.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CampaignSettings settings = 6;
 * @return {?proto.entity.CampaignSettings}
 */
proto.entity.NewCampaign.prototype.getSettings = function() {
  return /** @type{?proto.entity.CampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings, 6));
};


/**
 * @param {?proto.entity.CampaignSettings|undefined} value
 * @return {!proto.entity.NewCampaign} returns this
*/
proto.entity.NewCampaign.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewCampaign.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional InternalCampaignDetails internal_details = 7;
 * @return {?proto.entity.InternalCampaignDetails}
 */
proto.entity.NewCampaign.prototype.getInternalDetails = function() {
  return /** @type{?proto.entity.InternalCampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalCampaignDetails, 7));
};


/**
 * @param {?proto.entity.InternalCampaignDetails|undefined} value
 * @return {!proto.entity.NewCampaign} returns this
*/
proto.entity.NewCampaign.prototype.setInternalDetails = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewCampaign} returns this
 */
proto.entity.NewCampaign.prototype.clearInternalDetails = function() {
  return this.setInternalDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewCampaign.prototype.hasInternalDetails = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Campaign.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Campaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Campaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Campaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: (f = msg.getCampaignId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    siteId: (f = msg.getSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    externalAccountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    externalCampaignId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    campaignPlatform: jspb.Message.getFieldWithDefault(msg, 5, 0),
    details: (f = msg.getDetails()) && proto.entity.CampaignDetails.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && proto.entity.CampaignSettings.toObject(includeInstance, f),
    internalDetails: (f = msg.getInternalDetails()) && proto.entity.InternalCampaignDetails.toObject(includeInstance, f),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Campaign}
 */
proto.entity.Campaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Campaign;
  return proto.entity.Campaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Campaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Campaign}
 */
proto.entity.Campaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setCampaignId(value);
      break;
    case 2:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalCampaignId(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CampaignPlatform.Option} */ (reader.readEnum());
      msg.setCampaignPlatform(value);
      break;
    case 6:
      var value = new proto.entity.CampaignDetails;
      reader.readMessage(value,proto.entity.CampaignDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 7:
      var value = new proto.entity.CampaignSettings;
      reader.readMessage(value,proto.entity.CampaignSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 8:
      var value = new proto.entity.InternalCampaignDetails;
      reader.readMessage(value,proto.entity.InternalCampaignDetails.deserializeBinaryFromReader);
      msg.setInternalDetails(value);
      break;
    case 9:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Campaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Campaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Campaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Campaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getExternalAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExternalCampaignId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCampaignPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.CampaignDetails.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.CampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getInternalDetails();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.entity.InternalCampaignDetails.serializeBinaryToWriter
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID campaign_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Campaign.prototype.getCampaignId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Campaign} returns this
*/
proto.entity.Campaign.prototype.setCampaignId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.clearCampaignId = function() {
  return this.setCampaignId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Campaign.prototype.hasCampaignId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ObjectID site_id = 2;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Campaign.prototype.getSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 2));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Campaign} returns this
*/
proto.entity.Campaign.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Campaign.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string external_account_id = 3;
 * @return {string}
 */
proto.entity.Campaign.prototype.getExternalAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.setExternalAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string external_campaign_id = 4;
 * @return {string}
 */
proto.entity.Campaign.prototype.getExternalCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.setExternalCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.CampaignPlatform.Option campaign_platform = 5;
 * @return {!proto.common.CampaignPlatform.Option}
 */
proto.entity.Campaign.prototype.getCampaignPlatform = function() {
  return /** @type {!proto.common.CampaignPlatform.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CampaignPlatform.Option} value
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.setCampaignPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CampaignDetails details = 6;
 * @return {?proto.entity.CampaignDetails}
 */
proto.entity.Campaign.prototype.getDetails = function() {
  return /** @type{?proto.entity.CampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignDetails, 6));
};


/**
 * @param {?proto.entity.CampaignDetails|undefined} value
 * @return {!proto.entity.Campaign} returns this
*/
proto.entity.Campaign.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Campaign.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CampaignSettings settings = 7;
 * @return {?proto.entity.CampaignSettings}
 */
proto.entity.Campaign.prototype.getSettings = function() {
  return /** @type{?proto.entity.CampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings, 7));
};


/**
 * @param {?proto.entity.CampaignSettings|undefined} value
 * @return {!proto.entity.Campaign} returns this
*/
proto.entity.Campaign.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Campaign.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional InternalCampaignDetails internal_details = 8;
 * @return {?proto.entity.InternalCampaignDetails}
 */
proto.entity.Campaign.prototype.getInternalDetails = function() {
  return /** @type{?proto.entity.InternalCampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalCampaignDetails, 8));
};


/**
 * @param {?proto.entity.InternalCampaignDetails|undefined} value
 * @return {!proto.entity.Campaign} returns this
*/
proto.entity.Campaign.prototype.setInternalDetails = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.clearInternalDetails = function() {
  return this.setInternalDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Campaign.prototype.hasInternalDetails = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.CRUDFields crud_fields = 9;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.Campaign.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 9));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.Campaign} returns this
*/
proto.entity.Campaign.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Campaign} returns this
 */
proto.entity.Campaign.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Campaign.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    creationActionId: (f = msg.getCreationActionId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    ampdProductDetails: (f = msg.getAmpdProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f),
    ampdResourceConfiguration: (f = msg.getAmpdResourceConfiguration()) && proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignDetails}
 */
proto.entity.CampaignDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignDetails;
  return proto.entity.CampaignDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignDetails}
 */
proto.entity.CampaignDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setCreationActionId(value);
      break;
    case 1:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setAmpdProductDetails(value);
      break;
    case 2:
      var value = new proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration;
      reader.readMessage(value,proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration.deserializeBinaryFromReader);
      msg.setAmpdResourceConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreationActionId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getAmpdProductDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
  f = message.getAmpdResourceConfiguration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID creation_action_id = 3;
 * @return {?proto.common.ObjectID}
 */
proto.entity.CampaignDetails.prototype.getCreationActionId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 3));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.CampaignDetails} returns this
*/
proto.entity.CampaignDetails.prototype.setCreationActionId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignDetails} returns this
 */
proto.entity.CampaignDetails.prototype.clearCreationActionId = function() {
  return this.setCreationActionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignDetails.prototype.hasCreationActionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ProductDetails ampd_product_details = 1;
 * @return {?proto.common.ProductDetails}
 */
proto.entity.CampaignDetails.prototype.getAmpdProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 1));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.entity.CampaignDetails} returns this
*/
proto.entity.CampaignDetails.prototype.setAmpdProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignDetails} returns this
 */
proto.entity.CampaignDetails.prototype.clearAmpdProductDetails = function() {
  return this.setAmpdProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignDetails.prototype.hasAmpdProductDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ampdPb.ResourceConfiguration ampd_resource_configuration = 2;
 * @return {?proto.ampdPb.ResourceConfiguration}
 */
proto.entity.CampaignDetails.prototype.getAmpdResourceConfiguration = function() {
  return /** @type{?proto.ampdPb.ResourceConfiguration} */ (
    jspb.Message.getWrapperField(this, proto_ampdPb_resourceConfiguration_pb.ResourceConfiguration, 2));
};


/**
 * @param {?proto.ampdPb.ResourceConfiguration|undefined} value
 * @return {!proto.entity.CampaignDetails} returns this
*/
proto.entity.CampaignDetails.prototype.setAmpdResourceConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignDetails} returns this
 */
proto.entity.CampaignDetails.prototype.clearAmpdResourceConfiguration = function() {
  return this.setAmpdResourceConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignDetails.prototype.hasAmpdResourceConfiguration = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.CampaignSettings.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.entity.CampaignSettings.TypeCase = {
  TYPE_NOT_SET: 0,
  GOOGLE_ADS: 1
};

/**
 * @return {proto.entity.CampaignSettings.TypeCase}
 */
proto.entity.CampaignSettings.prototype.getTypeCase = function() {
  return /** @type {proto.entity.CampaignSettings.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.CampaignSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    googleAds: (f = msg.getGoogleAds()) && proto.entity.CampaignSettings.GoogleAds.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignSettings}
 */
proto.entity.CampaignSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignSettings;
  return proto.entity.CampaignSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignSettings}
 */
proto.entity.CampaignSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.CampaignSettings.GoogleAds;
      reader.readMessage(value,proto.entity.CampaignSettings.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.CampaignSettings.GoogleAds.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignSettings.GoogleAds.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignSettings.GoogleAds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignSettings.GoogleAds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.GoogleAds.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidAutomationSettings: (f = msg.getBidAutomationSettings()) && proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.toObject(includeInstance, f),
    pausingAutomationSettings: (f = msg.getPausingAutomationSettings()) && proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignSettings.GoogleAds}
 */
proto.entity.CampaignSettings.GoogleAds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignSettings.GoogleAds;
  return proto.entity.CampaignSettings.GoogleAds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignSettings.GoogleAds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignSettings.GoogleAds}
 */
proto.entity.CampaignSettings.GoogleAds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings;
      reader.readMessage(value,proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.deserializeBinaryFromReader);
      msg.setBidAutomationSettings(value);
      break;
    case 2:
      var value = new proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings;
      reader.readMessage(value,proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.deserializeBinaryFromReader);
      msg.setPausingAutomationSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignSettings.GoogleAds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignSettings.GoogleAds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignSettings.GoogleAds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.GoogleAds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidAutomationSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.serializeBinaryToWriter
    );
  }
  f = message.getPausingAutomationSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.TargetCase = {
  TARGET_NOT_SET: 0,
  TARGET_VOLTAGE: 3,
  TARGET_ACOS: 4
};

/**
 * @return {proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.TargetCase}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.getTargetCase = function() {
  return /** @type {proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.TargetCase} */(jspb.Message.computeOneofCase(this, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dateInfo: (f = msg.getDateInfo()) && proto.entity.CampaignSettings.AutomationDateInfo.toObject(includeInstance, f),
    targetVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    targetAcos: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    metricSnapshotsList: jspb.Message.toObjectList(msg.getMetricSnapshotsList(),
    proto.entity.BidAutomationMetricSnapshot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings;
  return proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.entity.CampaignSettings.AutomationDateInfo;
      reader.readMessage(value,proto.entity.CampaignSettings.AutomationDateInfo.deserializeBinaryFromReader);
      msg.setDateInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetVoltage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetAcos(value);
      break;
    case 5:
      var value = new proto.entity.BidAutomationMetricSnapshot;
      reader.readMessage(value,proto.entity.BidAutomationMetricSnapshot.deserializeBinaryFromReader);
      msg.addMetricSnapshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDateInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.CampaignSettings.AutomationDateInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMetricSnapshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.entity.BidAutomationMetricSnapshot.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutomationStatus.Option status = 1;
 * @return {!proto.entity.CampaignSettings.AutomationStatus.Option}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.getStatus = function() {
  return /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.CampaignSettings.AutomationStatus.Option} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional AutomationDateInfo date_info = 2;
 * @return {?proto.entity.CampaignSettings.AutomationDateInfo}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.getDateInfo = function() {
  return /** @type{?proto.entity.CampaignSettings.AutomationDateInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings.AutomationDateInfo, 2));
};


/**
 * @param {?proto.entity.CampaignSettings.AutomationDateInfo|undefined} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
*/
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.setDateInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.clearDateInfo = function() {
  return this.setDateInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.hasDateInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double target_voltage = 3;
 * @return {number}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.getTargetVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.setTargetVoltage = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.clearTargetVoltage = function() {
  return jspb.Message.setOneofField(this, 3, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.hasTargetVoltage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double target_acos = 4;
 * @return {number}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.getTargetAcos = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.setTargetAcos = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.clearTargetAcos = function() {
  return jspb.Message.setOneofField(this, 4, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.hasTargetAcos = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated BidAutomationMetricSnapshot metric_snapshots = 5;
 * @return {!Array<!proto.entity.BidAutomationMetricSnapshot>}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.getMetricSnapshotsList = function() {
  return /** @type{!Array<!proto.entity.BidAutomationMetricSnapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.BidAutomationMetricSnapshot, 5));
};


/**
 * @param {!Array<!proto.entity.BidAutomationMetricSnapshot>} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
*/
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.setMetricSnapshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.entity.BidAutomationMetricSnapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.BidAutomationMetricSnapshot}
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.addMetricSnapshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.entity.BidAutomationMetricSnapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings.prototype.clearMetricSnapshotsList = function() {
  return this.setMetricSnapshotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dateInfo: (f = msg.getDateInfo()) && proto.entity.CampaignSettings.AutomationDateInfo.toObject(includeInstance, f),
    pauseScoreThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cost28DayThresholdMicros: jspb.Message.getFieldWithDefault(msg, 4, 0),
    aacos28DayThresholdPoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minimumNumberOfActiveKeywords: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reevaluatePausedKeywords: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings;
  return proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.entity.CampaignSettings.AutomationDateInfo;
      reader.readMessage(value,proto.entity.CampaignSettings.AutomationDateInfo.deserializeBinaryFromReader);
      msg.setDateInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPauseScoreThreshold(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost28DayThresholdMicros(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAacos28DayThresholdPoints(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumNumberOfActiveKeywords(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReevaluatePausedKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDateInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.CampaignSettings.AutomationDateInfo.serializeBinaryToWriter
    );
  }
  f = message.getPauseScoreThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCost28DayThresholdMicros();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAacos28DayThresholdPoints();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMinimumNumberOfActiveKeywords();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getReevaluatePausedKeywords();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional AutomationStatus.Option status = 1;
 * @return {!proto.entity.CampaignSettings.AutomationStatus.Option}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getStatus = function() {
  return /** @type {!proto.entity.CampaignSettings.AutomationStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.CampaignSettings.AutomationStatus.Option} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional AutomationDateInfo date_info = 2;
 * @return {?proto.entity.CampaignSettings.AutomationDateInfo}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getDateInfo = function() {
  return /** @type{?proto.entity.CampaignSettings.AutomationDateInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings.AutomationDateInfo, 2));
};


/**
 * @param {?proto.entity.CampaignSettings.AutomationDateInfo|undefined} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
*/
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setDateInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.clearDateInfo = function() {
  return this.setDateInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.hasDateInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double pause_score_threshold = 3;
 * @return {number}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getPauseScoreThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setPauseScoreThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 cost_28_day_threshold_micros = 4;
 * @return {number}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getCost28DayThresholdMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setCost28DayThresholdMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 aacos_28_day_threshold_points = 5;
 * @return {number}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getAacos28DayThresholdPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setAacos28DayThresholdPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 minimum_number_of_active_keywords = 6;
 * @return {number}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getMinimumNumberOfActiveKeywords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setMinimumNumberOfActiveKeywords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool reevaluate_paused_keywords = 7;
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.getReevaluatePausedKeywords = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} returns this
 */
proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings.prototype.setReevaluatePausedKeywords = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional BidAutomationSettings bid_automation_settings = 1;
 * @return {?proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings}
 */
proto.entity.CampaignSettings.GoogleAds.prototype.getBidAutomationSettings = function() {
  return /** @type{?proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings, 1));
};


/**
 * @param {?proto.entity.CampaignSettings.GoogleAds.BidAutomationSettings|undefined} value
 * @return {!proto.entity.CampaignSettings.GoogleAds} returns this
*/
proto.entity.CampaignSettings.GoogleAds.prototype.setBidAutomationSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignSettings.GoogleAds} returns this
 */
proto.entity.CampaignSettings.GoogleAds.prototype.clearBidAutomationSettings = function() {
  return this.setBidAutomationSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.prototype.hasBidAutomationSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PausingAutomationSettings pausing_automation_settings = 2;
 * @return {?proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings}
 */
proto.entity.CampaignSettings.GoogleAds.prototype.getPausingAutomationSettings = function() {
  return /** @type{?proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings, 2));
};


/**
 * @param {?proto.entity.CampaignSettings.GoogleAds.PausingAutomationSettings|undefined} value
 * @return {!proto.entity.CampaignSettings.GoogleAds} returns this
*/
proto.entity.CampaignSettings.GoogleAds.prototype.setPausingAutomationSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignSettings.GoogleAds} returns this
 */
proto.entity.CampaignSettings.GoogleAds.prototype.clearPausingAutomationSettings = function() {
  return this.setPausingAutomationSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.GoogleAds.prototype.hasPausingAutomationSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignSettings.AutomationDateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignSettings.AutomationDateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.AutomationDateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialRunTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nextScheduledRunTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runFrequencyDays: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignSettings.AutomationDateInfo}
 */
proto.entity.CampaignSettings.AutomationDateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignSettings.AutomationDateInfo;
  return proto.entity.CampaignSettings.AutomationDateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignSettings.AutomationDateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignSettings.AutomationDateInfo}
 */
proto.entity.CampaignSettings.AutomationDateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialRunTime(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextScheduledRunTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRunFrequencyDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignSettings.AutomationDateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignSettings.AutomationDateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.AutomationDateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialRunTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNextScheduledRunTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunFrequencyDays();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string initial_run_time = 3;
 * @return {string}
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.getInitialRunTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.CampaignSettings.AutomationDateInfo} returns this
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.setInitialRunTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string next_scheduled_run_time = 1;
 * @return {string}
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.getNextScheduledRunTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.CampaignSettings.AutomationDateInfo} returns this
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.setNextScheduledRunTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double run_frequency_days = 2;
 * @return {number}
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.getRunFrequencyDays = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.CampaignSettings.AutomationDateInfo} returns this
 */
proto.entity.CampaignSettings.AutomationDateInfo.prototype.setRunFrequencyDays = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.CampaignSettings.AutomationStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.CampaignSettings.AutomationStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.CampaignSettings.AutomationStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.AutomationStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.CampaignSettings.AutomationStatus}
 */
proto.entity.CampaignSettings.AutomationStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.CampaignSettings.AutomationStatus;
  return proto.entity.CampaignSettings.AutomationStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.CampaignSettings.AutomationStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.CampaignSettings.AutomationStatus}
 */
proto.entity.CampaignSettings.AutomationStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.CampaignSettings.AutomationStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.CampaignSettings.AutomationStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.CampaignSettings.AutomationStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.CampaignSettings.AutomationStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.CampaignSettings.AutomationStatus.Option = {
  UNSPECIFIED: 0,
  DISABLED: 1,
  ENABLED: 2,
  DRY_RUN: 3
};

/**
 * optional GoogleAds google_ads = 1;
 * @return {?proto.entity.CampaignSettings.GoogleAds}
 */
proto.entity.CampaignSettings.prototype.getGoogleAds = function() {
  return /** @type{?proto.entity.CampaignSettings.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings.GoogleAds, 1));
};


/**
 * @param {?proto.entity.CampaignSettings.GoogleAds|undefined} value
 * @return {!proto.entity.CampaignSettings} returns this
*/
proto.entity.CampaignSettings.prototype.setGoogleAds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.CampaignSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.CampaignSettings} returns this
 */
proto.entity.CampaignSettings.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.CampaignSettings.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BidAutomationMetricSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BidAutomationMetricSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BidAutomationMetricSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    acos: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    recommendedBudget: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    currentBudget: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BidAutomationMetricSnapshot}
 */
proto.entity.BidAutomationMetricSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BidAutomationMetricSnapshot;
  return proto.entity.BidAutomationMetricSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BidAutomationMetricSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BidAutomationMetricSnapshot}
 */
proto.entity.BidAutomationMetricSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAcos(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClicks(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRecommendedBudget(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BidAutomationMetricSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BidAutomationMetricSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BidAutomationMetricSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAcos();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClicks();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCost();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRecommendedBudget();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCurrentBudget();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string timestamp = 1;
 * @return {string}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double acos = 2;
 * @return {number}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getAcos = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setAcos = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double clicks = 3;
 * @return {number}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double cost = 4;
 * @return {number}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double revenue = 5;
 * @return {number}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double recommended_budget = 6;
 * @return {number}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getRecommendedBudget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setRecommendedBudget = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double current_budget = 7;
 * @return {number}
 */
proto.entity.BidAutomationMetricSnapshot.prototype.getCurrentBudget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BidAutomationMetricSnapshot} returns this
 */
proto.entity.BidAutomationMetricSnapshot.prototype.setCurrentBudget = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalCampaignDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalCampaignDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalCampaignDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidAutomationInternalDetails: (f = msg.getBidAutomationInternalDetails()) && proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalCampaignDetails}
 */
proto.entity.InternalCampaignDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalCampaignDetails;
  return proto.entity.InternalCampaignDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalCampaignDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalCampaignDetails}
 */
proto.entity.InternalCampaignDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.InternalCampaignDetails.BidAutomationInternalDetails;
      reader.readMessage(value,proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.deserializeBinaryFromReader);
      msg.setBidAutomationInternalDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalCampaignDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalCampaignDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalCampaignDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidAutomationInternalDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.repeatedFields_ = [1,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastRunTime: jspb.Message.getFieldWithDefault(msg, 30, ""),
    errorMessagesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    lastFailedRunTime: jspb.Message.getFieldWithDefault(msg, 33, ""),
    qualified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    statusCode: jspb.Message.getFieldWithDefault(msg, 34, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 35, ""),
    trainingInfo: (f = msg.getTrainingInfo()) && proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.toObject(includeInstance, f),
    targetVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    voltageMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    voltageMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    profitMarginVoltageRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    aacosAdjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    acosLowList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    acosMidList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    acosHighList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    acosAList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    acosBList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 11)) == null ? undefined : f,
    acosScaleList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 12)) == null ? undefined : f,
    weeklyClicksLowList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 13)) == null ? undefined : f,
    weeklyClicksMidList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 14)) == null ? undefined : f,
    weeklyClicksHighList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 15)) == null ? undefined : f,
    weeklyClicksAList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 16)) == null ? undefined : f,
    weeklyClicksBList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 17)) == null ? undefined : f,
    weeklyCostLowList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 18)) == null ? undefined : f,
    weeklyCostMidList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 19)) == null ? undefined : f,
    weeklyCostHighList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 20)) == null ? undefined : f,
    weeklyCostAList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 21)) == null ? undefined : f,
    weeklyCostBList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 22)) == null ? undefined : f,
    weeklyRevenueLowList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 23)) == null ? undefined : f,
    weeklyRevenueMidList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 24)) == null ? undefined : f,
    weeklyRevenueHighList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 25)) == null ? undefined : f,
    weeklyRevenueAList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 26)) == null ? undefined : f,
    weeklyRevenueBList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 27)) == null ? undefined : f,
    dailyRecommendedBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 28)) == null ? undefined : f,
    weeklySearchVolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalCampaignDetails.BidAutomationInternalDetails;
  return proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastRunTime(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrorMessages(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFailedRunTime(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQualified(value);
      break;
    case 34:
      var value = /** @type {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option} */ (reader.readEnum());
      msg.setStatusCode(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 31:
      var value = new proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo;
      reader.readMessage(value,proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.deserializeBinaryFromReader);
      msg.setTrainingInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetVoltage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVoltageMin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVoltageMax(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitMarginVoltageRatio(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAacosAdjustment(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcosLow(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcosMid(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcosHigh(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcosA(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcosB(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAcosScale(values[i]);
      }
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyClicksLow(values[i]);
      }
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyClicksMid(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyClicksHigh(values[i]);
      }
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyClicksA(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyClicksB(values[i]);
      }
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyCostLow(values[i]);
      }
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyCostMid(values[i]);
      }
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyCostHigh(values[i]);
      }
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyCostA(values[i]);
      }
      break;
    case 22:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyCostB(values[i]);
      }
      break;
    case 23:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyRevenueLow(values[i]);
      }
      break;
    case 24:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyRevenueMid(values[i]);
      }
      break;
    case 25:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyRevenueHigh(values[i]);
      }
      break;
    case 26:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyRevenueA(values[i]);
      }
      break;
    case 27:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeeklyRevenueB(values[i]);
      }
      break;
    case 28:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDailyRecommendedBudget(values[i]);
      }
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeeklySearchVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastRunTime();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getErrorMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getLastFailedRunTime();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getQualified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getStatusCode();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getTrainingInfo();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.serializeBinaryToWriter
    );
  }
  f = message.getTargetVoltage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getVoltageMin();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getVoltageMax();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getProfitMarginVoltageRatio();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAacosAdjustment();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getAcosLowList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getAcosMidList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getAcosHighList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
  f = message.getAcosAList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
  f = message.getAcosBList();
  if (f.length > 0) {
    writer.writePackedDouble(
      11,
      f
    );
  }
  f = message.getAcosScaleList();
  if (f.length > 0) {
    writer.writePackedDouble(
      12,
      f
    );
  }
  f = message.getWeeklyClicksLowList();
  if (f.length > 0) {
    writer.writePackedDouble(
      13,
      f
    );
  }
  f = message.getWeeklyClicksMidList();
  if (f.length > 0) {
    writer.writePackedDouble(
      14,
      f
    );
  }
  f = message.getWeeklyClicksHighList();
  if (f.length > 0) {
    writer.writePackedDouble(
      15,
      f
    );
  }
  f = message.getWeeklyClicksAList();
  if (f.length > 0) {
    writer.writePackedDouble(
      16,
      f
    );
  }
  f = message.getWeeklyClicksBList();
  if (f.length > 0) {
    writer.writePackedDouble(
      17,
      f
    );
  }
  f = message.getWeeklyCostLowList();
  if (f.length > 0) {
    writer.writePackedDouble(
      18,
      f
    );
  }
  f = message.getWeeklyCostMidList();
  if (f.length > 0) {
    writer.writePackedDouble(
      19,
      f
    );
  }
  f = message.getWeeklyCostHighList();
  if (f.length > 0) {
    writer.writePackedDouble(
      20,
      f
    );
  }
  f = message.getWeeklyCostAList();
  if (f.length > 0) {
    writer.writePackedDouble(
      21,
      f
    );
  }
  f = message.getWeeklyCostBList();
  if (f.length > 0) {
    writer.writePackedDouble(
      22,
      f
    );
  }
  f = message.getWeeklyRevenueLowList();
  if (f.length > 0) {
    writer.writePackedDouble(
      23,
      f
    );
  }
  f = message.getWeeklyRevenueMidList();
  if (f.length > 0) {
    writer.writePackedDouble(
      24,
      f
    );
  }
  f = message.getWeeklyRevenueHighList();
  if (f.length > 0) {
    writer.writePackedDouble(
      25,
      f
    );
  }
  f = message.getWeeklyRevenueAList();
  if (f.length > 0) {
    writer.writePackedDouble(
      26,
      f
    );
  }
  f = message.getWeeklyRevenueBList();
  if (f.length > 0) {
    writer.writePackedDouble(
      27,
      f
    );
  }
  f = message.getDailyRecommendedBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      28,
      f
    );
  }
  f = message.getWeeklySearchVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    estimatedCompletionDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recommendedMinimumBudget: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo;
  return proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedCompletionDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRecommendedMinimumBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getEstimatedCompletionDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecommendedMinimumBudget();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional double progress = 1;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string estimated_completion_date = 2;
 * @return {string}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.getEstimatedCompletionDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.setEstimatedCompletionDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float recommended_minimum_budget = 3;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.getRecommendedMinimumBudget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo.prototype.setRecommendedMinimumBudget = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode;
  return proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option = {
  UNSPECIFIED: 0,
  NORMAL: 1,
  NOT_QUALIFIED: 2,
  TRAINING: 3,
  TRAINING_BUDGET_LIMITED: 4,
  AWAITING_CONFIG: 5,
  TRAFFIC_DROP: 6,
  COST_INCREASE: 7,
  BUDGET_LIMITED: 8
};

/**
 * optional string last_run_time = 30;
 * @return {string}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getLastRunTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setLastRunTime = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * repeated string error_messages = 1;
 * @return {!Array<string>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getErrorMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setErrorMessagesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addErrorMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearErrorMessagesList = function() {
  return this.setErrorMessagesList([]);
};


/**
 * optional string last_failed_run_time = 33;
 * @return {string}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getLastFailedRunTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setLastFailedRunTime = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional bool qualified = 2;
 * @return {boolean}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional BidAutomationStatusCode.Option status_code = 34;
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getStatusCode = function() {
  return /** @type {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional string status_message = 35;
 * @return {string}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional BidAutomationTrainingInfo training_info = 31;
 * @return {?proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getTrainingInfo = function() {
  return /** @type{?proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo, 31));
};


/**
 * @param {?proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationTrainingInfo|undefined} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
*/
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setTrainingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearTrainingInfo = function() {
  return this.setTrainingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.hasTrainingInfo = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional double target_voltage = 3;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getTargetVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setTargetVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double voltage_min = 4;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getVoltageMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setVoltageMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double voltage_max = 5;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getVoltageMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setVoltageMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double profit_margin_voltage_ratio = 6;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getProfitMarginVoltageRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setProfitMarginVoltageRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double aacos_adjustment = 32;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAacosAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAacosAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * repeated double acos_low = 7;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAcosLowList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAcosLowList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addAcosLow = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearAcosLowList = function() {
  return this.setAcosLowList([]);
};


/**
 * repeated double acos_mid = 8;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAcosMidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAcosMidList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addAcosMid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearAcosMidList = function() {
  return this.setAcosMidList([]);
};


/**
 * repeated double acos_high = 9;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAcosHighList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAcosHighList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addAcosHigh = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearAcosHighList = function() {
  return this.setAcosHighList([]);
};


/**
 * repeated double acos_a = 10;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAcosAList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAcosAList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addAcosA = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearAcosAList = function() {
  return this.setAcosAList([]);
};


/**
 * repeated double acos_b = 11;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAcosBList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAcosBList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addAcosB = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearAcosBList = function() {
  return this.setAcosBList([]);
};


/**
 * repeated double acos_scale = 12;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getAcosScaleList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setAcosScaleList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addAcosScale = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearAcosScaleList = function() {
  return this.setAcosScaleList([]);
};


/**
 * repeated double weekly_clicks_low = 13;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyClicksLowList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyClicksLowList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyClicksLow = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyClicksLowList = function() {
  return this.setWeeklyClicksLowList([]);
};


/**
 * repeated double weekly_clicks_mid = 14;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyClicksMidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyClicksMidList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyClicksMid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyClicksMidList = function() {
  return this.setWeeklyClicksMidList([]);
};


/**
 * repeated double weekly_clicks_high = 15;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyClicksHighList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyClicksHighList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyClicksHigh = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyClicksHighList = function() {
  return this.setWeeklyClicksHighList([]);
};


/**
 * repeated double weekly_clicks_a = 16;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyClicksAList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyClicksAList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyClicksA = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyClicksAList = function() {
  return this.setWeeklyClicksAList([]);
};


/**
 * repeated double weekly_clicks_b = 17;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyClicksBList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyClicksBList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyClicksB = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyClicksBList = function() {
  return this.setWeeklyClicksBList([]);
};


/**
 * repeated double weekly_cost_low = 18;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyCostLowList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyCostLowList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyCostLow = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyCostLowList = function() {
  return this.setWeeklyCostLowList([]);
};


/**
 * repeated double weekly_cost_mid = 19;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyCostMidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyCostMidList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyCostMid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyCostMidList = function() {
  return this.setWeeklyCostMidList([]);
};


/**
 * repeated double weekly_cost_high = 20;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyCostHighList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyCostHighList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyCostHigh = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyCostHighList = function() {
  return this.setWeeklyCostHighList([]);
};


/**
 * repeated double weekly_cost_a = 21;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyCostAList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyCostAList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyCostA = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyCostAList = function() {
  return this.setWeeklyCostAList([]);
};


/**
 * repeated double weekly_cost_b = 22;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyCostBList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 22));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyCostBList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyCostB = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyCostBList = function() {
  return this.setWeeklyCostBList([]);
};


/**
 * repeated double weekly_revenue_low = 23;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyRevenueLowList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 23));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyRevenueLowList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyRevenueLow = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyRevenueLowList = function() {
  return this.setWeeklyRevenueLowList([]);
};


/**
 * repeated double weekly_revenue_mid = 24;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyRevenueMidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 24));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyRevenueMidList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyRevenueMid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyRevenueMidList = function() {
  return this.setWeeklyRevenueMidList([]);
};


/**
 * repeated double weekly_revenue_high = 25;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyRevenueHighList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 25));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyRevenueHighList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyRevenueHigh = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyRevenueHighList = function() {
  return this.setWeeklyRevenueHighList([]);
};


/**
 * repeated double weekly_revenue_a = 26;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyRevenueAList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 26));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyRevenueAList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyRevenueA = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyRevenueAList = function() {
  return this.setWeeklyRevenueAList([]);
};


/**
 * repeated double weekly_revenue_b = 27;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklyRevenueBList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 27));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklyRevenueBList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addWeeklyRevenueB = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearWeeklyRevenueBList = function() {
  return this.setWeeklyRevenueBList([]);
};


/**
 * repeated double daily_recommended_budget = 28;
 * @return {!Array<number>}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getDailyRecommendedBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 28));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setDailyRecommendedBudgetList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.addDailyRecommendedBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.clearDailyRecommendedBudgetList = function() {
  return this.setDailyRecommendedBudgetList([]);
};


/**
 * optional double weekly_search_volume = 29;
 * @return {number}
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.getWeeklySearchVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} returns this
 */
proto.entity.InternalCampaignDetails.BidAutomationInternalDetails.prototype.setWeeklySearchVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional BidAutomationInternalDetails bid_automation_internal_details = 1;
 * @return {?proto.entity.InternalCampaignDetails.BidAutomationInternalDetails}
 */
proto.entity.InternalCampaignDetails.prototype.getBidAutomationInternalDetails = function() {
  return /** @type{?proto.entity.InternalCampaignDetails.BidAutomationInternalDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalCampaignDetails.BidAutomationInternalDetails, 1));
};


/**
 * @param {?proto.entity.InternalCampaignDetails.BidAutomationInternalDetails|undefined} value
 * @return {!proto.entity.InternalCampaignDetails} returns this
*/
proto.entity.InternalCampaignDetails.prototype.setBidAutomationInternalDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalCampaignDetails} returns this
 */
proto.entity.InternalCampaignDetails.prototype.clearBidAutomationInternalDetails = function() {
  return this.setBidAutomationInternalDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalCampaignDetails.prototype.hasBidAutomationInternalDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UpdateCampaignAction.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.entity.UpdateCampaignAction.ActionCase = {
  ACTION_NOT_SET: 0,
  SET_DETAILS: 1,
  SET_SETTINGS: 2,
  SET_INTERNAL_DETAILS: 3,
  SET_DELETED: 4
};

/**
 * @return {proto.entity.UpdateCampaignAction.ActionCase}
 */
proto.entity.UpdateCampaignAction.prototype.getActionCase = function() {
  return /** @type {proto.entity.UpdateCampaignAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.entity.UpdateCampaignAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateCampaignAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateCampaignAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateCampaignAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    setDetails: (f = msg.getSetDetails()) && proto.entity.UpdateCampaignAction.SetDetailsAction.toObject(includeInstance, f),
    setSettings: (f = msg.getSetSettings()) && proto.entity.UpdateCampaignAction.SetSettingsAction.toObject(includeInstance, f),
    setInternalDetails: (f = msg.getSetInternalDetails()) && proto.entity.UpdateCampaignAction.SetInternalDetailsAction.toObject(includeInstance, f),
    setDeleted: (f = msg.getSetDeleted()) && proto.entity.UpdateCampaignAction.SetDeletedAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateCampaignAction}
 */
proto.entity.UpdateCampaignAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateCampaignAction;
  return proto.entity.UpdateCampaignAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateCampaignAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateCampaignAction}
 */
proto.entity.UpdateCampaignAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.UpdateCampaignAction.SetDetailsAction;
      reader.readMessage(value,proto.entity.UpdateCampaignAction.SetDetailsAction.deserializeBinaryFromReader);
      msg.setSetDetails(value);
      break;
    case 2:
      var value = new proto.entity.UpdateCampaignAction.SetSettingsAction;
      reader.readMessage(value,proto.entity.UpdateCampaignAction.SetSettingsAction.deserializeBinaryFromReader);
      msg.setSetSettings(value);
      break;
    case 3:
      var value = new proto.entity.UpdateCampaignAction.SetInternalDetailsAction;
      reader.readMessage(value,proto.entity.UpdateCampaignAction.SetInternalDetailsAction.deserializeBinaryFromReader);
      msg.setSetInternalDetails(value);
      break;
    case 4:
      var value = new proto.entity.UpdateCampaignAction.SetDeletedAction;
      reader.readMessage(value,proto.entity.UpdateCampaignAction.SetDeletedAction.deserializeBinaryFromReader);
      msg.setSetDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateCampaignAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateCampaignAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateCampaignAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.UpdateCampaignAction.SetDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UpdateCampaignAction.SetSettingsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetInternalDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.UpdateCampaignAction.SetInternalDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetDeleted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.UpdateCampaignAction.SetDeletedAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateCampaignAction.SetDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateCampaignAction.SetDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newDetails: (f = msg.getNewDetails()) && proto.entity.CampaignDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateCampaignAction.SetDetailsAction}
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateCampaignAction.SetDetailsAction;
  return proto.entity.UpdateCampaignAction.SetDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateCampaignAction.SetDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateCampaignAction.SetDetailsAction}
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.CampaignDetails;
      reader.readMessage(value,proto.entity.CampaignDetails.deserializeBinaryFromReader);
      msg.setNewDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateCampaignAction.SetDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateCampaignAction.SetDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.CampaignDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional CampaignDetails new_details = 1;
 * @return {?proto.entity.CampaignDetails}
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.prototype.getNewDetails = function() {
  return /** @type{?proto.entity.CampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignDetails, 1));
};


/**
 * @param {?proto.entity.CampaignDetails|undefined} value
 * @return {!proto.entity.UpdateCampaignAction.SetDetailsAction} returns this
*/
proto.entity.UpdateCampaignAction.SetDetailsAction.prototype.setNewDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction.SetDetailsAction} returns this
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.prototype.clearNewDetails = function() {
  return this.setNewDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.SetDetailsAction.prototype.hasNewDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateCampaignAction.SetSettingsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateCampaignAction.SetSettingsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newSettings: (f = msg.getNewSettings()) && proto.entity.CampaignSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateCampaignAction.SetSettingsAction}
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateCampaignAction.SetSettingsAction;
  return proto.entity.UpdateCampaignAction.SetSettingsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateCampaignAction.SetSettingsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateCampaignAction.SetSettingsAction}
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.CampaignSettings;
      reader.readMessage(value,proto.entity.CampaignSettings.deserializeBinaryFromReader);
      msg.setNewSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateCampaignAction.SetSettingsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateCampaignAction.SetSettingsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.CampaignSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional CampaignSettings new_settings = 1;
 * @return {?proto.entity.CampaignSettings}
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.prototype.getNewSettings = function() {
  return /** @type{?proto.entity.CampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.CampaignSettings, 1));
};


/**
 * @param {?proto.entity.CampaignSettings|undefined} value
 * @return {!proto.entity.UpdateCampaignAction.SetSettingsAction} returns this
*/
proto.entity.UpdateCampaignAction.SetSettingsAction.prototype.setNewSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction.SetSettingsAction} returns this
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.prototype.clearNewSettings = function() {
  return this.setNewSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.SetSettingsAction.prototype.hasNewSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateCampaignAction.SetInternalDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newInternalDetails: (f = msg.getNewInternalDetails()) && proto.entity.InternalCampaignDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction}
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateCampaignAction.SetInternalDetailsAction;
  return proto.entity.UpdateCampaignAction.SetInternalDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction}
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.InternalCampaignDetails;
      reader.readMessage(value,proto.entity.InternalCampaignDetails.deserializeBinaryFromReader);
      msg.setNewInternalDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateCampaignAction.SetInternalDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewInternalDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.InternalCampaignDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional InternalCampaignDetails new_internal_details = 1;
 * @return {?proto.entity.InternalCampaignDetails}
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.prototype.getNewInternalDetails = function() {
  return /** @type{?proto.entity.InternalCampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalCampaignDetails, 1));
};


/**
 * @param {?proto.entity.InternalCampaignDetails|undefined} value
 * @return {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction} returns this
*/
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.prototype.setNewInternalDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction.SetInternalDetailsAction} returns this
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.prototype.clearNewInternalDetails = function() {
  return this.setNewInternalDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.SetInternalDetailsAction.prototype.hasNewInternalDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateCampaignAction.SetDeletedAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateCampaignAction.SetDeletedAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateCampaignAction.SetDeletedAction}
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateCampaignAction.SetDeletedAction;
  return proto.entity.UpdateCampaignAction.SetDeletedAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateCampaignAction.SetDeletedAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateCampaignAction.SetDeletedAction}
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateCampaignAction.SetDeletedAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateCampaignAction.SetDeletedAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewDeleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool new_deleted = 1;
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.prototype.getNewDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.UpdateCampaignAction.SetDeletedAction} returns this
 */
proto.entity.UpdateCampaignAction.SetDeletedAction.prototype.setNewDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SetDetailsAction set_details = 1;
 * @return {?proto.entity.UpdateCampaignAction.SetDetailsAction}
 */
proto.entity.UpdateCampaignAction.prototype.getSetDetails = function() {
  return /** @type{?proto.entity.UpdateCampaignAction.SetDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateCampaignAction.SetDetailsAction, 1));
};


/**
 * @param {?proto.entity.UpdateCampaignAction.SetDetailsAction|undefined} value
 * @return {!proto.entity.UpdateCampaignAction} returns this
*/
proto.entity.UpdateCampaignAction.prototype.setSetDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction} returns this
 */
proto.entity.UpdateCampaignAction.prototype.clearSetDetails = function() {
  return this.setSetDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.prototype.hasSetDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetSettingsAction set_settings = 2;
 * @return {?proto.entity.UpdateCampaignAction.SetSettingsAction}
 */
proto.entity.UpdateCampaignAction.prototype.getSetSettings = function() {
  return /** @type{?proto.entity.UpdateCampaignAction.SetSettingsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateCampaignAction.SetSettingsAction, 2));
};


/**
 * @param {?proto.entity.UpdateCampaignAction.SetSettingsAction|undefined} value
 * @return {!proto.entity.UpdateCampaignAction} returns this
*/
proto.entity.UpdateCampaignAction.prototype.setSetSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction} returns this
 */
proto.entity.UpdateCampaignAction.prototype.clearSetSettings = function() {
  return this.setSetSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.prototype.hasSetSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SetInternalDetailsAction set_internal_details = 3;
 * @return {?proto.entity.UpdateCampaignAction.SetInternalDetailsAction}
 */
proto.entity.UpdateCampaignAction.prototype.getSetInternalDetails = function() {
  return /** @type{?proto.entity.UpdateCampaignAction.SetInternalDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateCampaignAction.SetInternalDetailsAction, 3));
};


/**
 * @param {?proto.entity.UpdateCampaignAction.SetInternalDetailsAction|undefined} value
 * @return {!proto.entity.UpdateCampaignAction} returns this
*/
proto.entity.UpdateCampaignAction.prototype.setSetInternalDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction} returns this
 */
proto.entity.UpdateCampaignAction.prototype.clearSetInternalDetails = function() {
  return this.setSetInternalDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.prototype.hasSetInternalDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SetDeletedAction set_deleted = 4;
 * @return {?proto.entity.UpdateCampaignAction.SetDeletedAction}
 */
proto.entity.UpdateCampaignAction.prototype.getSetDeleted = function() {
  return /** @type{?proto.entity.UpdateCampaignAction.SetDeletedAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateCampaignAction.SetDeletedAction, 4));
};


/**
 * @param {?proto.entity.UpdateCampaignAction.SetDeletedAction|undefined} value
 * @return {!proto.entity.UpdateCampaignAction} returns this
*/
proto.entity.UpdateCampaignAction.prototype.setSetDeleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.UpdateCampaignAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateCampaignAction} returns this
 */
proto.entity.UpdateCampaignAction.prototype.clearSetDeleted = function() {
  return this.setSetDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateCampaignAction.prototype.hasSetDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.entity);

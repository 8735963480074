import {
  getStoredFacebookTableDataColumns,
  resetAllFacebookTableOptions,
  setStoredFacebookTableDataColumns
} from "Common/utils/savedTablePreferences";
import AmpdDataTableOptions, {
  useAmpdDataTableOptions
} from "ExtensionV2/components/AmpdDataTableOptions";
import GlobalDatePicker from "ExtensionV2/components/GlobalDatePicker";
import { FB_DATA_ROLLUP_LEVELS } from "ExtensionV2/queries/useFacebookPerformanceData";
import { popover } from "ExtensionV2/styles/zIndexes";
import React from "react";
import { connect } from "redux-zero/react";
import { Button, Dropdown, Popup } from "semantic-ui-react";
import styled from "styled-components";

import {
  defaultFacebookColumns,
  FacebookAdsPerformanceTable,
  facebookPerformanceColumns
} from "./FacebookPerformanceTable";

const FilterLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

function _AdPerformanceTab({
  dateRangeStartDateString,
  dateRangeEndDateString
}: {
  dateRangeStartDateString: string;
  dateRangeEndDateString: string;
}): JSX.Element {
  const [selectedRollup, setSelectedRollup] = React.useState<
    FB_DATA_ROLLUP_LEVELS
  >("adId");

  const {
    showFractions,
    showUnconvertedRevenue,
    excludeAmazonLagPeriod,
    selectableOptionsMap,
    selectedColumns,
    handleSelectColumnOption,
    handleResetTableOptions,
    handleToggleShowFraction,
    handleToggleShowUnconvertedRevenue,
    handleToggleExcludeAmazonLagPeriod
  } = useAmpdDataTableOptions({
    allColumns: facebookPerformanceColumns.map(col => col.name),
    defaultColumns: defaultFacebookColumns,
    unhideableColumns: ["name"],
    getStoredDataTableColumns: getStoredFacebookTableDataColumns,
    setStoredDataTableColumns: setStoredFacebookTableDataColumns,
    resetAllDataTableOptions: resetAllFacebookTableOptions
  });

  return (
    <>
      <div style={{ flexShrink: 0, zIndex: popover }}>
        <GlobalDatePicker />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <FacebookTableFilters onSelectRollupLevel={setSelectedRollup} />
          <Popup
            trigger={
              <Button style={{ height: "3em" }} size="medium">
                Table Options
              </Button>
            }
            on="click"
            position="bottom right"
            content={
              <AmpdDataTableOptions
                columnOptionsMap={selectableOptionsMap}
                columnTitles={facebookPerformanceColumns.reduce((acc, curr) => {
                  return { ...acc, [curr.name]: curr.displayName };
                }, {})}
                onToggleColumn={handleSelectColumnOption}
                onToggleShowFractions={handleToggleShowFraction}
                onToggleShowUnconvertedRevenue={
                  handleToggleShowUnconvertedRevenue
                }
                onToggleShowIncompleteData={handleToggleExcludeAmazonLagPeriod}
                onResetTableOptions={handleResetTableOptions}
                showFractions={showFractions}
                showUnconvertedRevenue={showUnconvertedRevenue}
                excludeAmazonLagPeriod={excludeAmazonLagPeriod}
                showAmazonLagPeriodToggle={false}
                showFractionsToggle={true}
                showUnconvertedRevenueToggle={false}
              />
            }
          />
        </div>
      </div>

      <div
        style={{
          overflow: "auto"
        }}
      >
        <FacebookAdsPerformanceTable
          startDate={dateRangeStartDateString}
          endDate={dateRangeEndDateString}
          rollupLevel={selectedRollup}
          selectedColumns={selectedColumns}
          showFractions={showFractions}
        />
      </div>
    </>
  );
}

function FacebookTableFilters({
  onSelectRollupLevel
}: {
  onSelectRollupLevel: (level: FB_DATA_ROLLUP_LEVELS) => void;
}): JSX.Element {
  return (
    <div style={{ marginTop: "0.5em" }}>
      <FilterLabel>Select Level</FilterLabel>
      <Dropdown
        selection
        defaultValue="adId"
        options={[
          { key: "adId", text: "Ad", value: "adId" },
          { key: "adSetId", text: "Ad Set", value: "adSetId" },
          { key: "campaignId", text: "Campaign", value: "campaignId" }
        ]}
        onChange={(_, { value }) => {
          onSelectRollupLevel(value as FB_DATA_ROLLUP_LEVELS);
        }}
      />
    </div>
  );
}

export const AdPerformanceTab = connect(state => {
  const dateRangeStartDateString = state.dataStartDate;
  const dateRangeEndDateString = state.dataEndDate;

  return {
    dateRangeStartDateString,
    dateRangeEndDateString
  };
})(_AdPerformanceTab);

import _ from "lodash";
import moment from "moment";
import { extractErrorMessage } from "Common/errors/error";
import { extractAmazonURLInfoFromString } from "Common/utils/amazon";
import * as datetime_pb from "Common/proto/common/datetime_pb";
import {
  GetCreatedGoogleAdsCampaignsRequest,
  GoogleAdsAuditRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";

import * as proto from "Common/utils/proto";
import { GRPCWebCallbackClient, GRPCWebClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";
import { parseDateRangeParam } from "Common/components/dateRangeUtils";
import { getStoredExcludeLagPeriod } from "Common/utils/savedTablePreferences";
import { AMAZON_ATTRIBUTION_DELAY_DAYS } from "Common/utils/amazon";

export const QUERY_STRING_DATE_FORMAT = "YYYY-MM-DD";

export const DATE_RANGE_CUSTOM_START_URL_PARAM = "queryStart";
export const DATE_RANGE_CUSTOM_END_URL_PARAM = "queryEnd";
export const DATE_RANGE_NAME_URL_PARAM = "queryRange";
export const DATE_RANGE_COMPARE_TO_URL_PARAM = "compare";

export const DEFAULT_NAMED_DATE_RANGE = "last-30-days";

/*
  Hold a reference to this data stream in common scope so that it can be cancelled
*/
let campaignDetailsRequestStream;

// Try to pull dates from the query string. If queryEnd is not found or is invalid,
// use the current day. If queryStart is not found or invalid, use the default data range relative
// to queryEnd
const getInitialStartDate = (queryParams, queryEnd) => {
  const queryStartParam = queryParams.get(DATE_RANGE_CUSTOM_START_URL_PARAM);

  if (queryStartParam) {
    const queryStart = moment(queryStartParam, QUERY_STRING_DATE_FORMAT);
    if (queryStart.isValid() && queryStart < queryEnd) {
      return queryStart;
    }
  }

  return moment(queryEnd, QUERY_STRING_DATE_FORMAT)
    .startOf("day")
    .subtract(30, "days");
};

const getInitialEndDate = queryParams => {
  const queryEndParam = queryParams.get(DATE_RANGE_CUSTOM_END_URL_PARAM);

  if (queryEndParam) {
    const queryEnd = moment(queryEndParam, QUERY_STRING_DATE_FORMAT);
    if (queryEnd.isValid()) {
      return queryEnd;
    }
  }

  return moment().startOf("day");
};

const getDefaultRange = () => {
  const defaultEndDate = moment()
    .startOf("day")
    .subtract(1, "day");
  return [
    moment(defaultEndDate)
      .startOf("day")
      .subtract(30 - 1, "days")
      .format(QUERY_STRING_DATE_FORMAT),
    defaultEndDate.format(QUERY_STRING_DATE_FORMAT),
    DEFAULT_NAMED_DATE_RANGE
  ];
};

const getInitialDateRange = () => {
  const queryParams = new URLSearchParams(window.location.search);

  let queryRangeParam = queryParams.get(DATE_RANGE_NAME_URL_PARAM);
  let queryStartParam = queryParams.get(DATE_RANGE_CUSTOM_START_URL_PARAM);
  let queryEndParam = queryParams.get(DATE_RANGE_CUSTOM_END_URL_PARAM);
  const compareParam = queryParams.get(DATE_RANGE_COMPARE_TO_URL_PARAM);

  if (
    queryStartParam &&
    !moment(queryStartParam, QUERY_STRING_DATE_FORMAT).isValid()
  ) {
    queryStartParam = null;
  }

  if (
    queryEndParam &&
    !moment(queryEndParam, QUERY_STRING_DATE_FORMAT).isValid()
  ) {
    queryEndParam = null;
  }

  if (
    queryStartParam &&
    queryEndParam &&
    moment(queryEndParam, QUERY_STRING_DATE_FORMAT).isBefore(
      moment(queryStartParam, QUERY_STRING_DATE_FORMAT)
    )
  ) {
    queryStartParam = null;
    queryEndParam = null;
  }

  const excludeAmazonLagPeriod = getStoredExcludeLagPeriod();

  if (queryRangeParam || !queryStartParam || !queryEndParam) {
    if (!queryRangeParam) {
      queryRangeParam = DEFAULT_NAMED_DATE_RANGE;
    }

    try {
      const refDate = moment()
        .subtract(
          excludeAmazonLagPeriod
            ? Math.max(AMAZON_ATTRIBUTION_DELAY_DAYS, 0)
            : 0,
          "days"
        )
        .endOf("day");

      const [startDate, endDate] = parseDateRangeParam(
        queryRangeParam,
        refDate
      );
      return [
        startDate.format(QUERY_STRING_DATE_FORMAT),
        endDate.format(QUERY_STRING_DATE_FORMAT),
        queryRangeParam,
        compareParam
      ];
    } catch (e) {
      console.error(e);
      return getDefaultRange();
    }
  }

  const queryEnd = getInitialEndDate(queryParams);
  const queryStart = getInitialStartDate(queryParams, queryEnd);
  return [
    queryStart.format(QUERY_STRING_DATE_FORMAT),
    queryEnd.format(QUERY_STRING_DATE_FORMAT),
    undefined,
    compareParam
  ];
};

// Creates the initial audit state for the store.
export const makeInitialAuditState = () => {
  const [
    initialStartDate,
    initialEndDate,
    namedRange,
    compareTo
  ] = getInitialDateRange();
  return {
    // Information about the campaigns that the user has created.
    campaignInfo: {
      loading: false,
      error: null,
      data: null
    },

    // Daily campaign metrics for each campaign
    dailyMetricsForCreatedCampaigns: {
      loading: false,
      error: null,
      data: null
    },

    // Assessment information for the user's Google Ads account.
    auditInfo: {
      loading: false,
      error: null,
      data: null,
      refreshCount: 0
    },

    // Google login details, if the user has logged in during the session.
    //
    // This is only used during the Google Ads linking process.
    googleLogin: {
      accessToken: "",
      email: ""
    },
    enableCreateGoogleAdsAccount: false,

    // global data range that is shared between dashboard pages
    dataStartDate: initialStartDate,
    dataEndDate: initialEndDate,
    namedRange,
    dataCompareTo: compareTo,

    newURLParams: null,

    currentURLParams: {
      newCampaignID: null,
      campaignID: null,
      refDate: null,
      expandCampaign: false,
      showKeywordScores: false,
      markIncompleteData: false
    }
  };
};

// Updates the auditInfo section of the state.
const updateAuditInfo = (state, updatedFields) => ({
  ...state,
  auditInfo: { ...state.auditInfo, ...updatedFields }
});

// Redux actions for managing the core audit experience.
export const auditActions = ({ setState, getState }) => ({
  // Sets the Google login details from the Google Ads linking process.
  setGoogleLogin: (state, response) => {
    const accessToken = _.get(response, "accessToken", "");
    const email = _.get(response, "profileObj.email", "");

    return { ...state, googleLogin: { accessToken, email } };
  },
  setEnableCreateGoogleAdsAccount: (state, enableCreate) => {
    return { ...state, enableCreateGoogleAdsAccount: enableCreate };
  },

  increaseAuditInfoRefreshCount: state => {
    return {
      ...state,
      auditInfo: {
        ...state.auditInfo,
        refreshCount: state.auditInfo.refreshCount + 1
      }
    };
  },

  // Refreshes the Google Ads audit information.
  refreshAuditInfo: (state, incrementRefreshCount) => {
    const { siteAlias, googleAdsAccount } = state.current;
    const customerId = _.get(googleAdsAccount, "customerId", "");
    if (!siteAlias || !customerId) {
      return state;
    }

    if (state.auditInfo.loading) {
      return state;
    }

    let refreshCount = state.auditInfo.refreshCount;
    if (incrementRefreshCount) {
      refreshCount++;
    }

    setState({
      auditInfo: {
        ...state.auditInfo,
        error: null,
        loading: true,
        refreshCount
      }
    });

    return queryAuditInfo({ siteAlias, customerId })
      .then(data => {
        return updateAuditInfo(getState(), {
          error: null,
          loading: false,
          data,
          refreshCount
        });
      })
      .catch(err => {
        state = getState();
        const error = extractErrorMessage(err) || "Server error";
        console.error(error);
        return updateAuditInfo(getState(), {
          error,
          loading: false,
          refreshCount
        });
      });
  },

  // Fetches daily Google Ads metrics  (clicks, impressions, etc) for the date
  // range. This can be kinda slow since it fetches for all campaigns, but is nice
  // because each individual campaign graph that uses the data already has it fetched.
  // It also includes amazon search ranking data by day
  fetchDailyMetricsForCreatedCampaigns: (
    state,
    siteAlias,
    startDate,
    endDate
  ) => {
    if (!siteAlias) {
      return state;
    }

    if (
      startDate === state.dailyMetricsForCreatedCampaigns.queryStart &&
      endDate === state.dailyMetricsForCreatedCampaigns.queryEnd
    ) {
      return state;
    }

    if (
      state.dailyMetricsForCreatedCampaigns.loading &&
      campaignDetailsRequestStream
    ) {
      campaignDetailsRequestStream.cancel();
    }

    let queryStartTime = moment(state.dataStartDate).startOf("day");
    let queryEndTime = moment(state.dataEndDate).startOf("day");

    if (!queryStartTime || !queryEndTime) {
      throw new Error(
        "Could not find a start and end date in the application state."
      );
    }

    setState({
      dailyMetricsForCreatedCampaigns: {
        ...state.dailyMetricsForCreatedCampaigns,
        error: null,
        loading: true,
        queryStart: startDate,
        queryEnd: endDate
      }
    });

    return getCreatedGoogleAdsCampaignForDateRange(
      siteAlias,
      queryStartTime,
      queryEndTime
    )
      .then(campaignInfoList => {
        let currentState = getState();

        const dailyMetricsMap = campaignInfoList.reduce((map, campaignInfo) => {
          map[campaignInfo.campaignId] = {
            ...campaignInfo?.adGroupInfosList[0],
            campaignMetrics: campaignInfo?.campaignMetrics
          };
          return map;
        }, {});

        return {
          ...currentState,
          dailyMetricsForCreatedCampaigns: {
            ...currentState.dailyMetricsForCreatedCampaigns,
            error: null,
            loading: false,
            data: dailyMetricsMap
          }
        };
      })
      .catch(err => {
        let currentState = getState();
        const error = extractErrorMessage(err) || "Server error";
        console.error(error);
        return {
          ...currentState,
          dailyMetricsForCreatedCampaigns: {
            ...currentState.dailyMetricsForCreatedCampaigns,
            error,
            loading: false
          }
        };
      });
  },

  // Refreshes information about the campaigns that a site has created.
  refreshCampaignInfo: (state, siteAlias, omitMetrics = false) => {
    if (!siteAlias) {
      return state;
    }

    if (state.campaignInfo.loading) {
      return state;
    }

    setState({
      campaignInfo: { ...state.campaignInfo, error: null, loading: true }
    });

    let endDate = null;
    if (!omitMetrics && state.campaignInfo.data) {
      endDate = moment().startOf("day");
    }

    return getCreatedGoogleAdsCampaigns(siteAlias, endDate)
      .then(([campaignInfoList, startDate]) => {
        return {
          ...getState(),
          campaignInfo: {
            error: null,
            loading: false,
            data: {
              campaignInfoList,
              startDate,
              endDate
            }
          }
        };
      })
      .catch(err => {
        state = getState();
        const error = extractErrorMessage(err) || "Server error";
        console.error(error);
        return {
          ...state,
          campaignInfo: { ...state.campaignInfo, error, loading: false }
        };
      });
  },

  setCurrentURLParams: (state, params) => {
    const newURLParams = {
      ...state.currentURLParams,
      ...state.newURLParams,
      ...params
    };

    if (
      !_.isEqual(newURLParams, state.currentURLParams) ||
      state.newURLParams !== null
    ) {
      setState({
        currentURLParams: newURLParams,
        newURLParams: null
      });
    }
  },

  setSelectedCampaignID: (state, campaignID) => {
    const newURLParams = {
      ...state.currentURLParams,
      ...state.newURLParams,
      campaignID: `${campaignID}`
    };

    if (!_.isEqual(newURLParams, state.newURLParams)) {
      setState({
        newURLParams
      });
    }
  },

  setNewCampaignID: (state, campaignID) => {
    const newURLParams = {
      ...state.currentURLParams,
      ...state.newURLParams,
      newCampaignID: `${campaignID}`
    };

    if (!_.isEqual(newURLParams, state.newURLParams)) {
      setState({
        newURLParams
      });
    }
  },

  setExpandCampaign: (state, expandCampaign) => {
    const newURLParams = {
      ...state.currentURLParams,
      ...state.newURLParams,
      expandCampaign: Boolean(expandCampaign)
    };

    if (!_.isEqual(newURLParams, state.newURLParams)) {
      setState({
        newURLParams
      });
    }
  },

  dispatchSetDataDateRange: (
    state,
    newStartDate,
    newEndDate,
    newNamedRange,
    newCompareTo
  ) => {
    if (
      state.dataStartDate === newStartDate &&
      state.dataEndDate === newEndDate &&
      state.namedRange === newNamedRange &&
      state.compareTo === newCompareTo
    ) {
      return state;
    }

    return {
      ...state,
      dataStartDate: newStartDate,
      dataEndDate: newEndDate,
      namedRange: newNamedRange,
      dataCompareTo: newCompareTo
    };
  },

  dispatchEnsureDateUrlParams: (state, searchParams, setSearchParams) => {
    const queryRangeParam = searchParams.get(DATE_RANGE_NAME_URL_PARAM);
    const queryStartParam = searchParams.get(DATE_RANGE_CUSTOM_START_URL_PARAM);
    const queryEndParam = searchParams.get(DATE_RANGE_CUSTOM_END_URL_PARAM);
    if (!queryRangeParam && !queryStartParam) {
      if (state.namedRange) {
        searchParams.set(DATE_RANGE_NAME_URL_PARAM, state.namedRange);
        searchParams.delete(DATE_RANGE_CUSTOM_START_URL_PARAM);
        searchParams.delete(DATE_RANGE_CUSTOM_END_URL_PARAM);
      } else {
        searchParams.delete(DATE_RANGE_NAME_URL_PARAM);
        searchParams.set(
          DATE_RANGE_CUSTOM_START_URL_PARAM,
          moment(state.dataStartDate).format(QUERY_STRING_DATE_FORMAT)
        );
        searchParams.set(
          DATE_RANGE_CUSTOM_END_URL_PARAM,
          moment(state.dataEndDate).format(QUERY_STRING_DATE_FORMAT)
        );
      }
    } else if ((queryStartParam || queryEndParam) && !queryRangeParam) {
      if (state.dataStartDate) {
        searchParams.set(
          DATE_RANGE_CUSTOM_START_URL_PARAM,
          state.dataStartDate
        );
      }

      if (state.dataEndDate) {
        searchParams.set(DATE_RANGE_CUSTOM_END_URL_PARAM, state.dataEndDate);
      }
    } else if (queryRangeParam && !state.namedRange) {
      searchParams.delete(DATE_RANGE_NAME_URL_PARAM);

      if (state.dataStartDate) {
        searchParams.set(
          DATE_RANGE_CUSTOM_START_URL_PARAM,
          state.dataStartDate
        );
      }

      if (state.dataEndDate) {
        searchParams.set(DATE_RANGE_CUSTOM_END_URL_PARAM, state.dataEndDate);
      }
    } else {
      if (state.namedRange) {
        searchParams.set(DATE_RANGE_NAME_URL_PARAM, state.namedRange);
      } else {
        searchParams.set(DATE_RANGE_NAME_URL_PARAM, DEFAULT_NAMED_DATE_RANGE);
      }
      searchParams.delete(DATE_RANGE_CUSTOM_START_URL_PARAM);
      searchParams.delete(DATE_RANGE_CUSTOM_END_URL_PARAM);
    }

    if (state.dataCompareTo) {
      searchParams.set(DATE_RANGE_COMPARE_TO_URL_PARAM, state.dataCompareTo);
    } else {
      searchParams.delete(DATE_RANGE_COMPARE_TO_URL_PARAM);
    }

    setSearchParams(searchParams, { replace: true });

    return state;
  }
});

// Returns the audit info for the given customer.
export async function queryAuditInfo({ customerId, siteAlias }) {
  const auditReq = proto.set(new GoogleAdsAuditRequest(), {
    siteAlias,
    customerId
  });

  const auditReply = await GRPCWebClient.googleAdsAudit(auditReq);
  return auditReply.getGoogleAdsAudit().toObject();
}

// A little more than 9 weeks of data (which should enable last-8-weeks, but not, say, last-year)
export const AMAZON_RANKING_MAX_NUMBER_OF_GRAPHED_DAYS = 9 * 7 + 1;

// Returns the startDate and dateRangeProto for the specified endDate.  If no
// end date is specified, then the startDate and dateRangeProto will be undefined.
function getDateRangeForQuery(endDate) {
  if (!endDate) {
    return {};
  }

  const startDate = moment(endDate).subtract(
    AMAZON_RANKING_MAX_NUMBER_OF_GRAPHED_DAYS - 1,
    "days"
  );

  const dateRangeProto = proto.set(new datetime_pb.DateRangeV2(), {
    startDate: proto.set(new datetime_pb.Date(), {
      year: startDate.year(),
      month: startDate.month() + 1 /* month() is zero-based */,
      day: startDate.date()
    }),
    endDate: proto.set(new datetime_pb.Date(), {
      year: endDate.year(),
      month: endDate.month() + 1 /* month() is zero-based */,
      day: endDate.date()
    })
  });

  return { startDate, dateRangeProto };
}

async function getCreatedGoogleAdsCampaignForDateRange(
  siteAlias,
  startDate,
  endDate
) {
  const dateRangeProto = proto.set(new datetime_pb.DateRangeV2(), {
    startDate: proto.set(new datetime_pb.Date(), {
      year: startDate.year(),
      month: startDate.month() + 1 /* month() is zero-based */,
      day: startDate.date()
    }),
    endDate: proto.set(new datetime_pb.Date(), {
      year: endDate.year(),
      month: endDate.month() + 1 /* month() is zero-based */,
      day: endDate.date()
    })
  });

  const req = proto.set(new GetCreatedGoogleAdsCampaignsRequest(), {
    siteAlias,
    dateRange: dateRangeProto
  });

  const campaignInfoList = [];
  campaignDetailsRequestStream = GRPCWebCallbackClient.getCreatedGoogleAdsCampaigns(
    req
  );
  await streamProcessor(campaignDetailsRequestStream, response =>
    campaignInfoList.push(...response.toObject().campaignInfosList)
  );

  return campaignInfoList;
}

// Returns the campaign info list for the site.
async function getCreatedGoogleAdsCampaigns(siteAlias, endDate) {
  const { startDate, dateRangeProto } = getDateRangeForQuery(endDate);

  const req = proto.set(new GetCreatedGoogleAdsCampaignsRequest(), {
    siteAlias,
    dateRange: dateRangeProto
  });

  const campaignInfoList = [];
  await streamProcessor(
    GRPCWebCallbackClient.getCreatedGoogleAdsCampaigns(req),
    response => campaignInfoList.push(...response.toObject().campaignInfosList)
  );

  return [campaignInfoList, startDate];
}

export function getEnabledAmazonCampaignCount(campaignInfoList, allCampaigns) {
  if (!campaignInfoList) {
    return 0;
  }

  const enabledAmazonCampaigns = new Set();
  campaignInfoList.forEach(campaignInfo => {
    const adGroupInfos = campaignInfo.adGroupInfosList || [];
    adGroupInfos.forEach(adGroupInfo => {
      const asin = _.get(adGroupInfo, "productDetails.amazon.asin", "");
      if (asin) {
        const amazonCampaign = _.find(
          allCampaigns,
          campaign => campaign.campaignId === String(campaignInfo.campaignId)
        );
        if (amazonCampaign && amazonCampaign.status === "ENABLED") {
          enabledAmazonCampaigns.add(amazonCampaign);
        }
      }
    });
  });
  return enabledAmazonCampaigns.size;
}

export function findAmazonCampaignAndAdGroupInfo(
  campaignInfoList,
  campaignId,
  adGroupId
) {
  if (!campaignInfoList || !campaignId) {
    return {};
  }

  for (
    let campaignIndex = 0;
    campaignIndex < campaignInfoList.length;
    campaignIndex++
  ) {
    const campaignInfo = campaignInfoList[campaignIndex];
    if (String(campaignInfo.campaignId) !== String(campaignId)) {
      continue;
    }

    for (
      let adGroupIndex = 0;
      adGroupIndex < campaignInfo.adGroupInfosList.length;
      adGroupIndex++
    ) {
      const adGroupInfo = campaignInfo.adGroupInfosList[adGroupIndex];
      if (adGroupId) {
        if (String(campaignInfo.adGroupId) !== String(adGroupId)) {
          continue;
        }
      }

      if (adGroupInfo.productDetails && adGroupInfo.productDetails.amazon) {
        let adInfo;
        if (adGroupInfo && adGroupInfo.adInfosList) {
          if (adGroupInfo.adInfosList.length === 1) {
            adInfo = adGroupInfo.adInfosList[0];
          }
        }

        return {
          campaignInfo,
          adGroupInfo,
          adInfo,
          productDetails: adGroupInfo.productDetails.amazon
        };
      }
    }
  }

  return {};
}

// Returns the Amazon Marketplace for the requested campaign, when found, else null.
export function getAmazonMarketplaceForCampaign(campaignId, campaignInfoList) {
  const { adInfo } = findAmazonCampaignAndAdGroupInfo(
    campaignInfoList,
    campaignId
  );

  if (!adInfo || _.isEmpty(adInfo.finalUrlsList)) {
    return null;
  }

  const finalUrl = adInfo.finalUrlsList[0];
  const { marketplaceInfo } = extractAmazonURLInfoFromString(finalUrl);

  return marketplaceInfo?.marketplace ?? null;
}

export function hasAmazonCampaigns(campaignInfoList) {
  // Return if any campaigns have amazon product adgroups
  return campaignInfoList.some(campaignInfo => {
    // Return if we found any amazon product adGroups
    return campaignInfo.adGroupInfosList.some(adGroupInfo => {
      // Return if THIS adGroup is for an Amazon product
      return !!adGroupInfo?.productDetails?.amazon ?? false;
    });
  });
}

// Returns the Amazon ASIN for the requested campaign, when found, else null.
export function determineAmazonASINForCampaign(campaignInfo) {
  const { adInfo, productDetails } = findAmazonCampaignAndAdGroupInfo(
    [campaignInfo],
    campaignInfo.campaignId
  );

  let createdWithASIN = null;
  if (productDetails) {
    createdWithASIN = productDetails.asin;
  }

  if (!adInfo || _.isEmpty(adInfo.finalUrlsList)) {
    return createdWithASIN;
  }

  const finalUrl = adInfo.finalUrlsList[0];
  const { asin } = extractAmazonURLInfoFromString(finalUrl);

  return asin || createdWithASIN;
}

// Creates an info object that describes a created amazon product ad campaign based
// on a campaign info object returned by GetCreatedGoogleAdsCampaign.
export function gatherAmazonProductAdCreationInfo(
  campaign,
  campaignInfo,
  adGroupInfo
) {
  if (!campaignInfo) {
    return null;
  }

  if (!adGroupInfo) {
    if (
      campaignInfo.adGroupInfosList &&
      campaignInfo.adGroupInfosList.length > 0
    ) {
      adGroupInfo = campaignInfo.adGroupInfosList[0];
    }
  }

  let adInfo = null;
  let usesAmpdAttribution = false;
  if (adGroupInfo && adGroupInfo.adInfosList) {
    if (adGroupInfo.adInfosList.length === 1) {
      adInfo = adGroupInfo.adInfosList[0];
    }
    adGroupInfo.adInfosList.forEach(adInfo => {
      if (adInfo.usesAmpdAttribution) {
        usesAmpdAttribution = true;
      }
    });
  }

  return {
    campaignInfo,
    adGroupInfo,
    adInfo,
    productDetails: _.get(adGroupInfo, "productDetails.amazon", null),
    usesAmpdAttribution,
    campaign
  };
}

export const ALL_STATUSES = "All";
export const ALL_BUT_REMOVED_STATUS = "Enabled & Paused";
export const ENABLED_STATUS = "Only Enabled";
export const PAUSED_STATUS = "Only Paused";
export const REMOVED_STATUS = "Only Removed";

export function filterBasedOnStatus(filterStatus, status) {
  switch (filterStatus) {
    case ALL_STATUSES:
      break;
    default:
    case ALL_BUT_REMOVED_STATUS:
      if (status === "REMOVED") {
        return true;
      }
      break;
    case ENABLED_STATUS:
      if (status !== "ENABLED") {
        return true;
      }
      break;
    case PAUSED_STATUS:
      if (status !== "PAUSED") {
        return true;
      }
      break;
    case REMOVED_STATUS:
      if (status !== "REMOVED") {
        return true;
      }
      break;
  }

  return false;
}

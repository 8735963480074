import moment from "moment";

// "for_metrics" date ranges with "Last" in the title do not include today or
// this week or this month, depending on the time unit, because the metrics for
// the partial time unit are not complete yet. However, the choices with "This"
// in the title (and, of course, "Today"), do allow seeing the partial set of
// metrics.

export const METRIC_DATE_RANGE_OPTIONS = [
  {
    text: "Today",
    value: "today"
  },
  {
    text: "Yesterday",
    value: "yesterday"
  },
  {
    text: "This week (Sun - Today)",
    value: "this-week"
  },
  {
    text: "Last 7 days",
    value: "last-7-days"
  },
  {
    text: "Last week (Sun - Sat)",
    value: "last-week"
  },
  {
    text: "Last 14 days",
    value: "last-14-days"
  },
  {
    text: "This month",
    value: "this-month"
  },
  {
    text: "Last month",
    value: "last-month"
  },
  {
    text: "Last 30 days",
    value: "last-30-days"
  },
  {
    text: "Last 60 days",
    value: "last-60-days"
  },
  {
    text: "Last 90 days",
    value: "last-90-days"
  },
  {
    text: "Last 120 days",
    value: "last-120-days"
  },
  {
    text: "Last 180 days",
    value: "last-180-days"
  },
  {
    text: "Last 8 weeks",
    value: "last-8-weeks"
  },
  {
    text: "Last 16 weeks",
    value: "last-16-weeks"
  },
  {
    text: "This year",
    value: "this-year"
  },
  {
    text: "Last year",
    value: "last-year"
  },
  {
    text: "Last 52 weeks",
    value: "last-52-weeks"
  }
];

// Parse date value parameter into a start date and an end date.
// If overrideRefDate is specified (as string or moment), then exclusive date
// ranges like "last-*" are determined relative to that reference date,
// otherwise they are determined relative to today (or overrideTodayDate).
// If overrideTodayDate is specified (as string or moment), then inclusive
// date ranges like "today" and "this-*" (and, as a special exception,
// "yesterday") are determined relative to that date, otherwise they are
// determined relative to true today.
export function parseDateRangeParam(
  dateRange,
  overrideRefDate,
  overrideTodayDate
) {
  const todayDate = overrideTodayDate
    ? moment(overrideTodayDate).endOf("day")
    : moment().endOf("day");
  if (!todayDate.isValid()) {
    throw new Error(`invalid todayDate: ${todayDate}`);
  }

  const refDate = overrideRefDate
    ? moment(overrideRefDate).endOf("day")
    : moment().endOf("day");
  if (!refDate.isValid()) {
    throw new Error(`invalid refDate: ${refDate}`);
  }

  // The today, yesterday, and this-* date ranges are calculate relative to todayDate, not refDate.
  // NOTE: Always, clone todayDate with moment(todayDate) because some operations
  // (like 'subtract') mutate the moment object.
  let startDate = null;
  let endDate = null;
  switch (dateRange) {
    case "today":
      endDate = moment(todayDate);
      startDate = moment(todayDate);
      break;
    case "yesterday":
      endDate = moment(todayDate).subtract(1, "days");
      startDate = moment(todayDate).subtract(1, "days");
      break;
    case "this-week": {
      const sunday = moment(todayDate)
        .add(1, "days")
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(todayDate);
      startDate = moment(sunday);
      break;
    }
    case "this-month":
      endDate = moment(todayDate);
      startDate = moment(todayDate).startOf("month");
      break;
    case "this-year":
      endDate = moment(todayDate);
      startDate = moment(todayDate).startOf("year");
      break;

    // The following date ranges are calculated relative to refDate, not todayDate.
    case "last-7-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(7, "days");
      break;
    case "last-week": {
      const sunday = moment(refDate)
        .add(1, "days")
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(sunday).subtract(1, "days");
      startDate = moment(sunday).subtract(7, "days");
      break;
    }
    case "last-14-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(14, "days");
      break;
    case "last-month":
      endDate = moment(refDate)
        .subtract(1, "months")
        .endOf("month");
      startDate = moment(refDate)
        .subtract(1, "months")
        .startOf("month");
      break;
    case "last-30-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(30, "days");
      break;
    case "last-60-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(60, "days");
      break;
    case "last-90-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(90, "days");
      break;
    case "last-120-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(120, "days");
      break;
    case "last-180-days":
      endDate = moment(refDate).subtract(1, "days");
      startDate = moment(refDate).subtract(180, "days");
      break;
    case "last-8-weeks": {
      const sunday = moment(refDate)
        .add(1, "days")
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(sunday).subtract(1, "days");
      startDate = moment(sunday).subtract(8 * 7, "days");
      break;
    }
    case "last-16-weeks": {
      const sunday = moment(refDate)
        .add(1, "days")
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(sunday).subtract(1, "days");
      startDate = moment(sunday).subtract(16 * 7, "days");
      break;
    }
    case "last-year":
      endDate = moment(refDate)
        .subtract(1, "years")
        .endOf("year");
      startDate = moment(refDate)
        .subtract(1, "years")
        .startOf("year");
      break;
    case "last-52-weeks": {
      const sunday = moment(refDate)
        .add(1, "days")
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(sunday).subtract(1, "days");
      startDate = moment(sunday).subtract(52 * 7, "days");
      break;
    }
    case "15-days":
      endDate = moment(refDate);
      startDate = moment(refDate).subtract(14, "days");
      break;
    case "31-days":
      endDate = moment(refDate);
      startDate = moment(refDate).subtract(30, "days");
      break;
    case "61-days":
      endDate = moment(refDate);
      startDate = moment(refDate).subtract(60, "days");
      break;
    case "91-days":
      endDate = moment(refDate);
      startDate = moment(refDate).subtract(90, "days");
      break;
    case "181-days":
      endDate = moment(refDate);
      startDate = moment(refDate).subtract(180, "days");
      break;
    default:
      throw new Error(`cannot parse dateRange: ${dateRange}`);
  }

  return [startDate, endDate, moment(refDate), moment(todayDate)];
}

/**
 * @fileoverview gRPC-Web generated client stub for grpcwebPb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as proto_edge_grpcwebPb_grpcweb_DashboardSession_pb from '../../../proto/edge/grpcwebPb/grpcweb_DashboardSession_pb';
import * as proto_edge_grpcwebPb_grpcweb_Admin_pb from '../../../proto/edge/grpcwebPb/grpcweb_Admin_pb';
import * as proto_edge_grpcwebPb_grpcweb_Amazon_pb from '../../../proto/edge/grpcwebPb/grpcweb_Amazon_pb';
import * as proto_edge_grpcwebPb_grpcweb_Campaigns_pb from '../../../proto/edge/grpcwebPb/grpcweb_Campaigns_pb';
import * as proto_edge_grpcwebPb_grpcweb_Cloudinary_pb from '../../../proto/edge/grpcwebPb/grpcweb_Cloudinary_pb';
import * as proto_edge_grpcwebPb_grpcweb_DashboardTable_pb from '../../../proto/edge/grpcwebPb/grpcweb_DashboardTable_pb';
import * as proto_edge_grpcwebPb_grpcweb_Facebook_pb from '../../../proto/edge/grpcwebPb/grpcweb_Facebook_pb';
import * as proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb from '../../../proto/edge/grpcwebPb/grpcweb_PerformanceMetrics_pb';
import * as proto_edge_grpcwebPb_grpcweb_GoogleAds_pb from '../../../proto/edge/grpcwebPb/grpcweb_GoogleAds_pb';
import * as proto_edge_grpcwebPb_grpcweb_Products_pb from '../../../proto/edge/grpcwebPb/grpcweb_Products_pb';
import * as proto_edge_grpcwebPb_grpcweb_Site_pb from '../../../proto/edge/grpcwebPb/grpcweb_Site_pb';
import * as proto_edge_grpcwebPb_grpcweb_Stripe_pb from '../../../proto/edge/grpcwebPb/grpcweb_Stripe_pb';
import * as proto_edge_grpcwebPb_grpcweb_User_pb from '../../../proto/edge/grpcwebPb/grpcweb_User_pb';
import * as proto_edge_grpcwebPb_grpcweb_Walmart_pb from '../../../proto/edge/grpcwebPb/grpcweb_Walmart_pb';


export class WebClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetDashboardSession = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionReply,
    (request: proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionReply.deserializeBinary
  );

  getDashboardSession(
    request: proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionReply>;

  getDashboardSession(
    request: proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionReply>;

  getDashboardSession(
    request: proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.GetDashboardSessionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetDashboardSession',
        request,
        metadata || {},
        this.methodInfoGetDashboardSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetDashboardSession',
    request,
    metadata || {},
    this.methodInfoGetDashboardSession);
  }

  methodInfoSignUp = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpReply.deserializeBinary
  );

  signUp(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpReply>;

  signUp(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpReply>;

  signUp(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/SignUp',
        request,
        metadata || {},
        this.methodInfoSignUp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/SignUp',
    request,
    metadata || {},
    this.methodInfoSignUp);
  }

  methodInfoSignUpLinkedSite = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteReply.deserializeBinary
  );

  signUpLinkedSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteReply>;

  signUpLinkedSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteReply>;

  signUpLinkedSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.SignUpLinkedSiteReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/SignUpLinkedSite',
        request,
        metadata || {},
        this.methodInfoSignUpLinkedSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/SignUpLinkedSite',
    request,
    metadata || {},
    this.methodInfoSignUpLinkedSite);
  }

  methodInfoLinkDataSource = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceReply.deserializeBinary
  );

  linkDataSource(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceReply>;

  linkDataSource(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceReply>;

  linkDataSource(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkDataSourceReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/LinkDataSource',
        request,
        metadata || {},
        this.methodInfoLinkDataSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/LinkDataSource',
    request,
    metadata || {},
    this.methodInfoLinkDataSource);
  }

  methodInfoUnlinkDataSource = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceReply.deserializeBinary
  );

  unlinkDataSource(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceReply>;

  unlinkDataSource(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceReply>;

  unlinkDataSource(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkDataSourceReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UnlinkDataSource',
        request,
        metadata || {},
        this.methodInfoUnlinkDataSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UnlinkDataSource',
    request,
    metadata || {},
    this.methodInfoUnlinkDataSource);
  }

  methodInfoLinkClientSiteToManagerSite = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteReply.deserializeBinary
  );

  linkClientSiteToManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteReply>;

  linkClientSiteToManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteReply>;

  linkClientSiteToManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkClientSiteToManagerSiteReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/LinkClientSiteToManagerSite',
        request,
        metadata || {},
        this.methodInfoLinkClientSiteToManagerSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/LinkClientSiteToManagerSite',
    request,
    metadata || {},
    this.methodInfoLinkClientSiteToManagerSite);
  }

  methodInfoUnlinkClientSiteFromManagerSite = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteReply.deserializeBinary
  );

  unlinkClientSiteFromManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteReply>;

  unlinkClientSiteFromManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteReply>;

  unlinkClientSiteFromManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.UnlinkClientSiteFromManagerSiteReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UnlinkClientSiteFromManagerSite',
        request,
        metadata || {},
        this.methodInfoUnlinkClientSiteFromManagerSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UnlinkClientSiteFromManagerSite',
    request,
    metadata || {},
    this.methodInfoUnlinkClientSiteFromManagerSite);
  }

  methodInfoUpdateSiteManagerLinkClientLabels = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsReply.deserializeBinary
  );

  updateSiteManagerLinkClientLabels(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsReply>;

  updateSiteManagerLinkClientLabels(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsReply>;

  updateSiteManagerLinkClientLabels(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.UpdateSiteManagerLinkClientLabelsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateSiteManagerLinkClientLabels',
        request,
        metadata || {},
        this.methodInfoUpdateSiteManagerLinkClientLabels,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateSiteManagerLinkClientLabels',
    request,
    metadata || {},
    this.methodInfoUpdateSiteManagerLinkClientLabels);
  }

  methodInfoGetClientSitesForManagerSite = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteReply.deserializeBinary
  );

  getClientSitesForManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteReply>;

  getClientSitesForManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteReply>;

  getClientSitesForManagerSite(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetClientSitesForManagerSiteReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetClientSitesForManagerSite',
        request,
        metadata || {},
        this.methodInfoGetClientSitesForManagerSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetClientSitesForManagerSite',
    request,
    metadata || {},
    this.methodInfoGetClientSitesForManagerSite);
  }

  methodInfoRefreshBillingAccount = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountReply.deserializeBinary
  );

  refreshBillingAccount(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountReply>;

  refreshBillingAccount(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountReply>;

  refreshBillingAccount(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.RefreshBillingAccountReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/RefreshBillingAccount',
        request,
        metadata || {},
        this.methodInfoRefreshBillingAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/RefreshBillingAccount',
    request,
    metadata || {},
    this.methodInfoRefreshBillingAccount);
  }

  methodInfoLinkBillingAccounts = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsReply.deserializeBinary
  );

  linkBillingAccounts(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsReply>;

  linkBillingAccounts(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsReply>;

  linkBillingAccounts(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.LinkBillingAccountsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/LinkBillingAccounts',
        request,
        metadata || {},
        this.methodInfoLinkBillingAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/LinkBillingAccounts',
    request,
    metadata || {},
    this.methodInfoLinkBillingAccounts);
  }

  methodInfoIsAmpdAdsSubscriber = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberReply.deserializeBinary
  );

  isAmpdAdsSubscriber(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberReply>;

  isAmpdAdsSubscriber(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberReply>;

  isAmpdAdsSubscriber(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.IsAmpdAdsSubscriberReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/IsAmpdAdsSubscriber',
        request,
        metadata || {},
        this.methodInfoIsAmpdAdsSubscriber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/IsAmpdAdsSubscriber',
    request,
    metadata || {},
    this.methodInfoIsAmpdAdsSubscriber);
  }

  methodInfoGetAmpdSubscriptions = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsReply.deserializeBinary
  );

  getAmpdSubscriptions(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsReply>;

  getAmpdSubscriptions(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsReply>;

  getAmpdSubscriptions(
    request: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Admin_pb.GetAmpdSubscriptionsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmpdSubscriptions',
        request,
        metadata || {},
        this.methodInfoGetAmpdSubscriptions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmpdSubscriptions',
    request,
    metadata || {},
    this.methodInfoGetAmpdSubscriptions);
  }

  methodInfoMarkSite = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteReply,
    (request: proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteReply.deserializeBinary
  );

  markSite(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteReply>;

  markSite(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteReply>;

  markSite(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.MarkSiteReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/MarkSite',
        request,
        metadata || {},
        this.methodInfoMarkSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/MarkSite',
    request,
    metadata || {},
    this.methodInfoMarkSite);
  }

  methodInfoUpdateSiteContactEmail = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailReply,
    (request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailReply.deserializeBinary
  );

  updateSiteContactEmail(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailReply>;

  updateSiteContactEmail(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailReply>;

  updateSiteContactEmail(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteContactEmailReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateSiteContactEmail',
        request,
        metadata || {},
        this.methodInfoUpdateSiteContactEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateSiteContactEmail',
    request,
    metadata || {},
    this.methodInfoUpdateSiteContactEmail);
  }

  methodInfoUpdateSiteBrandInfo = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoReply,
    (request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoReply.deserializeBinary
  );

  updateSiteBrandInfo(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoReply>;

  updateSiteBrandInfo(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoReply>;

  updateSiteBrandInfo(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.UpdateSiteBrandInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateSiteBrandInfo',
        request,
        metadata || {},
        this.methodInfoUpdateSiteBrandInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateSiteBrandInfo',
    request,
    metadata || {},
    this.methodInfoUpdateSiteBrandInfo);
  }

  methodInfoGetSiteBrandInfo = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoReply,
    (request: proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoReply.deserializeBinary
  );

  getSiteBrandInfo(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoReply>;

  getSiteBrandInfo(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoReply>;

  getSiteBrandInfo(
    request: proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Site_pb.GetSiteBrandInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetSiteBrandInfo',
        request,
        metadata || {},
        this.methodInfoGetSiteBrandInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetSiteBrandInfo',
    request,
    metadata || {},
    this.methodInfoGetSiteBrandInfo);
  }

  methodInfoUpdateUISettings = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsReply,
    (request: proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsReply.deserializeBinary
  );

  updateUISettings(
    request: proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsReply>;

  updateUISettings(
    request: proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsReply>;

  updateUISettings(
    request: proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_User_pb.UpdateUISettingsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateUISettings',
        request,
        metadata || {},
        this.methodInfoUpdateUISettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateUISettings',
    request,
    metadata || {},
    this.methodInfoUpdateUISettings);
  }

  methodInfoGetUISettings = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsReply,
    (request: proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsReply.deserializeBinary
  );

  getUISettings(
    request: proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsReply>;

  getUISettings(
    request: proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsReply>;

  getUISettings(
    request: proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_User_pb.GetUISettingsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetUISettings',
        request,
        metadata || {},
        this.methodInfoGetUISettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetUISettings',
    request,
    metadata || {},
    this.methodInfoGetUISettings);
  }

  methodInfoCreateCheckoutSession = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionReply.deserializeBinary
  );

  createCheckoutSession(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionReply>;

  createCheckoutSession(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionReply>;

  createCheckoutSession(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateCheckoutSessionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/CreateCheckoutSession',
        request,
        metadata || {},
        this.methodInfoCreateCheckoutSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/CreateCheckoutSession',
    request,
    metadata || {},
    this.methodInfoCreateCheckoutSession);
  }

  methodInfoPrepareStripeInvoicing = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingReply.deserializeBinary
  );

  prepareStripeInvoicing(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingReply>;

  prepareStripeInvoicing(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingReply>;

  prepareStripeInvoicing(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.PrepareStripeInvoicingReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/PrepareStripeInvoicing',
        request,
        metadata || {},
        this.methodInfoPrepareStripeInvoicing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/PrepareStripeInvoicing',
    request,
    metadata || {},
    this.methodInfoPrepareStripeInvoicing);
  }

  methodInfoApplyStripeCoupon = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponReply.deserializeBinary
  );

  applyStripeCoupon(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponReply>;

  applyStripeCoupon(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponReply>;

  applyStripeCoupon(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ApplyStripeCouponReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/ApplyStripeCoupon',
        request,
        metadata || {},
        this.methodInfoApplyStripeCoupon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/ApplyStripeCoupon',
    request,
    metadata || {},
    this.methodInfoApplyStripeCoupon);
  }

  methodInfoVerifyStripeCoupon = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponReply.deserializeBinary
  );

  verifyStripeCoupon(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponReply>;

  verifyStripeCoupon(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponReply>;

  verifyStripeCoupon(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.VerifyStripeCouponReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/VerifyStripeCoupon',
        request,
        metadata || {},
        this.methodInfoVerifyStripeCoupon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/VerifyStripeCoupon',
    request,
    metadata || {},
    this.methodInfoVerifyStripeCoupon);
  }

  methodInfoCreateStripeSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionReply.deserializeBinary
  );

  createStripeSubscription(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionReply>;

  createStripeSubscription(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionReply>;

  createStripeSubscription(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.CreateStripeSubscriptionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/CreateStripeSubscription',
        request,
        metadata || {},
        this.methodInfoCreateStripeSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/CreateStripeSubscription',
    request,
    metadata || {},
    this.methodInfoCreateStripeSubscription);
  }

  methodInfoGetNextStripeInvoice = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceReply.deserializeBinary
  );

  getNextStripeInvoice(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceReply>;

  getNextStripeInvoice(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceReply>;

  getNextStripeInvoice(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetNextStripeInvoiceReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetNextStripeInvoice',
        request,
        metadata || {},
        this.methodInfoGetNextStripeInvoice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetNextStripeInvoice',
    request,
    metadata || {},
    this.methodInfoGetNextStripeInvoice);
  }

  methodInfoGetCustomerInvoices = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesReply.deserializeBinary
  );

  getCustomerInvoices(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesReply>;

  getCustomerInvoices(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesReply>;

  getCustomerInvoices(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.GetCustomerInvoicesReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetCustomerInvoices',
        request,
        metadata || {},
        this.methodInfoGetCustomerInvoices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetCustomerInvoices',
    request,
    metadata || {},
    this.methodInfoGetCustomerInvoices);
  }

  methodInfoFinalizeStripeInvoicing = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingReply.deserializeBinary
  );

  finalizeStripeInvoicing(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingReply>;

  finalizeStripeInvoicing(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingReply>;

  finalizeStripeInvoicing(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.FinalizeStripeInvoicingReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/FinalizeStripeInvoicing',
        request,
        metadata || {},
        this.methodInfoFinalizeStripeInvoicing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/FinalizeStripeInvoicing',
    request,
    metadata || {},
    this.methodInfoFinalizeStripeInvoicing);
  }

  methodInfoUpdateStripeSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionReply.deserializeBinary
  );

  updateStripeSubscription(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionReply>;

  updateStripeSubscription(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionReply>;

  updateStripeSubscription(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.UpdateStripeSubscriptionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateStripeSubscription',
        request,
        metadata || {},
        this.methodInfoUpdateStripeSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateStripeSubscription',
    request,
    metadata || {},
    this.methodInfoUpdateStripeSubscription);
  }

  methodInfoScheduleNextSubscriptionPlan = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanReply,
    (request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanReply.deserializeBinary
  );

  scheduleNextSubscriptionPlan(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanReply>;

  scheduleNextSubscriptionPlan(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanReply>;

  scheduleNextSubscriptionPlan(
    request: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Stripe_pb.ScheduleNextSubscriptionPlanReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/ScheduleNextSubscriptionPlan',
        request,
        metadata || {},
        this.methodInfoScheduleNextSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/ScheduleNextSubscriptionPlan',
    request,
    metadata || {},
    this.methodInfoScheduleNextSubscriptionPlan);
  }

  methodInfoComputeCloudinarySignature = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureReply,
    (request: proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureReply.deserializeBinary
  );

  computeCloudinarySignature(
    request: proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureReply>;

  computeCloudinarySignature(
    request: proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureReply>;

  computeCloudinarySignature(
    request: proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Cloudinary_pb.ComputeCloudinarySignatureReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/ComputeCloudinarySignature',
        request,
        metadata || {},
        this.methodInfoComputeCloudinarySignature,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/ComputeCloudinarySignature',
    request,
    metadata || {},
    this.methodInfoComputeCloudinarySignature);
  }

  methodInfoGetCampaignConfigurations = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignConfigurationsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignConfigurationsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignConfigurationsReply.deserializeBinary
  );

  getCampaignConfigurations(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignConfigurationsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpcwebPb.Web/GetCampaignConfigurations',
      request,
      metadata || {},
      this.methodInfoGetCampaignConfigurations);
  }

  methodInfoGetCampaignBidAutomationResults = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsReply.deserializeBinary
  );

  getCampaignBidAutomationResults(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsReply>;

  getCampaignBidAutomationResults(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsReply>;

  getCampaignBidAutomationResults(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.GetCampaignBidAutomationResultsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetCampaignBidAutomationResults',
        request,
        metadata || {},
        this.methodInfoGetCampaignBidAutomationResults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetCampaignBidAutomationResults',
    request,
    metadata || {},
    this.methodInfoGetCampaignBidAutomationResults);
  }

  methodInfoUpdateCampaigns = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsReply.deserializeBinary
  );

  updateCampaigns(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsReply>;

  updateCampaigns(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsReply>;

  updateCampaigns(
    request: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Campaigns_pb.UpdateCampaignsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateCampaigns',
        request,
        metadata || {},
        this.methodInfoUpdateCampaigns,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateCampaigns',
    request,
    metadata || {},
    this.methodInfoUpdateCampaigns);
  }

  methodInfoGetSiteDashboardTableMetrics = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_DashboardTable_pb.GetSiteDashboardTableMetricsReply,
    (request: proto_edge_grpcwebPb_grpcweb_DashboardTable_pb.GetSiteDashboardTableMetricsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_DashboardTable_pb.GetSiteDashboardTableMetricsReply.deserializeBinary
  );

  getSiteDashboardTableMetrics(
    request: proto_edge_grpcwebPb_grpcweb_DashboardTable_pb.GetSiteDashboardTableMetricsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpcwebPb.Web/GetSiteDashboardTableMetrics',
      request,
      metadata || {},
      this.methodInfoGetSiteDashboardTableMetrics);
  }

  methodInfoGetProducts = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Products_pb.GetProductsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Products_pb.GetProductsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Products_pb.GetProductsReply.deserializeBinary
  );

  getProducts(
    request: proto_edge_grpcwebPb_grpcweb_Products_pb.GetProductsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpcwebPb.Web/GetProducts',
      request,
      metadata || {},
      this.methodInfoGetProducts);
  }

  methodInfoGetAsinPerformanceMetrics = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetAsinPerformanceMetricsReply,
    (request: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetAsinPerformanceMetricsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetAsinPerformanceMetricsReply.deserializeBinary
  );

  getAsinPerformanceMetrics(
    request: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetAsinPerformanceMetricsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpcwebPb.Web/GetAsinPerformanceMetrics',
      request,
      metadata || {},
      this.methodInfoGetAsinPerformanceMetrics);
  }

  methodInfoGetSitePerformanceMetrics = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsReply,
    (request: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsReply.deserializeBinary
  );

  getSitePerformanceMetrics(
    request: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsReply>;

  getSitePerformanceMetrics(
    request: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsReply>;

  getSitePerformanceMetrics(
    request: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.GetSitePerformanceMetricsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetSitePerformanceMetrics',
        request,
        metadata || {},
        this.methodInfoGetSitePerformanceMetrics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetSitePerformanceMetrics',
    request,
    metadata || {},
    this.methodInfoGetSitePerformanceMetrics);
  }

  methodInfoCreateGoogleAdsAccount = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountReply.deserializeBinary
  );

  createGoogleAdsAccount(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountReply>;

  createGoogleAdsAccount(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountReply>;

  createGoogleAdsAccount(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsAccountReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/CreateGoogleAdsAccount',
        request,
        metadata || {},
        this.methodInfoCreateGoogleAdsAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/CreateGoogleAdsAccount',
    request,
    metadata || {},
    this.methodInfoCreateGoogleAdsAccount);
  }

  methodInfoCreateGoogleAdsCampaign = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignReply.deserializeBinary
  );

  createGoogleAdsCampaign(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignReply>;

  createGoogleAdsCampaign(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignReply>;

  createGoogleAdsCampaign(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.CreateGoogleAdsCampaignReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/CreateGoogleAdsCampaign',
        request,
        metadata || {},
        this.methodInfoCreateGoogleAdsCampaign,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/CreateGoogleAdsCampaign',
    request,
    metadata || {},
    this.methodInfoCreateGoogleAdsCampaign);
  }

  methodInfoQueryGoogleAdsCampaign = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignReply.deserializeBinary
  );

  queryGoogleAdsCampaign(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignReply>;

  queryGoogleAdsCampaign(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignReply>;

  queryGoogleAdsCampaign(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.QueryGoogleAdsCampaignReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/QueryGoogleAdsCampaign',
        request,
        metadata || {},
        this.methodInfoQueryGoogleAdsCampaign,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/QueryGoogleAdsCampaign',
    request,
    metadata || {},
    this.methodInfoQueryGoogleAdsCampaign);
  }

  methodInfoGetExecutedGoogleAdsActions = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetExecutedGoogleAdsActionsReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetExecutedGoogleAdsActionsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetExecutedGoogleAdsActionsReply.deserializeBinary
  );

  getExecutedGoogleAdsActions(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetExecutedGoogleAdsActionsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpcwebPb.Web/GetExecutedGoogleAdsActions',
      request,
      metadata || {},
      this.methodInfoGetExecutedGoogleAdsActions);
  }

  methodInfoGetCreatedGoogleAdsCampaigns = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetCreatedGoogleAdsCampaignsReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetCreatedGoogleAdsCampaignsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetCreatedGoogleAdsCampaignsReply.deserializeBinary
  );

  getCreatedGoogleAdsCampaigns(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetCreatedGoogleAdsCampaignsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpcwebPb.Web/GetCreatedGoogleAdsCampaigns',
      request,
      metadata || {},
      this.methodInfoGetCreatedGoogleAdsCampaigns);
  }

  methodInfoGoogleAdsAudit = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditReply.deserializeBinary
  );

  googleAdsAudit(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditReply>;

  googleAdsAudit(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditReply>;

  googleAdsAudit(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GoogleAdsAuditReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GoogleAdsAudit',
        request,
        metadata || {},
        this.methodInfoGoogleAdsAudit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GoogleAdsAudit',
    request,
    metadata || {},
    this.methodInfoGoogleAdsAudit);
  }

  methodInfoGetGoogleAdsChangeHistory = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryReply.deserializeBinary
  );

  getGoogleAdsChangeHistory(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryReply>;

  getGoogleAdsChangeHistory(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryReply>;

  getGoogleAdsChangeHistory(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsChangeHistoryReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetGoogleAdsChangeHistory',
        request,
        metadata || {},
        this.methodInfoGetGoogleAdsChangeHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetGoogleAdsChangeHistory',
    request,
    metadata || {},
    this.methodInfoGetGoogleAdsChangeHistory);
  }

  methodInfoGenerateGoogleAdsKeywordIdeas = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasReply.deserializeBinary
  );

  generateGoogleAdsKeywordIdeas(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasReply>;

  generateGoogleAdsKeywordIdeas(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasReply>;

  generateGoogleAdsKeywordIdeas(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GenerateGoogleAdsKeywordIdeasReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GenerateGoogleAdsKeywordIdeas',
        request,
        metadata || {},
        this.methodInfoGenerateGoogleAdsKeywordIdeas,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GenerateGoogleAdsKeywordIdeas',
    request,
    metadata || {},
    this.methodInfoGenerateGoogleAdsKeywordIdeas);
  }

  methodInfoGetGoogleAdsAdsWithAmazonAttribution = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionReply.deserializeBinary
  );

  getGoogleAdsAdsWithAmazonAttribution(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionReply>;

  getGoogleAdsAdsWithAmazonAttribution(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionReply>;

  getGoogleAdsAdsWithAmazonAttribution(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.GetGoogleAdsAdsWithAmazonAttributionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetGoogleAdsAdsWithAmazonAttribution',
        request,
        metadata || {},
        this.methodInfoGetGoogleAdsAdsWithAmazonAttribution,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetGoogleAdsAdsWithAmazonAttribution',
    request,
    metadata || {},
    this.methodInfoGetGoogleAdsAdsWithAmazonAttribution);
  }

  methodInfoUpdateGoogleRsa = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaReply,
    (request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaReply.deserializeBinary
  );

  updateGoogleRsa(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaReply>;

  updateGoogleRsa(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaReply>;

  updateGoogleRsa(
    request: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_GoogleAds_pb.UpdateGoogleRsaReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateGoogleRsa',
        request,
        metadata || {},
        this.methodInfoUpdateGoogleRsa,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateGoogleRsa',
    request,
    metadata || {},
    this.methodInfoUpdateGoogleRsa);
  }

  methodInfoCollectAmazonProductInfo = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoReply.deserializeBinary
  );

  collectAmazonProductInfo(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoReply>;

  collectAmazonProductInfo(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoReply>;

  collectAmazonProductInfo(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.CollectAmazonProductInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/CollectAmazonProductInfo',
        request,
        metadata || {},
        this.methodInfoCollectAmazonProductInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/CollectAmazonProductInfo',
    request,
    metadata || {},
    this.methodInfoCollectAmazonProductInfo);
  }

  methodInfoGetAmazonProduct = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductReply.deserializeBinary
  );

  getAmazonProduct(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductReply>;

  getAmazonProduct(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductReply>;

  getAmazonProduct(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonProductReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmazonProduct',
        request,
        metadata || {},
        this.methodInfoGetAmazonProduct,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmazonProduct',
    request,
    metadata || {},
    this.methodInfoGetAmazonProduct);
  }

  methodInfoGetAmazonSearchTermRanking = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingReply.deserializeBinary
  );

  getAmazonSearchTermRanking(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingReply>;

  getAmazonSearchTermRanking(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingReply>;

  getAmazonSearchTermRanking(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSearchTermRankingReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmazonSearchTermRanking',
        request,
        metadata || {},
        this.methodInfoGetAmazonSearchTermRanking,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmazonSearchTermRanking',
    request,
    metadata || {},
    this.methodInfoGetAmazonSearchTermRanking);
  }

  methodInfoGetAmazonKeywordSuggestions = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsReply.deserializeBinary
  );

  getAmazonKeywordSuggestions(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsReply>;

  getAmazonKeywordSuggestions(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsReply>;

  getAmazonKeywordSuggestions(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonKeywordSuggestionsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmazonKeywordSuggestions',
        request,
        metadata || {},
        this.methodInfoGetAmazonKeywordSuggestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmazonKeywordSuggestions',
    request,
    metadata || {},
    this.methodInfoGetAmazonKeywordSuggestions);
  }

  methodInfoGetAmazonSponsoredProducts = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsReply.deserializeBinary
  );

  getAmazonSponsoredProducts(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsReply>;

  getAmazonSponsoredProducts(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsReply>;

  getAmazonSponsoredProducts(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonSponsoredProductsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmazonSponsoredProducts',
        request,
        metadata || {},
        this.methodInfoGetAmazonSponsoredProducts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmazonSponsoredProducts',
    request,
    metadata || {},
    this.methodInfoGetAmazonSponsoredProducts);
  }

  methodInfoGetAmazonAdvertisingProfiles = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesReply.deserializeBinary
  );

  getAmazonAdvertisingProfiles(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesReply>;

  getAmazonAdvertisingProfiles(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesReply>;

  getAmazonAdvertisingProfiles(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAdvertisingProfilesReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmazonAdvertisingProfiles',
        request,
        metadata || {},
        this.methodInfoGetAmazonAdvertisingProfiles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmazonAdvertisingProfiles',
    request,
    metadata || {},
    this.methodInfoGetAmazonAdvertisingProfiles);
  }

  methodInfoGetAmazonAttributionTags = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsReply.deserializeBinary
  );

  getAmazonAttributionTags(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsReply>;

  getAmazonAttributionTags(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsReply>;

  getAmazonAttributionTags(
    request: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Amazon_pb.GetAmazonAttributionTagsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetAmazonAttributionTags',
        request,
        metadata || {},
        this.methodInfoGetAmazonAttributionTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetAmazonAttributionTags',
    request,
    metadata || {},
    this.methodInfoGetAmazonAttributionTags);
  }

  methodInfoGetFacebookMarketingResources = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesReply,
    (request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesReply.deserializeBinary
  );

  getFacebookMarketingResources(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesReply>;

  getFacebookMarketingResources(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesReply>;

  getFacebookMarketingResources(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookMarketingResourcesReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetFacebookMarketingResources',
        request,
        metadata || {},
        this.methodInfoGetFacebookMarketingResources,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetFacebookMarketingResources',
    request,
    metadata || {},
    this.methodInfoGetFacebookMarketingResources);
  }

  methodInfoUpdateFacebookAdAttribution = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionReply,
    (request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionReply.deserializeBinary
  );

  updateFacebookAdAttribution(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionReply>;

  updateFacebookAdAttribution(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionReply>;

  updateFacebookAdAttribution(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Facebook_pb.UpdateFacebookAdAttributionReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/UpdateFacebookAdAttribution',
        request,
        metadata || {},
        this.methodInfoUpdateFacebookAdAttribution,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/UpdateFacebookAdAttribution',
    request,
    metadata || {},
    this.methodInfoUpdateFacebookAdAttribution);
  }

  methodInfoGetFacebookAdPerformance = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceReply,
    (request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceReply.deserializeBinary
  );

  getFacebookAdPerformance(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceReply>;

  getFacebookAdPerformance(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceReply>;

  getFacebookAdPerformance(
    request: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Facebook_pb.GetFacebookAdPerformanceReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetFacebookAdPerformance',
        request,
        metadata || {},
        this.methodInfoGetFacebookAdPerformance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetFacebookAdPerformance',
    request,
    metadata || {},
    this.methodInfoGetFacebookAdPerformance);
  }

  methodInfoGetWalmartProduct = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductReply,
    (request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductReply.deserializeBinary
  );

  getWalmartProduct(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductReply>;

  getWalmartProduct(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductReply>;

  getWalmartProduct(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartProductReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetWalmartProduct',
        request,
        metadata || {},
        this.methodInfoGetWalmartProduct,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetWalmartProduct',
    request,
    metadata || {},
    this.methodInfoGetWalmartProduct);
  }

  methodInfoGetWalmartSearchPage = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageReply,
    (request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageReply.deserializeBinary
  );

  getWalmartSearchPage(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageReply>;

  getWalmartSearchPage(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageReply>;

  getWalmartSearchPage(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartSearchPageReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetWalmartSearchPage',
        request,
        metadata || {},
        this.methodInfoGetWalmartSearchPage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetWalmartSearchPage',
    request,
    metadata || {},
    this.methodInfoGetWalmartSearchPage);
  }

  methodInfoGetWalmartAttributionUrls = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsReply,
    (request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsRequest) => {
      return request.serializeBinary();
    },
    proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsReply.deserializeBinary
  );

  getWalmartAttributionUrls(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsReply>;

  getWalmartAttributionUrls(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsReply) => void): grpcWeb.ClientReadableStream<proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsReply>;

  getWalmartAttributionUrls(
    request: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_edge_grpcwebPb_grpcweb_Walmart_pb.GetWalmartAttributionUrlsReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpcwebPb.Web/GetWalmartAttributionUrls',
        request,
        metadata || {},
        this.methodInfoGetWalmartAttributionUrls,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpcwebPb.Web/GetWalmartAttributionUrls',
    request,
    metadata || {},
    this.methodInfoGetWalmartAttributionUrls);
  }

}


// source: proto/edge/grpcwebPb/grpcweb_Facebook.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_facebook_pb = require('../../../proto/common/facebook_pb.js');
goog.object.extend(proto, proto_common_facebook_pb);
var proto_common_datetime_pb = require('../../../proto/common/datetime_pb.js');
goog.object.extend(proto, proto_common_datetime_pb);
var proto_common_amount_pb = require('../../../proto/common/amount_pb.js');
goog.object.extend(proto, proto_common_amount_pb);
var proto_common_retailer_pb = require('../../../proto/common/retailer_pb.js');
goog.object.extend(proto, proto_common_retailer_pb);
goog.exportSymbol('proto.grpcwebPb.GetFacebookAdPerformanceReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay', null, global);
goog.exportSymbol('proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay', null, global);
goog.exportSymbol('proto.grpcwebPb.GetFacebookAdPerformanceRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetFacebookMarketingResourcesReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetFacebookMarketingResourcesRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateFacebookAdAttributionReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateFacebookAdAttributionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetFacebookMarketingResourcesRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetFacebookMarketingResourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetFacebookMarketingResourcesRequest.displayName = 'proto.grpcwebPb.GetFacebookMarketingResourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetFacebookMarketingResourcesReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetFacebookMarketingResourcesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetFacebookMarketingResourcesReply.displayName = 'proto.grpcwebPb.GetFacebookMarketingResourcesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateFacebookAdAttributionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateFacebookAdAttributionRequest.displayName = 'proto.grpcwebPb.UpdateFacebookAdAttributionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateFacebookAdAttributionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateFacebookAdAttributionReply.displayName = 'proto.grpcwebPb.UpdateFacebookAdAttributionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetFacebookAdPerformanceRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetFacebookAdPerformanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetFacebookAdPerformanceRequest.displayName = 'proto.grpcwebPb.GetFacebookAdPerformanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetFacebookAdPerformanceReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetFacebookAdPerformanceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetFacebookAdPerformanceReply.displayName = 'proto.grpcwebPb.GetFacebookAdPerformanceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.displayName = 'proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.displayName = 'proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetFacebookMarketingResourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetFacebookMarketingResourcesRequest;
  return proto.grpcwebPb.GetFacebookMarketingResourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetFacebookMarketingResourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string fields = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesRequest} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetFacebookMarketingResourcesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    adAccountsList: jspb.Message.toObjectList(msg.getAdAccountsList(),
    proto_common_facebook_pb.Facebook.API.AdAccount.toObject, includeInstance),
    campaignsList: jspb.Message.toObjectList(msg.getCampaignsList(),
    proto_common_facebook_pb.Facebook.API.Campaign.toObject, includeInstance),
    adSetsList: jspb.Message.toObjectList(msg.getAdSetsList(),
    proto_common_facebook_pb.Facebook.API.AdSet.toObject, includeInstance),
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto_common_facebook_pb.Facebook.API.Ad.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetFacebookMarketingResourcesReply;
  return proto.grpcwebPb.GetFacebookMarketingResourcesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_facebook_pb.Facebook.API.AdAccount;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.API.AdAccount.deserializeBinaryFromReader);
      msg.addAdAccounts(value);
      break;
    case 2:
      var value = new proto_common_facebook_pb.Facebook.API.Campaign;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.API.Campaign.deserializeBinaryFromReader);
      msg.addCampaigns(value);
      break;
    case 3:
      var value = new proto_common_facebook_pb.Facebook.API.AdSet;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.API.AdSet.deserializeBinaryFromReader);
      msg.addAdSets(value);
      break;
    case 4:
      var value = new proto_common_facebook_pb.Facebook.API.Ad;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.API.Ad.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetFacebookMarketingResourcesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_common_facebook_pb.Facebook.API.AdAccount.serializeBinaryToWriter
    );
  }
  f = message.getCampaignsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_common_facebook_pb.Facebook.API.Campaign.serializeBinaryToWriter
    );
  }
  f = message.getAdSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_common_facebook_pb.Facebook.API.AdSet.serializeBinaryToWriter
    );
  }
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_common_facebook_pb.Facebook.API.Ad.serializeBinaryToWriter
    );
  }
};


/**
 * repeated common.Facebook.API.AdAccount ad_accounts = 1;
 * @return {!Array<!proto.common.Facebook.API.AdAccount>}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.getAdAccountsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_facebook_pb.Facebook.API.AdAccount, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdAccount>} value
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
*/
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.setAdAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Facebook.API.AdAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdAccount}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.addAdAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Facebook.API.AdAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.clearAdAccountsList = function() {
  return this.setAdAccountsList([]);
};


/**
 * repeated common.Facebook.API.Campaign campaigns = 2;
 * @return {!Array<!proto.common.Facebook.API.Campaign>}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.getCampaignsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.Campaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_facebook_pb.Facebook.API.Campaign, 2));
};


/**
 * @param {!Array<!proto.common.Facebook.API.Campaign>} value
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
*/
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.setCampaignsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Facebook.API.Campaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.Campaign}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.addCampaigns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Facebook.API.Campaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.clearCampaignsList = function() {
  return this.setCampaignsList([]);
};


/**
 * repeated common.Facebook.API.AdSet ad_sets = 3;
 * @return {!Array<!proto.common.Facebook.API.AdSet>}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.getAdSetsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_facebook_pb.Facebook.API.AdSet, 3));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdSet>} value
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
*/
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.setAdSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Facebook.API.AdSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdSet}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.addAdSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Facebook.API.AdSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.clearAdSetsList = function() {
  return this.setAdSetsList([]);
};


/**
 * repeated common.Facebook.API.Ad ads = 4;
 * @return {!Array<!proto.common.Facebook.API.Ad>}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.Ad>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_facebook_pb.Facebook.API.Ad, 4));
};


/**
 * @param {!Array<!proto.common.Facebook.API.Ad>} value
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
*/
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.Facebook.API.Ad=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.Ad}
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.Facebook.API.Ad, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookMarketingResourcesReply} returns this
 */
proto.grpcwebPb.GetFacebookMarketingResourcesReply.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateFacebookAdAttributionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    retailer: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amazonAttributionAdvertiserId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateFacebookAdAttributionRequest;
  return proto.grpcwebPb.UpdateFacebookAdAttributionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 4:
      var value = /** @type {!proto.common.Retailer.Option} */ (reader.readEnum());
      msg.setRetailer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonAttributionAdvertiserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateFacebookAdAttributionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRetailer();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAmazonAttributionAdvertiserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} returns this
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} returns this
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Retailer.Option retailer = 4;
 * @return {!proto.common.Retailer.Option}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.getRetailer = function() {
  return /** @type {!proto.common.Retailer.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.Retailer.Option} value
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} returns this
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.setRetailer = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string amazon_attribution_advertiser_id = 5;
 * @return {string}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.getAmazonAttributionAdvertiserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionRequest} returns this
 */
proto.grpcwebPb.UpdateFacebookAdAttributionRequest.prototype.setAmazonAttributionAdvertiserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateFacebookAdAttributionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateFacebookAdAttributionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionReply}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateFacebookAdAttributionReply;
  return proto.grpcwebPb.UpdateFacebookAdAttributionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateFacebookAdAttributionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateFacebookAdAttributionReply}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateFacebookAdAttributionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateFacebookAdAttributionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateFacebookAdAttributionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetFacebookAdPerformanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetFacebookAdPerformanceRequest;
  return proto.grpcwebPb.GetFacebookAdPerformanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdIds(value);
      break;
    case 3:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetFacebookAdPerformanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string ad_ids = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.getAdIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.setAdIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.addAdIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.clearAdIdsList = function() {
  return this.setAdIdsList([]);
};


/**
 * optional common.DateRangeV2 date_range = 3;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 3));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} returns this
*/
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceRequest} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetFacebookAdPerformanceRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetFacebookAdPerformanceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    dailyPerformanceList: jspb.Message.toObjectList(msg.getDailyPerformanceList(),
    proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.toObject, includeInstance),
    dailyProductList: jspb.Message.toObjectList(msg.getDailyProductList(),
    proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetFacebookAdPerformanceReply;
  return proto.grpcwebPb.GetFacebookAdPerformanceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay;
      reader.readMessage(value,proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.deserializeBinaryFromReader);
      msg.addDailyPerformance(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay;
      reader.readMessage(value,proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.deserializeBinaryFromReader);
      msg.addDailyProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetFacebookAdPerformanceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDailyPerformanceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.serializeBinaryToWriter
    );
  }
  f = message.getDailyProductList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && proto_common_datetime_pb.Date.toObject(includeInstance, f),
    adId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adSetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clicks: jspb.Message.getFieldWithDefault(msg, 5, 0),
    impressions: jspb.Message.getFieldWithDefault(msg, 6, 0),
    spend: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    conversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    conversionValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    unitsSold: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    addToCartClicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    detailPageViewClicks: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    clickThroughs: jspb.Message.getFieldWithDefault(msg, 13, 0),
    brandReferralBonus: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay;
  return proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.Date;
      reader.readMessage(value,proto_common_datetime_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdSetId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClicks(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImpressions(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpend(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConversions(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConversionValue(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitsSold(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAddToCartClicks(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDetailPageViewClicks(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClickThroughs(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBrandReferralBonus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdSetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClicks();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getImpressions();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSpend();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getConversions();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getConversionValue();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getUnitsSold();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getAddToCartClicks();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getDetailPageViewClicks();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getClickThroughs();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getBrandReferralBonus();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
};


/**
 * optional common.Date date = 1;
 * @return {?proto.common.Date}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getDate = function() {
  return /** @type{?proto.common.Date} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.Date, 1));
};


/**
 * @param {?proto.common.Date|undefined} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
*/
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_set_id = 3;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getAdSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setAdSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string campaign_id = 4;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 clicks = 5;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getClicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setClicks = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 impressions = 6;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getImpressions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setImpressions = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float spend = 7;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getSpend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setSpend = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float conversions = 8;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float conversion_value = 9;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getConversionValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setConversionValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float units_sold = 10;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getUnitsSold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setUnitsSold = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float add_to_cart_clicks = 11;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getAddToCartClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setAddToCartClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float detail_page_view_clicks = 12;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getDetailPageViewClicks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setDetailPageViewClicks = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int64 click_throughs = 13;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getClickThroughs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setClickThroughs = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional float brand_referral_bonus = 14;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.getBrandReferralBonus = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay.prototype.setBrandReferralBonus = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && proto_common_datetime_pb.Date.toObject(includeInstance, f),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adSetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    asin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    newToBrandConversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    newToBrandConversionValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    newToBrandUnitsSold: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay;
  return proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.Date;
      reader.readMessage(value,proto_common_datetime_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdSetId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNewToBrandConversions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNewToBrandConversionValue(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNewToBrandUnitsSold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdSetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNewToBrandConversions();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getNewToBrandConversionValue();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getNewToBrandUnitsSold();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional common.Date date = 1;
 * @return {?proto.common.Date}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getDate = function() {
  return /** @type{?proto.common.Date} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.Date, 1));
};


/**
 * @param {?proto.common.Date|undefined} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
*/
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string campaign_id = 2;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_set_id = 3;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getAdSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setAdSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string asin = 4;
 * @return {string}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float new_to_brand_conversions = 5;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getNewToBrandConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setNewToBrandConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float new_to_brand_conversion_value = 6;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getNewToBrandConversionValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setNewToBrandConversionValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float new_to_brand_units_sold = 7;
 * @return {number}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.getNewToBrandUnitsSold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay.prototype.setNewToBrandUnitsSold = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated AdPerformanceDay daily_performance = 1;
 * @return {!Array<!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay>}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.getDailyPerformanceList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay>} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply} returns this
*/
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.setDailyPerformanceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.addDailyPerformance = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.GetFacebookAdPerformanceReply.AdPerformanceDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.clearDailyPerformanceList = function() {
  return this.setDailyPerformanceList([]);
};


/**
 * repeated ProductPerformanceDay daily_product = 2;
 * @return {!Array<!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay>}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.getDailyProductList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay, 2));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay>} value
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply} returns this
*/
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.setDailyProductList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay}
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.addDailyProduct = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpcwebPb.GetFacebookAdPerformanceReply.ProductPerformanceDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetFacebookAdPerformanceReply} returns this
 */
proto.grpcwebPb.GetFacebookAdPerformanceReply.prototype.clearDailyProductList = function() {
  return this.setDailyProductList([]);
};


goog.object.extend(exports, proto.grpcwebPb);

import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Icon, Message, Popup, Table } from "semantic-ui-react";
import styled from "styled-components";

import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { SORT_NONE } from "./MetricColumns";
import {
  tableFreezeLeftHeader,
  tableHeader
} from "ExtensionV2/styles/zIndexes";
import {
  ampdRed,
  backgroundDark,
  backgroundMedium
} from "ExtensionV2/styles/colors";
import { useColumnSort } from "./table/useColumnSort";

export const SEMANTIC_DARK_ACCENT = "rgb(247, 249, 250)";

// Compares two values according to the specified sort direction.  When used to
// sort, Null and empty values are pushed to the end regardless of direction.
// Also, string values are compared case insensitively.
export const defaultValueSortComparator = (aValue, bValue, sortAscending) => {
  if (aValue === bValue) {
    return 0;
  }

  if (aValue == null || aValue === "") {
    return 1;
  }

  if (bValue == null || bValue === "") {
    return -1;
  }

  // order case insensitive for strings
  if (_.isString(aValue) && _.isString(bValue)) {
    if (aValue.toLowerCase() < bValue.toLowerCase()) {
      return sortAscending ? -1 : 1;
    }

    return sortAscending ? 1 : -1;
  }

  if (aValue < bValue) {
    return sortAscending ? -1 : 1;
  }

  return sortAscending ? 1 : -1;
};

// Compares two objects based on the (raw or surface) value found at the specified
// column, according to the specified sort direction.
export const defaultSortComparator = (a, b, column, sortAscending) => {
  const aValue = a[column]?.rawValue ?? a[column];
  const bValue = b[column]?.rawValue ?? b[column];

  return defaultValueSortComparator(aValue, bValue, sortAscending);
};

export const DataTableHeader = styled(Table.Header)`
  z-index: ${tableHeader + 2};
  position: sticky;
  top: 0;
`;

export const DataTableFooter = styled(Table.Footer)`
  position: sticky;
  bottom: 0;
  z-index: ${tableHeader};
  &&& th {
    font-weight: bold;
  }
`;

// eslint-disable-next-line no-unused-vars
export const DataTableHeaderCell = styled(({ freezeLeft, ...rest }) => (
  <Table.HeaderCell {...rest} />
))`
  border-top: 1px solid ${backgroundDark};
  border-left: 1px solid ${backgroundDark} !important;
  z-index: ${tableHeader};
  @media (min-width: 60em) {
    ${props =>
      props.freezeLeft &&
      `position: sticky;
    left: ${props.freezeLeft};
    z-index: ${tableFreezeLeftHeader};`}
  }

  div:first-child {
    display: flex;
    flex-direction: row;
    font-size: small;
    justify-content: center;
  }
`;

export const SortingIcon = styled.div`
  visibility: ${props => (props.isSelected ? "inherit" : "hidden")};
  display: inline;
  padding-left: 0.3em;
  align-self: center;
  div:hover > & {
    visibility: inherit;
  }
`;

export const DataTableRowCell = styled.td`
  &&& {
    background-color: ${props =>
      props.rowIndex % 2 === 0 ? "white" : SEMANTIC_DARK_ACCENT};
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.3em;
    height: 2.7em;
  }
`;

export const DataTableMetricCell = styled(DataTableRowCell)`
  &&& {
    text-align: right;
  }
`;

export const DataTableFreezeLeftCell = styled(DataTableRowCell)`
  cursor: pointer;
  @media (min-width: 60em) {
    position: sticky;
    left: 0;
    z-index: ${tableHeader - 1};
  }
  margin-left: 1em;
  padding-right: 0;
  border-right: 1px solid ${backgroundDark};
  border-left: 1px solid ${backgroundDark} !important;
`;

export const DataTableRow = styled(
  // eslint-disable-next-line no-unused-vars
  ({ isLastKeywordRow, keywordsOpen, isSelected, ...rest }) => (
    <Table.Row {...rest} />
  )
)`
  padding-top: 0;
  padding-bottom: 0;
  &&& ${DataTableRowCell} {
    ${props =>
      props.isLastKeywordRow || (!props.keywordsOpen && props.isSelected)
        ? `border-bottom: 1px solid ${ampdRed}`
        : null};
  }

  &:hover {
    &&& td${DataTableRowCell} {
      background-color: ${backgroundDark} !important;
    }
  }
`;

export const SelectableDataTableRow = styled(DataTableRow)`
  &&& ${DataTableRowCell} {
    border-top: ${props =>
      props.keywordsOpen || props.isSelected ? `1px solid ${ampdRed}` : ""};

    border-bottom: ${props =>
      (props.isSelected && !props.keywordsOpen) || props.isLastKeywordRow
        ? `1px solid ${ampdRed}`
        : ""};

    background-color: ${props => (props.isSelected ? backgroundDark : "")};
  }
`;

export const SelectedAdditionalDataTableRow = styled(DataTableRow)`
  &&& td${DataTableRowCell} {
    border-bottom: 1px solid ${ampdRed};
    background-color: ${backgroundMedium} !important;
  }
  &&&:hover td${DataTableRowCell}${DataTableRowCell}${DataTableRowCell} {
    background-color: ${backgroundMedium} !important;
  }
`;

export const DataTable = styled(Table)`
  &&& {
    border-collapse: separate;
    ${DataTableHeaderCell}:first-child {
      div:first-child {
        justify-content: flex-start;
      }
    }
  }
`;

const defaultRowToComponentMapper = (data, columns, dataRowIndex) => {
  return (
    <tr key={dataRowIndex}>
      {columns.map((column, i) => (
        <td key={i}>{`${data[column] ?? "-"}`}</td>
      ))}
    </tr>
  );
};

const HeaderCell = ({
  handleColumnClick,
  freezeLeft,
  isSortable,
  isAscending,
  isSelected,
  name,
  subtitle,
  title,
  tooltip,
  width,
  minWidth
}) => {
  const contextRef = useRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const Header = React.forwardRef(
    (
      {
        handleColumnClick,
        isAscending,
        isSelected,
        name,
        subtitle,
        tooltip,
        freezeLeft
      },
      r
    ) => {
      return (
        <DataTableHeaderCell
          style={{ width: width, minWidth: minWidth }}
          name={name}
          selected={isSelected}
          onClick={isSortable ? handleColumnClick : undefined}
          freezeLeft={freezeLeft}
        >
          <div
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          >
            <div
              ref={r}
              style={{ borderBottom: tooltip ? "1px gray dashed" : "" }}
            >
              {title}
            </div>
            {isSortable && (
              <SortingIcon isSelected={isSelected}>
                {!isSelected ? (
                  <Icon name="sort" size="small" />
                ) : isAscending ? (
                  <Icon name="angle up" size="small" />
                ) : (
                  <Icon name="angle down" size="small" />
                )}
              </SortingIcon>
            )}
          </div>
          <p style={{ fontSize: "x-small" }}>{subtitle}</p>
        </DataTableHeaderCell>
      );
    }
  );

  return (
    <Popup
      style={{ padding: 10 }}
      animation="none"
      trigger={
        <Header
          handleColumnClick={handleColumnClick}
          isAscending={isAscending}
          isSelected={isSelected}
          name={name}
          subtitle={subtitle}
          tooltip={tooltip}
          ref={contextRef}
          title={title}
          freezeLeft={freezeLeft}
        />
      }
      position={"bottom left"}
      context={contextRef}
      disabled={!tooltip}
      open={tooltipOpen}
    >
      <strong>
        <small>{tooltip}</small>
      </strong>
    </Popup>
  );
};

/*
  columnDataNames: [String] The names of the columns (in order.) This is the name of the data
    attribute from each dataRow that should be placed in the column. If you want a different value
    displayed as the column header use columnDisplayNamesMap.
  
  columnDisplayNamesMap: {String: String} maps a column data name to a user facing display name

  dataRows: [[Any]] A 2D array with each inner array representing a table row.

  mapDataRowToComponent: (dataRows, columnNames, dataRowIndex) => React.Component<tr>  A function used to map
    the data and columns to a table row component.
*/
const AmpdDataTable = ({
  columnDataNames,
  columnDisplayNamesMap = {},
  columnDisplaySubtitlesMap = {},
  columnDisplayWidthsMap = {},
  columnDisplayMinWidthsMap = {},
  columnTooltipMap = {},
  dataRows,
  defaultSortColumn = columnDataNames[0],
  defaultSortDirections = {},
  emptyContent = <></>,
  errorMessage = "",
  freezeColumnsMap = {},
  isLoading = false,
  mapDataRowToComponent = defaultRowToComponentMapper,
  totalData,
  mapTotalDataToComponent,
  onSort = (_col, _dir) => {},
  sortComparator = defaultSortComparator,
  maxRowsToDisplay = -1,
  compact
}) => {
  const { sortColumn, sortIsAscending, handleSortColumn } = useColumnSort(
    defaultSortColumn,
    defaultSortDirections
  );

  const sortedDataRows = useMemo(() => {
    if (!dataRows) {
      return [];
    }

    const sorted = [...dataRows].sort((a, b) =>
      sortComparator(a, b, sortColumn, sortIsAscending)
    );

    return sorted;
  }, [dataRows, sortColumn, sortIsAscending, sortComparator]);

  const componentRows = useMemo(() => {
    if (!_.isFunction(mapDataRowToComponent)) {
      return sortedDataRows;
    }

    let rowIndex = 0;
    const componentRows = sortedDataRows
      .map((row, index) => {
        if (maxRowsToDisplay >= 0 && index >= maxRowsToDisplay) {
          return null;
        }

        const component = mapDataRowToComponent(row, columnDataNames, rowIndex);
        if (component) {
          rowIndex += 1;
        }
        return component;
      })
      .filter(Boolean);

    return componentRows;
  }, [
    columnDataNames,
    mapDataRowToComponent,
    sortedDataRows,
    maxRowsToDisplay
  ]);

  const AmpdDataTableFooter = useMemo(() => {
    if (!totalData || !mapTotalDataToComponent) {
      return null;
    }

    const totalRows = mapTotalDataToComponent(
      totalData,
      columnDataNames,
      componentRows.length
    );

    return <DataTableFooter>{totalRows}</DataTableFooter>;
  }, [columnDataNames, componentRows, totalData, mapTotalDataToComponent]);

  const handleColumnClick = (_ev, clickedColumn) => {
    const isSortAscending = handleSortColumn(clickedColumn);
    if (onSort) {
      onSort(clickedColumn, isSortAscending);
    }
  };

  const AmpdDataTableContent =
    emptyContent && dataRows?.length === 0 ? (
      <tr>
        <td colSpan={columnDataNames.length}>{emptyContent}</td>
      </tr>
    ) : (
      componentRows
    );

  return (
    <DataTable celled unstackable compact={compact}>
      <DataTableHeader>
        {!isLoading && (
          <Table.Row>
            {columnDataNames.map(columnName => {
              return (
                <HeaderCell
                  handleColumnClick={ev => handleColumnClick(ev, columnName)}
                  isSortable={defaultSortDirections[columnName] !== SORT_NONE}
                  isAscending={sortIsAscending}
                  isSelected={sortColumn === columnName}
                  key={columnName}
                  name={columnName}
                  title={columnDisplayNamesMap[columnName]}
                  subtitle={columnDisplaySubtitlesMap[columnName]}
                  tooltip={columnTooltipMap[columnName]}
                  freezeLeft={freezeColumnsMap[columnName]}
                  width={columnDisplayWidthsMap[columnName]}
                  minWidth={columnDisplayMinWidthsMap[columnName]}
                />
              );
            })}
          </Table.Row>
        )}
      </DataTableHeader>

      <Table.Body>
        {isLoading ? (
          <tr>
            <td colSpan={columnDataNames.length} style={{ height: "50vh" }}>
              <LoadingSpinner>
                <p>Loading ...</p>
              </LoadingSpinner>
            </td>
          </tr>
        ) : null}
        {!isLoading && errorMessage ? (
          <tr>
            <td colSpan={columnDataNames.length} style={{ height: "50vh" }}>
              <Message negative>
                <Message.Header>
                  There was a problem loading your data.
                </Message.Header>
                <p>{errorMessage}</p>
              </Message>
            </td>
          </tr>
        ) : null}
        {!isLoading && !errorMessage && AmpdDataTableContent}
      </Table.Body>
      {!isLoading && !errorMessage && AmpdDataTableFooter}
    </DataTable>
  );
};

export default AmpdDataTable;

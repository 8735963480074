import { extractErrorMessage } from "Common/errors/error";
import {
  adTrackingStatus,
  CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS
} from "Common/utils/facebook";
import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import {
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell
} from "ExtensionV2/components/AmpdDataTable";
import {
  AmpdDataTableColumn,
  AmpdDataTableSettings,
  AmpdDataTableTsWrapper,
  TAmpdDataTable
} from "ExtensionV2/components/AmpdDataTableTsWrapper";
import {
  fractionalPercentageMetricDef,
  roundedNumberMetricDef,
  roundedPercentageMetricDef
} from "ExtensionV2/components/MetricColumns";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { useFacebookMarketingResources } from "ExtensionV2/queries/useFacebookMarketingResources";
import {
  FacebookPerformanceRollup,
  FB_DATA_ROLLUP_LEVELS,
  useFacebookPerformanceData,
  usePerformanceDataRollupByLevel
} from "ExtensionV2/queries/useFacebookPerformanceData";
import React, { useMemo } from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";

type FacebookPerformanceTableData = FacebookPerformanceRollup & {
  name: string;
  status: string;
  id: string;
};

export const defaultFacebookColumns: Array<keyof FacebookPerformanceTableData> = [
  "name",
  "status",
  "spend",
  "impressions",
  "facebookClicks",
  "facebookCostPerClick",
  "detailPageViews",
  "addToCarts",
  "conversions"
];

export const facebookPerformanceColumns: Array<AmpdDataTableColumn<
  FacebookPerformanceTableData
>> = [
  { name: "name", displayName: "Name", freeze: "0px", displayMinWidth: 400 },
  { name: "status", displayName: "Status" },
  { name: "spend", displayName: "Cost" },
  { name: "impressions", displayName: "Impressions" },
  { name: "costPerMille", displayName: "CPM" },
  { name: "facebookClicks", displayName: "Meta Clicks" },
  { name: "facebookCostPerClick", displayName: "Cost Per Meta Click" },
  { name: "facebookClickRate", displayName: "Meta Click Through Rate" },
  // TODO: update display to reflect to handle Walmart
  { name: "marketplaceClicks", displayName: "Amazon Clicks" },
  { name: "marketplaceClickRate", displayName: "Amazon Click Through Rate" },
  { name: "marketplaceCostPerClick", displayName: "Cost Per Amazon Click" },
  { name: "detailPageViews", displayName: "Detail Page Views" },
  { name: "detailPageViewRate", displayName: "Detail Page View Rate" },
  { name: "costPerDetailPageView", displayName: "Cost Per Detail Page View" },
  { name: "addToCarts", displayName: "Carts" },
  { name: "addToCartRate", displayName: "Cart Rate" },
  { name: "ntbConversionRate", displayName: "% of Conversions NTB" },
  { name: "ntbUnitsSoldRate", displayName: "% of Units Sold NTB" },
  { name: "ntbRevenueRate", displayName: "% of Revenue NTB" },
  { name: "ntbROAS", displayName: "NTB ROAS" },
  { name: "conversions", displayName: "Conversions" },
  { name: "unitsSold", displayName: "Units Sold" },
  { name: "conversionValue", displayName: "Revenue" },
  { name: "brandReferralBonus", displayName: "Brand Referral Bonus" },
  { name: "roas", displayName: "ROAS" }
];

const ResourceNameCell = styled(DataTableFreezeLeftCell)<{
  rowIndex?: number;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 35em;
`;

// This wrapper is just so we can get make the TS compiler happy since
// DataTableMetricCell is defined in a JS file and JSDOC comments don't seem to
// work on styled components
const FbDataTableMetricCell = styled(DataTableMetricCell)<{
  rowIndex?: number;
}>``;

const FbDataTableRowCell = styled(DataTableRowCell)<{
  rowIndex?: number;
}>``;

function mapPerformanceDataToComponent(
  showFractions: boolean,
  metaCurrency: string
) {
  const rateFormatter = showFractions
    ? fractionalPercentageMetricDef
    : roundedPercentageMetricDef;

  const currencyFormatter = getCurrencyMetricDef(metaCurrency, showFractions);

  return (
    performanceData: FacebookPerformanceTableData,
    columns: Array<keyof FacebookPerformanceTableData>,
    i: number
  ): JSX.Element => {
    return (
      <Table.Row key={performanceData.id}>
        {columns.map(column => {
          switch (column) {
            case "name":
              return (
                <SimpleTooltip key={column} tooltip={performanceData[column]}>
                  <ResourceNameCell key={column} rowIndex={i}>
                    {performanceData[column]}
                  </ResourceNameCell>
                </SimpleTooltip>
              );
            case "marketplaceClickRate":
            case "addToCartRate":
            case "detailPageViewRate":
            case "facebookClickRate":
            case "ntbConversionRate":
            case "ntbUnitsSoldRate":
            case "ntbRevenueRate":
            case "ntbROAS":
            case "roas":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(rateFormatter, performanceData[column])}
                </FbDataTableMetricCell>
              );
            // cost per metrics always show fractions of a currency
            case "marketplaceCostPerClick":
            case "costPerAddToCart":
            case "costPerDetailPageView":
            case "costPerMille":
            case "facebookCostPerClick":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(
                    getCurrencyMetricDef(metaCurrency, true),
                    performanceData[column]
                  )}
                </FbDataTableMetricCell>
              );
            // user selects if they want to see fractions of a currency
            case "conversionValue":
            case "brandReferralBonus":
            case "spend":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(currencyFormatter, performanceData[column])}
                </FbDataTableMetricCell>
              );
            case "addToCarts":
            case "marketplaceClicks":
            case "conversions":
            case "detailPageViews":
            case "impressions":
            case "facebookClicks":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(
                    roundedNumberMetricDef,
                    performanceData[column]
                  )}
                </FbDataTableMetricCell>
              );
            default:
              return (
                <FbDataTableRowCell key={column} rowIndex={i}>
                  {performanceData[column]}
                </FbDataTableRowCell>
              );
          }
        })}
      </Table.Row>
    );
  };
}

export function FacebookAdsPerformanceTable({
  startDate,
  endDate,
  rollupLevel,
  selectedColumns,
  showFractions
}: {
  startDate: string;
  endDate: string;
  rollupLevel: FB_DATA_ROLLUP_LEVELS;
  selectedColumns: Array<keyof FacebookPerformanceTableData>;
  showFractions: boolean;
}): JSX.Element {
  const {
    rowData,
    isLoading: rowsLoading,
    error: rowsError
  } = useFacebookPerformanceRowData(startDate, endDate, rollupLevel);

  const tableSettings: AmpdDataTableSettings<FacebookPerformanceTableData> = {
    defaultSortColumn: "name",
    emptyContent: <div>No data available</div>,
    compact: "very",
    // TODO: fetch the correct currency from Facebook, handle multiple currencies
    mapDataRowToComponent: mapPerformanceDataToComponent(showFractions, "USD")
  };

  const columns = useMemo(() => {
    return selectedColumns.flatMap(column => {
      const found = facebookPerformanceColumns.find(c => c.name === column);
      return found ? [found] : [];
    });
  }, [selectedColumns]);

  const tableConfig: TAmpdDataTable<FacebookPerformanceTableData> = {
    columns,
    rows: rowData,
    settings: tableSettings
  };

  return (
    <AmpdDataTableTsWrapper
      isLoading={rowsLoading}
      error={extractErrorMessage(rowsError)}
      tableConfig={tableConfig}
    />
  );
}

// Fetch the daily performance data, roll it up by the selected level, and
// and combine it with the resources meta data to create the table rows.
function useFacebookPerformanceRowData(
  startDate: string,
  endDate: string,
  rollupLevel: FB_DATA_ROLLUP_LEVELS
): {
  rowData: Array<FacebookPerformanceTableData>;
  isLoading: boolean;
  error: string | undefined;
} {
  // fetch the resources meta data (names, targets, budgets, etc.)
  const {
    data: resourcesMetaData,
    isLoading: resourcesMetaDataLoading,
    error: resourcesMetaDataError
  } = useFacebookMarketingResources({
    enabled: true,
    fields: CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS
  });

  // We need a list of IDs to query performance metrics from Facebook. We need
  // a list of ad/adSet/campaign IDs so we can fill in any rows that don't have
  // performance data
  const ampdResources = useMemo((): {
    adIds: Array<string>;
    selectedRollupIds: Array<string>;
  } => {
    if (!resourcesMetaData) {
      return {
        adIds: [],
        selectedRollupIds: []
      };
    }

    const ampdAds = resourcesMetaData?.adsList.filter(ad => {
      const {
        hasAmazonAttributionTags,
        hasWalmartAttributionTags
      } = adTrackingStatus(ad);
      return hasAmazonAttributionTags || hasWalmartAttributionTags;
    });

    const ampdAdIds = ampdAds.map(ad => ad.id);

    if (rollupLevel === "adId") {
      return { adIds: ampdAdIds, selectedRollupIds: ampdAdIds };
    } else {
      return {
        adIds: ampdAdIds,
        selectedRollupIds: ampdAds.map(ad => ad[rollupLevel])
      };
    }
  }, [resourcesMetaData, rollupLevel]);

  // request daily performance data for the Ampd ads
  const {
    data: performanceData,
    isLoading: performanceDataLoading,
    error: performanceDataError
  } = useFacebookPerformanceData(ampdResources.adIds, startDate, endDate);

  // rollup the daily stats by resource level (ad/adSet/campaign)
  const rollupPerformanceData = usePerformanceDataRollupByLevel(
    performanceData,
    rollupLevel,
    ampdResources.selectedRollupIds
  );

  // combine the resources meta data with the performance data
  const adRows: Array<FacebookPerformanceTableData> = useMemo(() => {
    if (!resourcesMetaData || !performanceData) {
      return [];
    }

    const rows: Array<FacebookPerformanceTableData> = [];
    for (const [adId, performance] of Object.entries(rollupPerformanceData)) {
      const ad = resourcesMetaData.adsList.find(ad => ad.id === adId);

      if (!ad) {
        continue;
      }

      rows.push({
        ...performance,
        id: adId,
        name: ad.name,
        status: ad.effectiveStatus
      });
    }

    return rows;
  }, [performanceData, resourcesMetaData, rollupPerformanceData]);

  const adSetRows: Array<FacebookPerformanceTableData> = useMemo(() => {
    if (!resourcesMetaData || !performanceData) {
      return [];
    }

    const rows: Array<FacebookPerformanceTableData> = [];
    for (const [adSetId, performance] of Object.entries(
      rollupPerformanceData
    )) {
      const adSet = resourcesMetaData.adSetsList.find(
        adSet => adSet.id === adSetId
      );

      if (!adSet) {
        continue;
      }

      rows.push({
        ...performance,
        id: adSetId,
        name: adSet.name,
        status: adSet.effectiveStatus
      });
    }

    return rows;
  }, [performanceData, resourcesMetaData, rollupPerformanceData]);

  const campaignRows: Array<FacebookPerformanceTableData> = useMemo(() => {
    if (!resourcesMetaData || !performanceData) {
      return [];
    }

    const rows: Array<FacebookPerformanceTableData> = [];
    for (const [campaignId, performance] of Object.entries(
      rollupPerformanceData
    )) {
      const campaign = resourcesMetaData.campaignsList.find(
        campaign => campaign.id === campaignId
      );

      if (!campaign) {
        continue;
      }

      rows.push({
        ...performance,
        id: campaignId,
        name: campaign.name,
        status: campaign.effectiveStatus
      });
    }

    return rows;
  }, [performanceData, resourcesMetaData, rollupPerformanceData]);

  let rowData: Array<FacebookPerformanceTableData> = [];
  if (rollupLevel === "campaignId") {
    rowData = campaignRows;
  } else if (rollupLevel === "adSetId") {
    rowData = adSetRows;
  } else {
    rowData = adRows;
  }

  return {
    rowData,
    isLoading: resourcesMetaDataLoading || performanceDataLoading,
    error:
      extractErrorMessage(resourcesMetaDataError) ||
      extractErrorMessage(performanceDataError)
  };
}

// source: proto/common/dataSourceInfo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_common_amazon_pb = require('../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
var proto_common_amount_pb = require('../../proto/common/amount_pb.js');
goog.object.extend(proto, proto_common_amount_pb);
var proto_common_crypto_pb = require('../../proto/common/crypto_pb.js');
goog.object.extend(proto, proto_common_crypto_pb);
var proto_common_datetime_pb = require('../../proto/common/datetime_pb.js');
goog.object.extend(proto, proto_common_datetime_pb);
var proto_common_facebook_pb = require('../../proto/common/facebook_pb.js');
goog.object.extend(proto, proto_common_facebook_pb);
goog.exportSymbol('proto.common.AdWordsAccount', null, global);
goog.exportSymbol('proto.common.AdWordsAccount.Error', null, global);
goog.exportSymbol('proto.common.AdWordsAccount.Error.Type', null, global);
goog.exportSymbol('proto.common.AdWordsAccount.Invitation', null, global);
goog.exportSymbol('proto.common.AdWordsAccount.Invitation.Status', null, global);
goog.exportSymbol('proto.common.AdWordsInfo', null, global);
goog.exportSymbol('proto.common.AdWordsInfo.Performance', null, global);
goog.exportSymbol('proto.common.AmazonInfo', null, global);
goog.exportSymbol('proto.common.BigCommerceInfo', null, global);
goog.exportSymbol('proto.common.FacebookInfo', null, global);
goog.exportSymbol('proto.common.GAInfo', null, global);
goog.exportSymbol('proto.common.GAInfo.Error', null, global);
goog.exportSymbol('proto.common.GAInfo.Error.Type', null, global);
goog.exportSymbol('proto.common.GoogleAdsAccountMetric', null, global);
goog.exportSymbol('proto.common.GoogleAdsAudit', null, global);
goog.exportSymbol('proto.common.GoogleAdsAudit.Metrics', null, global);
goog.exportSymbol('proto.common.GoogleAdsAudit.Status', null, global);
goog.exportSymbol('proto.common.GoogleAdsAudit.Status.Option', null, global);
goog.exportSymbol('proto.common.ShopifyInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GAInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GAInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GAInfo.displayName = 'proto.common.GAInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GAInfo.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GAInfo.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GAInfo.Error.displayName = 'proto.common.GAInfo.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AdWordsInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.AdWordsInfo.repeatedFields_, null);
};
goog.inherits(proto.common.AdWordsInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AdWordsInfo.displayName = 'proto.common.AdWordsInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AdWordsInfo.Performance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AdWordsInfo.Performance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AdWordsInfo.Performance.displayName = 'proto.common.AdWordsInfo.Performance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ShopifyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ShopifyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ShopifyInfo.displayName = 'proto.common.ShopifyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.BigCommerceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.BigCommerceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.BigCommerceInfo.displayName = 'proto.common.BigCommerceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GoogleAdsAccountMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.GoogleAdsAccountMetric.repeatedFields_, null);
};
goog.inherits(proto.common.GoogleAdsAccountMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GoogleAdsAccountMetric.displayName = 'proto.common.GoogleAdsAccountMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AdWordsAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AdWordsAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AdWordsAccount.displayName = 'proto.common.AdWordsAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AdWordsAccount.Invitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AdWordsAccount.Invitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AdWordsAccount.Invitation.displayName = 'proto.common.AdWordsAccount.Invitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AdWordsAccount.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AdWordsAccount.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AdWordsAccount.Error.displayName = 'proto.common.AdWordsAccount.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GoogleAdsAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GoogleAdsAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GoogleAdsAudit.displayName = 'proto.common.GoogleAdsAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GoogleAdsAudit.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GoogleAdsAudit.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GoogleAdsAudit.Status.displayName = 'proto.common.GoogleAdsAudit.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GoogleAdsAudit.Metrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GoogleAdsAudit.Metrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GoogleAdsAudit.Metrics.displayName = 'proto.common.GoogleAdsAudit.Metrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AmazonInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.AmazonInfo.repeatedFields_, null);
};
goog.inherits(proto.common.AmazonInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AmazonInfo.displayName = 'proto.common.AmazonInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FacebookInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.FacebookInfo.repeatedFields_, null);
};
goog.inherits(proto.common.FacebookInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FacebookInfo.displayName = 'proto.common.FacebookInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GAInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GAInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GAInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GAInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    view: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authTokenName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && proto.common.GAInfo.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GAInfo}
 */
proto.common.GAInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GAInfo;
  return proto.common.GAInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GAInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GAInfo}
 */
proto.common.GAInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setView(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthTokenName(value);
      break;
    case 3:
      var value = new proto.common.GAInfo.Error;
      reader.readMessage(value,proto.common.GAInfo.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GAInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GAInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GAInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GAInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getView();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthTokenName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.GAInfo.Error.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GAInfo.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GAInfo.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GAInfo.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GAInfo.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GAInfo.Error}
 */
proto.common.GAInfo.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GAInfo.Error;
  return proto.common.GAInfo.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GAInfo.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GAInfo.Error}
 */
proto.common.GAInfo.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.common.GAInfo.Error.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GAInfo.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GAInfo.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GAInfo.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GAInfo.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.GAInfo.Error.Type = {
  UNKNOWN: 0,
  UNAUTHORIZED: 1
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.common.GAInfo.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.GAInfo.Error} returns this
 */
proto.common.GAInfo.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.common.GAInfo.Error.Type}
 */
proto.common.GAInfo.Error.prototype.getType = function() {
  return /** @type {!proto.common.GAInfo.Error.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.GAInfo.Error.Type} value
 * @return {!proto.common.GAInfo.Error} returns this
 */
proto.common.GAInfo.Error.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string view = 1;
 * @return {string}
 */
proto.common.GAInfo.prototype.getView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.GAInfo} returns this
 */
proto.common.GAInfo.prototype.setView = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auth_token_name = 2;
 * @return {string}
 */
proto.common.GAInfo.prototype.getAuthTokenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.GAInfo} returns this
 */
proto.common.GAInfo.prototype.setAuthTokenName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Error error = 3;
 * @return {?proto.common.GAInfo.Error}
 */
proto.common.GAInfo.prototype.getError = function() {
  return /** @type{?proto.common.GAInfo.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.GAInfo.Error, 3));
};


/**
 * @param {?proto.common.GAInfo.Error|undefined} value
 * @return {!proto.common.GAInfo} returns this
*/
proto.common.GAInfo.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GAInfo} returns this
 */
proto.common.GAInfo.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GAInfo.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.AdWordsInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AdWordsInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AdWordsInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AdWordsInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.common.AdWordsAccount.toObject, includeInstance),
    adSpend: (f = msg.getAdSpend()) && proto.common.GoogleAdsAccountMetric.toObject(includeInstance, f),
    ytdOpportunityPerformance: (f = msg.getYtdOpportunityPerformance()) && proto.common.AdWordsInfo.Performance.toObject(includeInstance, f),
    weeklyOpportunityPerformance: (f = msg.getWeeklyOpportunityPerformance()) && proto.common.AdWordsInfo.Performance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AdWordsInfo}
 */
proto.common.AdWordsInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AdWordsInfo;
  return proto.common.AdWordsInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AdWordsInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AdWordsInfo}
 */
proto.common.AdWordsInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.AdWordsAccount;
      reader.readMessage(value,proto.common.AdWordsAccount.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 2:
      var value = new proto.common.GoogleAdsAccountMetric;
      reader.readMessage(value,proto.common.GoogleAdsAccountMetric.deserializeBinaryFromReader);
      msg.setAdSpend(value);
      break;
    case 5:
      var value = new proto.common.AdWordsInfo.Performance;
      reader.readMessage(value,proto.common.AdWordsInfo.Performance.deserializeBinaryFromReader);
      msg.setYtdOpportunityPerformance(value);
      break;
    case 6:
      var value = new proto.common.AdWordsInfo.Performance;
      reader.readMessage(value,proto.common.AdWordsInfo.Performance.deserializeBinaryFromReader);
      msg.setWeeklyOpportunityPerformance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AdWordsInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AdWordsInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AdWordsInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.AdWordsAccount.serializeBinaryToWriter
    );
  }
  f = message.getAdSpend();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.GoogleAdsAccountMetric.serializeBinaryToWriter
    );
  }
  f = message.getYtdOpportunityPerformance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.AdWordsInfo.Performance.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyOpportunityPerformance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.AdWordsInfo.Performance.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AdWordsInfo.Performance.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AdWordsInfo.Performance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AdWordsInfo.Performance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsInfo.Performance.toObject = function(includeInstance, msg) {
  var f, obj = {
    clicks: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    conversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    conversionValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AdWordsInfo.Performance}
 */
proto.common.AdWordsInfo.Performance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AdWordsInfo.Performance;
  return proto.common.AdWordsInfo.Performance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AdWordsInfo.Performance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AdWordsInfo.Performance}
 */
proto.common.AdWordsInfo.Performance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClicks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConversions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConversionValue(value);
      break;
    case 5:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AdWordsInfo.Performance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AdWordsInfo.Performance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AdWordsInfo.Performance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsInfo.Performance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClicks();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCost();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getConversions();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getConversionValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 clicks = 1;
 * @return {number}
 */
proto.common.AdWordsInfo.Performance.prototype.getClicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.AdWordsInfo.Performance} returns this
 */
proto.common.AdWordsInfo.Performance.prototype.setClicks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double cost = 2;
 * @return {number}
 */
proto.common.AdWordsInfo.Performance.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AdWordsInfo.Performance} returns this
 */
proto.common.AdWordsInfo.Performance.prototype.setCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double conversions = 3;
 * @return {number}
 */
proto.common.AdWordsInfo.Performance.prototype.getConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AdWordsInfo.Performance} returns this
 */
proto.common.AdWordsInfo.Performance.prototype.setConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double conversion_value = 4;
 * @return {number}
 */
proto.common.AdWordsInfo.Performance.prototype.getConversionValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AdWordsInfo.Performance} returns this
 */
proto.common.AdWordsInfo.Performance.prototype.setConversionValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional DateRangeV2 date_range = 5;
 * @return {?proto.common.DateRangeV2}
 */
proto.common.AdWordsInfo.Performance.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 5));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.common.AdWordsInfo.Performance} returns this
*/
proto.common.AdWordsInfo.Performance.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsInfo.Performance} returns this
 */
proto.common.AdWordsInfo.Performance.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsInfo.Performance.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated AdWordsAccount accounts = 1;
 * @return {!Array<!proto.common.AdWordsAccount>}
 */
proto.common.AdWordsInfo.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.common.AdWordsAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.AdWordsAccount, 1));
};


/**
 * @param {!Array<!proto.common.AdWordsAccount>} value
 * @return {!proto.common.AdWordsInfo} returns this
*/
proto.common.AdWordsInfo.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.AdWordsAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.AdWordsAccount}
 */
proto.common.AdWordsInfo.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.AdWordsAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.AdWordsInfo} returns this
 */
proto.common.AdWordsInfo.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * optional GoogleAdsAccountMetric ad_spend = 2;
 * @return {?proto.common.GoogleAdsAccountMetric}
 */
proto.common.AdWordsInfo.prototype.getAdSpend = function() {
  return /** @type{?proto.common.GoogleAdsAccountMetric} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAccountMetric, 2));
};


/**
 * @param {?proto.common.GoogleAdsAccountMetric|undefined} value
 * @return {!proto.common.AdWordsInfo} returns this
*/
proto.common.AdWordsInfo.prototype.setAdSpend = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsInfo} returns this
 */
proto.common.AdWordsInfo.prototype.clearAdSpend = function() {
  return this.setAdSpend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsInfo.prototype.hasAdSpend = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Performance ytd_opportunity_performance = 5;
 * @return {?proto.common.AdWordsInfo.Performance}
 */
proto.common.AdWordsInfo.prototype.getYtdOpportunityPerformance = function() {
  return /** @type{?proto.common.AdWordsInfo.Performance} */ (
    jspb.Message.getWrapperField(this, proto.common.AdWordsInfo.Performance, 5));
};


/**
 * @param {?proto.common.AdWordsInfo.Performance|undefined} value
 * @return {!proto.common.AdWordsInfo} returns this
*/
proto.common.AdWordsInfo.prototype.setYtdOpportunityPerformance = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsInfo} returns this
 */
proto.common.AdWordsInfo.prototype.clearYtdOpportunityPerformance = function() {
  return this.setYtdOpportunityPerformance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsInfo.prototype.hasYtdOpportunityPerformance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Performance weekly_opportunity_performance = 6;
 * @return {?proto.common.AdWordsInfo.Performance}
 */
proto.common.AdWordsInfo.prototype.getWeeklyOpportunityPerformance = function() {
  return /** @type{?proto.common.AdWordsInfo.Performance} */ (
    jspb.Message.getWrapperField(this, proto.common.AdWordsInfo.Performance, 6));
};


/**
 * @param {?proto.common.AdWordsInfo.Performance|undefined} value
 * @return {!proto.common.AdWordsInfo} returns this
*/
proto.common.AdWordsInfo.prototype.setWeeklyOpportunityPerformance = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsInfo} returns this
 */
proto.common.AdWordsInfo.prototype.clearWeeklyOpportunityPerformance = function() {
  return this.setWeeklyOpportunityPerformance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsInfo.prototype.hasWeeklyOpportunityPerformance = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ShopifyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ShopifyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ShopifyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ShopifyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shop: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accessToken: (f = msg.getAccessToken()) && proto_common_crypto_pb.EncryptedData.toObject(includeInstance, f),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    uninstalledTime: (f = msg.getUninstalledTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    planName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ShopifyInfo}
 */
proto.common.ShopifyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ShopifyInfo;
  return proto.common.ShopifyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ShopifyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ShopifyInfo}
 */
proto.common.ShopifyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setShopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 4:
      var value = new proto_common_crypto_pb.EncryptedData;
      reader.readMessage(value,proto_common_crypto_pb.EncryptedData.deserializeBinaryFromReader);
      msg.setAccessToken(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUninstalledTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ShopifyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ShopifyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ShopifyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ShopifyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccessToken();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_crypto_pb.EncryptedData.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUninstalledTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlanName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional uint64 shop_id = 1;
 * @return {number}
 */
proto.common.ShopifyInfo.prototype.getShopId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.setShopId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shop = 2;
 * @return {string}
 */
proto.common.ShopifyInfo.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.setShop = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EncryptedData access_token = 4;
 * @return {?proto.common.EncryptedData}
 */
proto.common.ShopifyInfo.prototype.getAccessToken = function() {
  return /** @type{?proto.common.EncryptedData} */ (
    jspb.Message.getWrapperField(this, proto_common_crypto_pb.EncryptedData, 4));
};


/**
 * @param {?proto.common.EncryptedData|undefined} value
 * @return {!proto.common.ShopifyInfo} returns this
*/
proto.common.ShopifyInfo.prototype.setAccessToken = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.clearAccessToken = function() {
  return this.setAccessToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ShopifyInfo.prototype.hasAccessToken = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp created_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.ShopifyInfo.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.ShopifyInfo} returns this
*/
proto.common.ShopifyInfo.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ShopifyInfo.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp uninstalled_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.ShopifyInfo.prototype.getUninstalledTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.ShopifyInfo} returns this
*/
proto.common.ShopifyInfo.prototype.setUninstalledTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.clearUninstalledTime = function() {
  return this.setUninstalledTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ShopifyInfo.prototype.hasUninstalledTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string plan_name = 6;
 * @return {string}
 */
proto.common.ShopifyInfo.prototype.getPlanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string currency_code = 7;
 * @return {string}
 */
proto.common.ShopifyInfo.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string timezone = 8;
 * @return {string}
 */
proto.common.ShopifyInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ShopifyInfo} returns this
 */
proto.common.ShopifyInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.BigCommerceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.BigCommerceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.BigCommerceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.BigCommerceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    webhookSecret: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uninstalledTime: (f = msg.getUninstalledTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accessToken: (f = msg.getAccessToken()) && proto_common_crypto_pb.EncryptedData.toObject(includeInstance, f),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.BigCommerceInfo}
 */
proto.common.BigCommerceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.BigCommerceInfo;
  return proto.common.BigCommerceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.BigCommerceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.BigCommerceInfo}
 */
proto.common.BigCommerceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreHash(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhookSecret(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUninstalledTime(value);
      break;
    case 5:
      var value = new proto_common_crypto_pb.EncryptedData;
      reader.readMessage(value,proto_common_crypto_pb.EncryptedData.deserializeBinaryFromReader);
      msg.setAccessToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.BigCommerceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.BigCommerceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.BigCommerceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.BigCommerceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWebhookSecret();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUninstalledTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccessToken();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_crypto_pb.EncryptedData.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string store_hash = 1;
 * @return {string}
 */
proto.common.BigCommerceInfo.prototype.getStoreHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.setStoreHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.BigCommerceInfo.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.BigCommerceInfo} returns this
*/
proto.common.BigCommerceInfo.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.BigCommerceInfo.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string webhook_secret = 3;
 * @return {string}
 */
proto.common.BigCommerceInfo.prototype.getWebhookSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.setWebhookSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp uninstalled_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.BigCommerceInfo.prototype.getUninstalledTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.BigCommerceInfo} returns this
*/
proto.common.BigCommerceInfo.prototype.setUninstalledTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.clearUninstalledTime = function() {
  return this.setUninstalledTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.BigCommerceInfo.prototype.hasUninstalledTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EncryptedData access_token = 5;
 * @return {?proto.common.EncryptedData}
 */
proto.common.BigCommerceInfo.prototype.getAccessToken = function() {
  return /** @type{?proto.common.EncryptedData} */ (
    jspb.Message.getWrapperField(this, proto_common_crypto_pb.EncryptedData, 5));
};


/**
 * @param {?proto.common.EncryptedData|undefined} value
 * @return {!proto.common.BigCommerceInfo} returns this
*/
proto.common.BigCommerceInfo.prototype.setAccessToken = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.clearAccessToken = function() {
  return this.setAccessToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.BigCommerceInfo.prototype.hasAccessToken = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.common.BigCommerceInfo.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string timezone = 7;
 * @return {string}
 */
proto.common.BigCommerceInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.BigCommerceInfo} returns this
 */
proto.common.BigCommerceInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.GoogleAdsAccountMetric.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GoogleAdsAccountMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GoogleAdsAccountMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GoogleAdsAccountMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAccountMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && proto_common_amount_pb.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GoogleAdsAccountMetric}
 */
proto.common.GoogleAdsAccountMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GoogleAdsAccountMetric;
  return proto.common.GoogleAdsAccountMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GoogleAdsAccountMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GoogleAdsAccountMetric}
 */
proto.common.GoogleAdsAccountMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomerIds(value);
      break;
    case 2:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 3:
      var value = new proto_common_amount_pb.Amount;
      reader.readMessage(value,proto_common_amount_pb.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GoogleAdsAccountMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GoogleAdsAccountMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GoogleAdsAccountMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAccountMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_amount_pb.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string customer_ids = 1;
 * @return {!Array<string>}
 */
proto.common.GoogleAdsAccountMetric.prototype.getCustomerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
 */
proto.common.GoogleAdsAccountMetric.prototype.setCustomerIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
 */
proto.common.GoogleAdsAccountMetric.prototype.addCustomerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
 */
proto.common.GoogleAdsAccountMetric.prototype.clearCustomerIdsList = function() {
  return this.setCustomerIdsList([]);
};


/**
 * optional DateRangeV2 date_range = 2;
 * @return {?proto.common.DateRangeV2}
 */
proto.common.GoogleAdsAccountMetric.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 2));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
*/
proto.common.GoogleAdsAccountMetric.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
 */
proto.common.GoogleAdsAccountMetric.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAccountMetric.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Amount amount = 3;
 * @return {?proto.common.Amount}
 */
proto.common.GoogleAdsAccountMetric.prototype.getAmount = function() {
  return /** @type{?proto.common.Amount} */ (
    jspb.Message.getWrapperField(this, proto_common_amount_pb.Amount, 3));
};


/**
 * @param {?proto.common.Amount|undefined} value
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
*/
proto.common.GoogleAdsAccountMetric.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAccountMetric} returns this
 */
proto.common.GoogleAdsAccountMetric.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAccountMetric.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AdWordsAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AdWordsAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AdWordsAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    managerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    authTokenName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && proto.common.AdWordsAccount.Error.toObject(includeInstance, f),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    invitation: (f = msg.getInvitation()) && proto.common.AdWordsAccount.Invitation.toObject(includeInstance, f),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedTime: (f = msg.getUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AdWordsAccount}
 */
proto.common.AdWordsAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AdWordsAccount;
  return proto.common.AdWordsAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AdWordsAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AdWordsAccount}
 */
proto.common.AdWordsAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthTokenName(value);
      break;
    case 3:
      var value = new proto.common.AdWordsAccount.Error;
      reader.readMessage(value,proto.common.AdWordsAccount.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 7:
      var value = new proto.common.AdWordsAccount.Invitation;
      reader.readMessage(value,proto.common.AdWordsAccount.Invitation.deserializeBinaryFromReader);
      msg.setInvitation(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AdWordsAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AdWordsAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AdWordsAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAuthTokenName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.AdWordsAccount.Error.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInvitation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.AdWordsAccount.Invitation.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AdWordsAccount.Invitation.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AdWordsAccount.Invitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AdWordsAccount.Invitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsAccount.Invitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    verifiedMember: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AdWordsAccount.Invitation}
 */
proto.common.AdWordsAccount.Invitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AdWordsAccount.Invitation;
  return proto.common.AdWordsAccount.Invitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AdWordsAccount.Invitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AdWordsAccount.Invitation}
 */
proto.common.AdWordsAccount.Invitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerifiedMember(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 5:
      var value = /** @type {!proto.common.AdWordsAccount.Invitation.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AdWordsAccount.Invitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AdWordsAccount.Invitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AdWordsAccount.Invitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsAccount.Invitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVerifiedMember();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.AdWordsAccount.Invitation.Status = {
  UNKNOWN: 0,
  NONE: 1,
  VALID: 2,
  IS_MEMBER: 3,
  EXPIRED: 4
};

/**
 * optional string link = 1;
 * @return {string}
 */
proto.common.AdWordsAccount.Invitation.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount.Invitation} returns this
 */
proto.common.AdWordsAccount.Invitation.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.common.AdWordsAccount.Invitation.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount.Invitation} returns this
 */
proto.common.AdWordsAccount.Invitation.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool verified_member = 4;
 * @return {boolean}
 */
proto.common.AdWordsAccount.Invitation.prototype.getVerifiedMember = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.AdWordsAccount.Invitation} returns this
 */
proto.common.AdWordsAccount.Invitation.prototype.setVerifiedMember = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.AdWordsAccount.Invitation.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.AdWordsAccount.Invitation} returns this
*/
proto.common.AdWordsAccount.Invitation.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsAccount.Invitation} returns this
 */
proto.common.AdWordsAccount.Invitation.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsAccount.Invitation.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 5;
 * @return {!proto.common.AdWordsAccount.Invitation.Status}
 */
proto.common.AdWordsAccount.Invitation.prototype.getStatus = function() {
  return /** @type {!proto.common.AdWordsAccount.Invitation.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.AdWordsAccount.Invitation.Status} value
 * @return {!proto.common.AdWordsAccount.Invitation} returns this
 */
proto.common.AdWordsAccount.Invitation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AdWordsAccount.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AdWordsAccount.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AdWordsAccount.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsAccount.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AdWordsAccount.Error}
 */
proto.common.AdWordsAccount.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AdWordsAccount.Error;
  return proto.common.AdWordsAccount.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AdWordsAccount.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AdWordsAccount.Error}
 */
proto.common.AdWordsAccount.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.common.AdWordsAccount.Error.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AdWordsAccount.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AdWordsAccount.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AdWordsAccount.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AdWordsAccount.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.AdWordsAccount.Error.Type = {
  UNKNOWN: 0,
  UNAUTHORIZED: 1
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.common.AdWordsAccount.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount.Error} returns this
 */
proto.common.AdWordsAccount.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.common.AdWordsAccount.Error.Type}
 */
proto.common.AdWordsAccount.Error.prototype.getType = function() {
  return /** @type {!proto.common.AdWordsAccount.Error.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.AdWordsAccount.Error.Type} value
 * @return {!proto.common.AdWordsAccount.Error} returns this
 */
proto.common.AdWordsAccount.Error.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.common.AdWordsAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.common.AdWordsAccount.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string manager_id = 4;
 * @return {string}
 */
proto.common.AdWordsAccount.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string auth_token_name = 2;
 * @return {string}
 */
proto.common.AdWordsAccount.prototype.getAuthTokenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.setAuthTokenName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Error error = 3;
 * @return {?proto.common.AdWordsAccount.Error}
 */
proto.common.AdWordsAccount.prototype.getError = function() {
  return /** @type{?proto.common.AdWordsAccount.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.AdWordsAccount.Error, 3));
};


/**
 * @param {?proto.common.AdWordsAccount.Error|undefined} value
 * @return {!proto.common.AdWordsAccount} returns this
*/
proto.common.AdWordsAccount.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsAccount.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.common.AdWordsAccount.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Invitation invitation = 7;
 * @return {?proto.common.AdWordsAccount.Invitation}
 */
proto.common.AdWordsAccount.prototype.getInvitation = function() {
  return /** @type{?proto.common.AdWordsAccount.Invitation} */ (
    jspb.Message.getWrapperField(this, proto.common.AdWordsAccount.Invitation, 7));
};


/**
 * @param {?proto.common.AdWordsAccount.Invitation|undefined} value
 * @return {!proto.common.AdWordsAccount} returns this
*/
proto.common.AdWordsAccount.prototype.setInvitation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.clearInvitation = function() {
  return this.setInvitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsAccount.prototype.hasInvitation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp created_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.AdWordsAccount.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.AdWordsAccount} returns this
*/
proto.common.AdWordsAccount.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsAccount.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updated_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.AdWordsAccount.prototype.getUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.AdWordsAccount} returns this
*/
proto.common.AdWordsAccount.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AdWordsAccount} returns this
 */
proto.common.AdWordsAccount.prototype.clearUpdatedTime = function() {
  return this.setUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AdWordsAccount.prototype.hasUpdatedTime = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GoogleAdsAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GoogleAdsAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GoogleAdsAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hasBilling: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    hasConversionTracking: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ampdSiteWideDsaStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dsaStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    smartShoppingStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    shoppingStatus: jspb.Message.getFieldWithDefault(msg, 16, 0),
    ampdSiteWideDsaMetrics: (f = msg.getAmpdSiteWideDsaMetrics()) && proto.common.GoogleAdsAudit.Metrics.toObject(includeInstance, f),
    dsaMetrics: (f = msg.getDsaMetrics()) && proto.common.GoogleAdsAudit.Metrics.toObject(includeInstance, f),
    smartShoppingMetrics: (f = msg.getSmartShoppingMetrics()) && proto.common.GoogleAdsAudit.Metrics.toObject(includeInstance, f),
    shoppingMetrics: (f = msg.getShoppingMetrics()) && proto.common.GoogleAdsAudit.Metrics.toObject(includeInstance, f),
    searchMetrics: (f = msg.getSearchMetrics()) && proto.common.GoogleAdsAudit.Metrics.toObject(includeInstance, f),
    totalMetrics: (f = msg.getTotalMetrics()) && proto.common.GoogleAdsAudit.Metrics.toObject(includeInstance, f),
    hasRetargetingSimilar: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hasRetargetingRemarketing: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GoogleAdsAudit}
 */
proto.common.GoogleAdsAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GoogleAdsAudit;
  return proto.common.GoogleAdsAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GoogleAdsAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GoogleAdsAudit}
 */
proto.common.GoogleAdsAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBilling(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasConversionTracking(value);
      break;
    case 3:
      var value = /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (reader.readEnum());
      msg.setAmpdSiteWideDsaStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (reader.readEnum());
      msg.setDsaStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (reader.readEnum());
      msg.setSmartShoppingStatus(value);
      break;
    case 16:
      var value = /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (reader.readEnum());
      msg.setShoppingStatus(value);
      break;
    case 11:
      var value = new proto.common.GoogleAdsAudit.Metrics;
      reader.readMessage(value,proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader);
      msg.setAmpdSiteWideDsaMetrics(value);
      break;
    case 6:
      var value = new proto.common.GoogleAdsAudit.Metrics;
      reader.readMessage(value,proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader);
      msg.setDsaMetrics(value);
      break;
    case 7:
      var value = new proto.common.GoogleAdsAudit.Metrics;
      reader.readMessage(value,proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader);
      msg.setSmartShoppingMetrics(value);
      break;
    case 8:
      var value = new proto.common.GoogleAdsAudit.Metrics;
      reader.readMessage(value,proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader);
      msg.setShoppingMetrics(value);
      break;
    case 12:
      var value = new proto.common.GoogleAdsAudit.Metrics;
      reader.readMessage(value,proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader);
      msg.setSearchMetrics(value);
      break;
    case 13:
      var value = new proto.common.GoogleAdsAudit.Metrics;
      reader.readMessage(value,proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader);
      msg.setTotalMetrics(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRetargetingSimilar(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRetargetingRemarketing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GoogleAdsAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GoogleAdsAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GoogleAdsAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHasBilling();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHasConversionTracking();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAmpdSiteWideDsaStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDsaStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSmartShoppingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getShoppingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getAmpdSiteWideDsaMetrics();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getDsaMetrics();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getSmartShoppingMetrics();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getShoppingMetrics();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getSearchMetrics();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getTotalMetrics();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getHasRetargetingSimilar();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHasRetargetingRemarketing();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GoogleAdsAudit.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GoogleAdsAudit.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GoogleAdsAudit.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAudit.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GoogleAdsAudit.Status}
 */
proto.common.GoogleAdsAudit.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GoogleAdsAudit.Status;
  return proto.common.GoogleAdsAudit.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GoogleAdsAudit.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GoogleAdsAudit.Status}
 */
proto.common.GoogleAdsAudit.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GoogleAdsAudit.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GoogleAdsAudit.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GoogleAdsAudit.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAudit.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.GoogleAdsAudit.Status.Option = {
  UNKNOWN: 0,
  ENABLED: 1,
  PAUSED: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GoogleAdsAudit.Metrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GoogleAdsAudit.Metrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAudit.Metrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    impressions: jspb.Message.getFieldWithDefault(msg, 1, 0),
    clicks: jspb.Message.getFieldWithDefault(msg, 2, 0),
    conversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cost: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.Metrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GoogleAdsAudit.Metrics;
  return proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GoogleAdsAudit.Metrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.Metrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImpressions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClicks(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConversions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GoogleAdsAudit.Metrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GoogleAdsAudit.Metrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImpressions();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getClicks();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getConversions();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCost();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int64 impressions = 1;
 * @return {number}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.getImpressions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.GoogleAdsAudit.Metrics} returns this
 */
proto.common.GoogleAdsAudit.Metrics.prototype.setImpressions = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 clicks = 2;
 * @return {number}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.getClicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.GoogleAdsAudit.Metrics} returns this
 */
proto.common.GoogleAdsAudit.Metrics.prototype.setClicks = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double conversions = 3;
 * @return {number}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.getConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.GoogleAdsAudit.Metrics} returns this
 */
proto.common.GoogleAdsAudit.Metrics.prototype.setConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double revenue = 4;
 * @return {number}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.GoogleAdsAudit.Metrics} returns this
 */
proto.common.GoogleAdsAudit.Metrics.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double cost = 5;
 * @return {number}
 */
proto.common.GoogleAdsAudit.Metrics.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.GoogleAdsAudit.Metrics} returns this
 */
proto.common.GoogleAdsAudit.Metrics.prototype.setCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string customer_id = 15;
 * @return {string}
 */
proto.common.GoogleAdsAudit.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp created_time = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.GoogleAdsAudit.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool has_billing = 1;
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.getHasBilling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setHasBilling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool has_conversion_tracking = 2;
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.getHasConversionTracking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setHasConversionTracking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Status.Option ampd_site_wide_dsa_status = 3;
 * @return {!proto.common.GoogleAdsAudit.Status.Option}
 */
proto.common.GoogleAdsAudit.prototype.getAmpdSiteWideDsaStatus = function() {
  return /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.GoogleAdsAudit.Status.Option} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setAmpdSiteWideDsaStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Status.Option dsa_status = 4;
 * @return {!proto.common.GoogleAdsAudit.Status.Option}
 */
proto.common.GoogleAdsAudit.prototype.getDsaStatus = function() {
  return /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.GoogleAdsAudit.Status.Option} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setDsaStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Status.Option smart_shopping_status = 5;
 * @return {!proto.common.GoogleAdsAudit.Status.Option}
 */
proto.common.GoogleAdsAudit.prototype.getSmartShoppingStatus = function() {
  return /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.GoogleAdsAudit.Status.Option} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setSmartShoppingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Status.Option shopping_status = 16;
 * @return {!proto.common.GoogleAdsAudit.Status.Option}
 */
proto.common.GoogleAdsAudit.prototype.getShoppingStatus = function() {
  return /** @type {!proto.common.GoogleAdsAudit.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.common.GoogleAdsAudit.Status.Option} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setShoppingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional Metrics ampd_site_wide_dsa_metrics = 11;
 * @return {?proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.prototype.getAmpdSiteWideDsaMetrics = function() {
  return /** @type{?proto.common.GoogleAdsAudit.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAudit.Metrics, 11));
};


/**
 * @param {?proto.common.GoogleAdsAudit.Metrics|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setAmpdSiteWideDsaMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearAmpdSiteWideDsaMetrics = function() {
  return this.setAmpdSiteWideDsaMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasAmpdSiteWideDsaMetrics = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Metrics dsa_metrics = 6;
 * @return {?proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.prototype.getDsaMetrics = function() {
  return /** @type{?proto.common.GoogleAdsAudit.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAudit.Metrics, 6));
};


/**
 * @param {?proto.common.GoogleAdsAudit.Metrics|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setDsaMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearDsaMetrics = function() {
  return this.setDsaMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasDsaMetrics = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Metrics smart_shopping_metrics = 7;
 * @return {?proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.prototype.getSmartShoppingMetrics = function() {
  return /** @type{?proto.common.GoogleAdsAudit.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAudit.Metrics, 7));
};


/**
 * @param {?proto.common.GoogleAdsAudit.Metrics|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setSmartShoppingMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearSmartShoppingMetrics = function() {
  return this.setSmartShoppingMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasSmartShoppingMetrics = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Metrics shopping_metrics = 8;
 * @return {?proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.prototype.getShoppingMetrics = function() {
  return /** @type{?proto.common.GoogleAdsAudit.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAudit.Metrics, 8));
};


/**
 * @param {?proto.common.GoogleAdsAudit.Metrics|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setShoppingMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearShoppingMetrics = function() {
  return this.setShoppingMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasShoppingMetrics = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Metrics search_metrics = 12;
 * @return {?proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.prototype.getSearchMetrics = function() {
  return /** @type{?proto.common.GoogleAdsAudit.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAudit.Metrics, 12));
};


/**
 * @param {?proto.common.GoogleAdsAudit.Metrics|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setSearchMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearSearchMetrics = function() {
  return this.setSearchMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasSearchMetrics = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Metrics total_metrics = 13;
 * @return {?proto.common.GoogleAdsAudit.Metrics}
 */
proto.common.GoogleAdsAudit.prototype.getTotalMetrics = function() {
  return /** @type{?proto.common.GoogleAdsAudit.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.GoogleAdsAudit.Metrics, 13));
};


/**
 * @param {?proto.common.GoogleAdsAudit.Metrics|undefined} value
 * @return {!proto.common.GoogleAdsAudit} returns this
*/
proto.common.GoogleAdsAudit.prototype.setTotalMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.clearTotalMetrics = function() {
  return this.setTotalMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.hasTotalMetrics = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool has_retargeting_similar = 9;
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.getHasRetargetingSimilar = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setHasRetargetingSimilar = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool has_retargeting_remarketing = 10;
 * @return {boolean}
 */
proto.common.GoogleAdsAudit.prototype.getHasRetargetingRemarketing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.GoogleAdsAudit} returns this
 */
proto.common.GoogleAdsAudit.prototype.setHasRetargetingRemarketing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.AmazonInfo.repeatedFields_ = [1,3,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AmazonInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AmazonInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AmazonInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmazonInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertisingAccountsList: jspb.Message.toObjectList(msg.getAdvertisingAccountsList(),
    proto_common_amazon_pb.Amazon.AdvertisingAccount.toObject, includeInstance),
    profileMigrationsList: jspb.Message.toObjectList(msg.getProfileMigrationsList(),
    proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.toObject, includeInstance),
    sellerAccountsList: jspb.Message.toObjectList(msg.getSellerAccountsList(),
    proto_common_amazon_pb.Amazon.SellerAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AmazonInfo}
 */
proto.common.AmazonInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AmazonInfo;
  return proto.common.AmazonInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AmazonInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AmazonInfo}
 */
proto.common.AmazonInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingAccount;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingAccount.deserializeBinaryFromReader);
      msg.addAdvertisingAccounts(value);
      break;
    case 3:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfileMigration;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.deserializeBinaryFromReader);
      msg.addProfileMigrations(value);
      break;
    case 2:
      var value = new proto_common_amazon_pb.Amazon.SellerAccount;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.SellerAccount.deserializeBinaryFromReader);
      msg.addSellerAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AmazonInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AmazonInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AmazonInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmazonInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertisingAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingAccount.serializeBinaryToWriter
    );
  }
  f = message.getProfileMigrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.serializeBinaryToWriter
    );
  }
  f = message.getSellerAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_common_amazon_pb.Amazon.SellerAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Amazon.AdvertisingAccount advertising_accounts = 1;
 * @return {!Array<!proto.common.Amazon.AdvertisingAccount>}
 */
proto.common.AmazonInfo.prototype.getAdvertisingAccountsList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingAccount, 1));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingAccount>} value
 * @return {!proto.common.AmazonInfo} returns this
*/
proto.common.AmazonInfo.prototype.setAdvertisingAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingAccount}
 */
proto.common.AmazonInfo.prototype.addAdvertisingAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Amazon.AdvertisingAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.AmazonInfo} returns this
 */
proto.common.AmazonInfo.prototype.clearAdvertisingAccountsList = function() {
  return this.setAdvertisingAccountsList([]);
};


/**
 * repeated Amazon.AdvertisingProfileMigration profile_migrations = 3;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfileMigration>}
 */
proto.common.AmazonInfo.prototype.getProfileMigrationsList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfileMigration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfileMigration, 3));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfileMigration>} value
 * @return {!proto.common.AmazonInfo} returns this
*/
proto.common.AmazonInfo.prototype.setProfileMigrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfileMigration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfileMigration}
 */
proto.common.AmazonInfo.prototype.addProfileMigrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Amazon.AdvertisingProfileMigration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.AmazonInfo} returns this
 */
proto.common.AmazonInfo.prototype.clearProfileMigrationsList = function() {
  return this.setProfileMigrationsList([]);
};


/**
 * repeated Amazon.SellerAccount seller_accounts = 2;
 * @return {!Array<!proto.common.Amazon.SellerAccount>}
 */
proto.common.AmazonInfo.prototype.getSellerAccountsList = function() {
  return /** @type{!Array<!proto.common.Amazon.SellerAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.SellerAccount, 2));
};


/**
 * @param {!Array<!proto.common.Amazon.SellerAccount>} value
 * @return {!proto.common.AmazonInfo} returns this
*/
proto.common.AmazonInfo.prototype.setSellerAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Amazon.SellerAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.SellerAccount}
 */
proto.common.AmazonInfo.prototype.addSellerAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Amazon.SellerAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.AmazonInfo} returns this
 */
proto.common.AmazonInfo.prototype.clearSellerAccountsList = function() {
  return this.setSellerAccountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.FacebookInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FacebookInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FacebookInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FacebookInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FacebookInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto_common_facebook_pb.Facebook.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FacebookInfo}
 */
proto.common.FacebookInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FacebookInfo;
  return proto.common.FacebookInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FacebookInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FacebookInfo}
 */
proto.common.FacebookInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_facebook_pb.Facebook.Account;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.Account.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FacebookInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FacebookInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FacebookInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FacebookInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_common_facebook_pb.Facebook.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Facebook.Account accounts = 1;
 * @return {!Array<!proto.common.Facebook.Account>}
 */
proto.common.FacebookInfo.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.common.Facebook.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_facebook_pb.Facebook.Account, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.Account>} value
 * @return {!proto.common.FacebookInfo} returns this
*/
proto.common.FacebookInfo.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Facebook.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.Account}
 */
proto.common.FacebookInfo.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Facebook.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.FacebookInfo} returns this
 */
proto.common.FacebookInfo.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


goog.object.extend(exports, proto.common);

// source: proto/common/productDetails.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_amazon_pb = require('../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
var proto_common_walmart_pb = require('../../proto/common/walmart_pb.js');
goog.object.extend(proto, proto_common_walmart_pb);
goog.exportSymbol('proto.common.ProductDetails', null, global);
goog.exportSymbol('proto.common.ProductDetails.Amazon', null, global);
goog.exportSymbol('proto.common.ProductDetails.Parameter', null, global);
goog.exportSymbol('proto.common.ProductDetails.TypeCase', null, global);
goog.exportSymbol('proto.common.ProductDetails.Walmart', null, global);
goog.exportSymbol('proto.common.ProductDetails.Walmart.Attribution', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ProductDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.ProductDetails.oneofGroups_);
};
goog.inherits(proto.common.ProductDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ProductDetails.displayName = 'proto.common.ProductDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ProductDetails.Amazon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.ProductDetails.Amazon.repeatedFields_, null);
};
goog.inherits(proto.common.ProductDetails.Amazon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ProductDetails.Amazon.displayName = 'proto.common.ProductDetails.Amazon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ProductDetails.Walmart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.ProductDetails.Walmart.repeatedFields_, null);
};
goog.inherits(proto.common.ProductDetails.Walmart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ProductDetails.Walmart.displayName = 'proto.common.ProductDetails.Walmart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ProductDetails.Walmart.Attribution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.ProductDetails.Walmart.Attribution.repeatedFields_, null);
};
goog.inherits(proto.common.ProductDetails.Walmart.Attribution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ProductDetails.Walmart.Attribution.displayName = 'proto.common.ProductDetails.Walmart.Attribution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ProductDetails.Parameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ProductDetails.Parameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ProductDetails.Parameter.displayName = 'proto.common.ProductDetails.Parameter';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.ProductDetails.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.common.ProductDetails.TypeCase = {
  TYPE_NOT_SET: 0,
  AMAZON: 1,
  WALMART: 2
};

/**
 * @return {proto.common.ProductDetails.TypeCase}
 */
proto.common.ProductDetails.prototype.getTypeCase = function() {
  return /** @type {proto.common.ProductDetails.TypeCase} */(jspb.Message.computeOneofCase(this, proto.common.ProductDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ProductDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ProductDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ProductDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    amazon: (f = msg.getAmazon()) && proto.common.ProductDetails.Amazon.toObject(includeInstance, f),
    walmart: (f = msg.getWalmart()) && proto.common.ProductDetails.Walmart.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ProductDetails}
 */
proto.common.ProductDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ProductDetails;
  return proto.common.ProductDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ProductDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ProductDetails}
 */
proto.common.ProductDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.ProductDetails.Amazon;
      reader.readMessage(value,proto.common.ProductDetails.Amazon.deserializeBinaryFromReader);
      msg.setAmazon(value);
      break;
    case 2:
      var value = new proto.common.ProductDetails.Walmart;
      reader.readMessage(value,proto.common.ProductDetails.Walmart.deserializeBinaryFromReader);
      msg.setWalmart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ProductDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ProductDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ProductDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmazon();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.ProductDetails.Amazon.serializeBinaryToWriter
    );
  }
  f = message.getWalmart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.ProductDetails.Walmart.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.ProductDetails.Amazon.repeatedFields_ = [3,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ProductDetails.Amazon.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ProductDetails.Amazon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ProductDetails.Amazon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Amazon.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    asin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productHeadline: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    searchTermsToTrackList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    trackOrganicRankings: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    searchTermsHistoryList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    attributionProfileId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    attributionProfileIdStr: jspb.Message.getFieldWithDefault(msg, 9, ""),
    attributionAdvertiserId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    attributionAdvertiserIdStr: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ProductDetails.Amazon}
 */
proto.common.ProductDetails.Amazon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ProductDetails.Amazon;
  return proto.common.ProductDetails.Amazon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ProductDetails.Amazon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ProductDetails.Amazon}
 */
proto.common.ProductDetails.Amazon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductHeadline(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSearchTermsToTrack(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackOrganicRankings(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addSearchTermsHistory(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAttributionProfileId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributionProfileIdStr(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAttributionAdvertiserId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributionAdvertiserIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ProductDetails.Amazon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ProductDetails.Amazon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ProductDetails.Amazon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Amazon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductHeadline();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSearchTermsToTrackList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getTrackOrganicRankings();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getSearchTermsHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getAttributionProfileId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAttributionProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAttributionAdvertiserId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAttributionAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional Amazon.Marketplace.Option marketplace = 1;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.common.ProductDetails.Amazon.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string asin = 2;
 * @return {string}
 */
proto.common.ProductDetails.Amazon.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_url = 4;
 * @return {string}
 */
proto.common.ProductDetails.Amazon.prototype.getProductUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setProductUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_headline = 5;
 * @return {string}
 */
proto.common.ProductDetails.Amazon.prototype.getProductHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setProductHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_title = 6;
 * @return {string}
 */
proto.common.ProductDetails.Amazon.prototype.getProductTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setProductTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string search_terms_to_track = 3;
 * @return {!Array<string>}
 */
proto.common.ProductDetails.Amazon.prototype.getSearchTermsToTrackList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setSearchTermsToTrackList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.addSearchTermsToTrack = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.clearSearchTermsToTrackList = function() {
  return this.setSearchTermsToTrackList([]);
};


/**
 * optional bool track_organic_rankings = 12;
 * @return {boolean}
 */
proto.common.ProductDetails.Amazon.prototype.getTrackOrganicRankings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setTrackOrganicRankings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated string search_terms_history = 11;
 * @return {!Array<string>}
 */
proto.common.ProductDetails.Amazon.prototype.getSearchTermsHistoryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setSearchTermsHistoryList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.addSearchTermsHistory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.clearSearchTermsHistoryList = function() {
  return this.setSearchTermsHistoryList([]);
};


/**
 * optional int64 attribution_profile_id = 7;
 * @return {number}
 */
proto.common.ProductDetails.Amazon.prototype.getAttributionProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setAttributionProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string attribution_profile_id_str = 9;
 * @return {string}
 */
proto.common.ProductDetails.Amazon.prototype.getAttributionProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setAttributionProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 attribution_advertiser_id = 8;
 * @return {number}
 */
proto.common.ProductDetails.Amazon.prototype.getAttributionAdvertiserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setAttributionAdvertiserId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string attribution_advertiser_id_str = 10;
 * @return {string}
 */
proto.common.ProductDetails.Amazon.prototype.getAttributionAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Amazon} returns this
 */
proto.common.ProductDetails.Amazon.prototype.setAttributionAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.ProductDetails.Walmart.repeatedFields_ = [3,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ProductDetails.Walmart.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ProductDetails.Walmart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ProductDetails.Walmart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Walmart.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    itemBrand: jspb.Message.getFieldWithDefault(msg, 10, ""),
    urlSearchTerm: jspb.Message.getFieldWithDefault(msg, 4, ""),
    brandNameFacetsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    otherSearchFacetsList: jspb.Message.toObjectList(msg.getOtherSearchFacetsList(),
    proto.common.ProductDetails.Parameter.toObject, includeInstance),
    otherSearchParametersList: jspb.Message.toObjectList(msg.getOtherSearchParametersList(),
    proto.common.ProductDetails.Parameter.toObject, includeInstance),
    searchTermTrackingMap: (f = msg.getSearchTermTrackingMap()) ? f.toObject(includeInstance, undefined) : [],
    attribution: (f = msg.getAttribution()) && proto.common.ProductDetails.Walmart.Attribution.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ProductDetails.Walmart}
 */
proto.common.ProductDetails.Walmart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ProductDetails.Walmart;
  return proto.common.ProductDetails.Walmart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ProductDetails.Walmart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ProductDetails.Walmart}
 */
proto.common.ProductDetails.Walmart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addItemIds(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemBrand(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlSearchTerm(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBrandNameFacets(value);
      break;
    case 6:
      var value = new proto.common.ProductDetails.Parameter;
      reader.readMessage(value,proto.common.ProductDetails.Parameter.deserializeBinaryFromReader);
      msg.addOtherSearchFacets(value);
      break;
    case 7:
      var value = new proto.common.ProductDetails.Parameter;
      reader.readMessage(value,proto.common.ProductDetails.Parameter.deserializeBinaryFromReader);
      msg.addOtherSearchParameters(value);
      break;
    case 8:
      var value = msg.getSearchTermTrackingMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 9:
      var value = new proto.common.ProductDetails.Walmart.Attribution;
      reader.readMessage(value,proto.common.ProductDetails.Walmart.Attribution.deserializeBinaryFromReader);
      msg.setAttribution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ProductDetails.Walmart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ProductDetails.Walmart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ProductDetails.Walmart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Walmart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTargetUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getItemBrand();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUrlSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBrandNameFacetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOtherSearchFacetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.common.ProductDetails.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getOtherSearchParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.common.ProductDetails.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getSearchTermTrackingMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getAttribution();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.common.ProductDetails.Walmart.Attribution.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.ProductDetails.Walmart.Attribution.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ProductDetails.Walmart.Attribution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ProductDetails.Walmart.Attribution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Walmart.Attribution.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    excludedCategoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    minPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    maxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ProductDetails.Walmart.Attribution}
 */
proto.common.ProductDetails.Walmart.Attribution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ProductDetails.Walmart.Attribution;
  return proto.common.ProductDetails.Walmart.Attribution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ProductDetails.Walmart.Attribution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ProductDetails.Walmart.Attribution}
 */
proto.common.ProductDetails.Walmart.Attribution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addExcludedCategories(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ProductDetails.Walmart.Attribution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ProductDetails.Walmart.Attribution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Walmart.Attribution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getExcludedCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getMinPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated string categories = 1;
 * @return {!Array<string>}
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated string excluded_categories = 4;
 * @return {!Array<string>}
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.getExcludedCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.setExcludedCategoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.addExcludedCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.clearExcludedCategoriesList = function() {
  return this.setExcludedCategoriesList([]);
};


/**
 * optional double min_price = 2;
 * @return {number}
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double max_price = 3;
 * @return {number}
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.ProductDetails.Walmart.Attribution} returns this
 */
proto.common.ProductDetails.Walmart.Attribution.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Walmart.Marketplace.Option marketplace = 1;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.common.ProductDetails.Walmart.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string target_url = 2;
 * @return {string}
 */
proto.common.ProductDetails.Walmart.prototype.getTargetUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.setTargetUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string item_ids = 3;
 * @return {!Array<string>}
 */
proto.common.ProductDetails.Walmart.prototype.getItemIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.setItemIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.addItemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.clearItemIdsList = function() {
  return this.setItemIdsList([]);
};


/**
 * optional string item_brand = 10;
 * @return {string}
 */
proto.common.ProductDetails.Walmart.prototype.getItemBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.setItemBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string url_search_term = 4;
 * @return {string}
 */
proto.common.ProductDetails.Walmart.prototype.getUrlSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.setUrlSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string brand_name_facets = 5;
 * @return {!Array<string>}
 */
proto.common.ProductDetails.Walmart.prototype.getBrandNameFacetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.setBrandNameFacetsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.addBrandNameFacets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.clearBrandNameFacetsList = function() {
  return this.setBrandNameFacetsList([]);
};


/**
 * repeated Parameter other_search_facets = 6;
 * @return {!Array<!proto.common.ProductDetails.Parameter>}
 */
proto.common.ProductDetails.Walmart.prototype.getOtherSearchFacetsList = function() {
  return /** @type{!Array<!proto.common.ProductDetails.Parameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.ProductDetails.Parameter, 6));
};


/**
 * @param {!Array<!proto.common.ProductDetails.Parameter>} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
*/
proto.common.ProductDetails.Walmart.prototype.setOtherSearchFacetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ProductDetails.Parameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Parameter}
 */
proto.common.ProductDetails.Walmart.prototype.addOtherSearchFacets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ProductDetails.Parameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.clearOtherSearchFacetsList = function() {
  return this.setOtherSearchFacetsList([]);
};


/**
 * repeated Parameter other_search_parameters = 7;
 * @return {!Array<!proto.common.ProductDetails.Parameter>}
 */
proto.common.ProductDetails.Walmart.prototype.getOtherSearchParametersList = function() {
  return /** @type{!Array<!proto.common.ProductDetails.Parameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.ProductDetails.Parameter, 7));
};


/**
 * @param {!Array<!proto.common.ProductDetails.Parameter>} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
*/
proto.common.ProductDetails.Walmart.prototype.setOtherSearchParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.ProductDetails.Parameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ProductDetails.Parameter}
 */
proto.common.ProductDetails.Walmart.prototype.addOtherSearchParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.ProductDetails.Parameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.clearOtherSearchParametersList = function() {
  return this.setOtherSearchParametersList([]);
};


/**
 * map<string, bool> search_term_tracking = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.common.ProductDetails.Walmart.prototype.getSearchTermTrackingMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.clearSearchTermTrackingMap = function() {
  this.getSearchTermTrackingMap().clear();
  return this;};


/**
 * optional Attribution attribution = 9;
 * @return {?proto.common.ProductDetails.Walmart.Attribution}
 */
proto.common.ProductDetails.Walmart.prototype.getAttribution = function() {
  return /** @type{?proto.common.ProductDetails.Walmart.Attribution} */ (
    jspb.Message.getWrapperField(this, proto.common.ProductDetails.Walmart.Attribution, 9));
};


/**
 * @param {?proto.common.ProductDetails.Walmart.Attribution|undefined} value
 * @return {!proto.common.ProductDetails.Walmart} returns this
*/
proto.common.ProductDetails.Walmart.prototype.setAttribution = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ProductDetails.Walmart} returns this
 */
proto.common.ProductDetails.Walmart.prototype.clearAttribution = function() {
  return this.setAttribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ProductDetails.Walmart.prototype.hasAttribution = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ProductDetails.Parameter.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ProductDetails.Parameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ProductDetails.Parameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Parameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ProductDetails.Parameter}
 */
proto.common.ProductDetails.Parameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ProductDetails.Parameter;
  return proto.common.ProductDetails.Parameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ProductDetails.Parameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ProductDetails.Parameter}
 */
proto.common.ProductDetails.Parameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ProductDetails.Parameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ProductDetails.Parameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ProductDetails.Parameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProductDetails.Parameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.ProductDetails.Parameter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Parameter} returns this
 */
proto.common.ProductDetails.Parameter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.ProductDetails.Parameter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ProductDetails.Parameter} returns this
 */
proto.common.ProductDetails.Parameter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Amazon amazon = 1;
 * @return {?proto.common.ProductDetails.Amazon}
 */
proto.common.ProductDetails.prototype.getAmazon = function() {
  return /** @type{?proto.common.ProductDetails.Amazon} */ (
    jspb.Message.getWrapperField(this, proto.common.ProductDetails.Amazon, 1));
};


/**
 * @param {?proto.common.ProductDetails.Amazon|undefined} value
 * @return {!proto.common.ProductDetails} returns this
*/
proto.common.ProductDetails.prototype.setAmazon = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.common.ProductDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ProductDetails} returns this
 */
proto.common.ProductDetails.prototype.clearAmazon = function() {
  return this.setAmazon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ProductDetails.prototype.hasAmazon = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Walmart walmart = 2;
 * @return {?proto.common.ProductDetails.Walmart}
 */
proto.common.ProductDetails.prototype.getWalmart = function() {
  return /** @type{?proto.common.ProductDetails.Walmart} */ (
    jspb.Message.getWrapperField(this, proto.common.ProductDetails.Walmart, 2));
};


/**
 * @param {?proto.common.ProductDetails.Walmart|undefined} value
 * @return {!proto.common.ProductDetails} returns this
*/
proto.common.ProductDetails.prototype.setWalmart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.common.ProductDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ProductDetails} returns this
 */
proto.common.ProductDetails.prototype.clearWalmart = function() {
  return this.setWalmart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ProductDetails.prototype.hasWalmart = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.common);

import createStore from "redux-zero";
import { combineActions } from "redux-zero/utils";
import { auditActions, makeInitialAuditState } from "./audit";

export const makeInitialState = apolloClient => ({
  apolloClient,
  ...makeInitialAuditState()
});

// The redux store for the CreateProductAd workflow.
export const createAppStore = createStore(makeInitialState());

const coreActions = (/* store */) => ({
  setApolloClient: (state, apolloClient) => ({ ...state, apolloClient })
});

// All of the actions for our Redux store.
export const actions = combineActions(coreActions, auditActions);

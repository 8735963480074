import { connect } from "redux-zero/react";
import { DateInput } from "semantic-ui-calendar-react";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { useSearchParams, useLocation, Link } from "react-router-dom-v5-compat";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import { actions } from "ExtensionV2/redux/store";
import {
  METRIC_DATE_RANGE_OPTIONS,
  parseDateRangeParam
} from "Common/components/dateRangeUtils";
import {
  QUERY_STRING_DATE_FORMAT,
  DEFAULT_NAMED_DATE_RANGE,
  DATE_RANGE_CUSTOM_START_URL_PARAM,
  DATE_RANGE_CUSTOM_END_URL_PARAM
} from "ExtensionV2/redux/audit";
import styled from "styled-components";
import getCompareToDates, {
  COMPARE_TO_PREVIOUS
} from "ExtensionV2/state/getCompareToDates";
import SimpleTooltip from "./SimpleTooltip";
import { pluralize } from "Common/utils/strings";

import { AMAZON_ATTRIBUTION_DELAY_DAYS } from "Common/utils/amazon";

export const DatePickerLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

export const DatePickerCheckbox = styled(Checkbox)`
  &&& {
    display: block;
    margin: 0 0 0.3em 0.5em;
    font-weight: bold;
    font-size: small;
  }
`;

const COMPARE_STARTING_AT = "startingAt";

// Manages the dashboard-wide date range in the redux state.
const _GlobalDatePicker = ({
  excludeAmazonLagPeriod,
  offerCompare,
  startDate,
  endDate,
  namedRange,
  compareTo,
  dispatchSetDataDateRange,
  dispatchEnsureDateUrlParams
}) => {
  const DISPLAY_FORMAT = "ddd, MMM Do YYYY"; // ex: Sat, Jul 2nd 2022
  const location = useLocation();
  // Don't forget that searchParams is not a plain Object, it is a URLSearchParams
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayEndDate, setDisplayEndDate] = useState("");
  const [displayStartDate, setDisplayStartDate] = useState("");

  // Keep the URL search params in sync with the date range values from the redux state.
  useEffect(() => {
    dispatchEnsureDateUrlParams(searchParams, setSearchParams);
  }, [
    startDate,
    endDate,
    namedRange,
    compareTo,
    dispatchEnsureDateUrlParams,
    searchParams,
    setSearchParams
  ]);

  const internalFormatToCustomerFormat = dateString => {
    return moment(dateString, QUERY_STRING_DATE_FORMAT).format(DISPLAY_FORMAT);
  };
  /*
    Convert our internal date format to a customer-friendly format
  */
  useEffect(() => {
    if (startDate) {
      setDisplayStartDate(internalFormatToCustomerFormat(startDate));
    }

    if (endDate) {
      setDisplayEndDate(internalFormatToCustomerFormat(endDate));
    }
  }, [endDate, startDate]);

  /*
    Update the start/end date based on the selected named range
  */
  useEffect(() => {
    if (namedRange) {
      setGlobalDateRange(
        namedRange,
        excludeAmazonLagPeriod,
        dispatchSetDataDateRange,
        compareTo
      );
    }
  }, [namedRange, compareTo, excludeAmazonLagPeriod, dispatchSetDataDateRange]);

  const diffDays = moment(displayEndDate, DISPLAY_FORMAT).diff(
    moment(displayStartDate, DISPLAY_FORMAT),
    "days"
  );

  const [compareRangeStartDate, compareRangeEndDate] = useMemo(
    () =>
      getCompareToDates(startDate, endDate, compareTo || COMPARE_TO_PREVIOUS),
    [startDate, endDate, compareTo]
  );

  const compareToOptions = useMemo(
    () => [
      {
        text: "--",
        value: "none"
      },
      {
        text: `Previous ${pluralize(diffDays + 1, "day")}`,
        value: COMPARE_TO_PREVIOUS
      },
      {
        text: `${pluralize(diffDays + 1, "day")} starting at`,
        value: COMPARE_STARTING_AT
      }
    ],
    [diffDays]
  );

  const compareToValue = useMemo(() => {
    if (!compareTo) {
      return "none";
    }
    if (compareTo === COMPARE_TO_PREVIOUS) {
      return COMPARE_TO_PREVIOUS;
    }
    return COMPARE_STARTING_AT;
  }, [compareTo]);

  const compareToTooltip = useMemo(() => {
    if (!offerCompare) {
      return null;
    }

    const label = `Compare to ${pluralize(diffDays + 1, "day")}`;
    const start = internalFormatToCustomerFormat(compareRangeStartDate);
    const end = internalFormatToCustomerFormat(compareRangeEndDate);

    return (
      <div>
        {label}:
        <br />
        <Link
          to={{
            pathname: location.pathname,
            search: new URLSearchParams({
              [DATE_RANGE_CUSTOM_START_URL_PARAM]: compareRangeStartDate,
              [DATE_RANGE_CUSTOM_END_URL_PARAM]: compareRangeEndDate
            }).toString()
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {start} - {end}
        </Link>
      </div>
    );
  }, [
    offerCompare,
    diffDays,
    compareRangeStartDate,
    compareRangeEndDate,
    location.pathname
  ]);

  const customerFormatToInternalFormat = dateString => {
    return moment(dateString, DISPLAY_FORMAT).format(QUERY_STRING_DATE_FORMAT);
  };

  const handleSelectStartDate = (_event, { value }) => {
    dispatchSetDataDateRange(
      customerFormatToInternalFormat(value),
      endDate,
      null,
      compareTo
    );
  };

  const handleSelectEndDate = (_event, { value }) => {
    dispatchSetDataDateRange(
      startDate,
      customerFormatToInternalFormat(value),
      null,
      compareTo
    );
  };

  const handleSelectCompareDate = (_event, { value }) => {
    let newCompareTo = null;
    if (compareToValue === COMPARE_STARTING_AT) {
      newCompareTo = customerFormatToInternalFormat(value);
    }

    dispatchSetDataDateRange(startDate, endDate, namedRange, newCompareTo);
  };

  const handleSelectNamedRange = (_event, { value }) => {
    let newNamedRange = value;
    const refDate = moment()
      .subtract(
        excludeAmazonLagPeriod ? Math.max(AMAZON_ATTRIBUTION_DELAY_DAYS, 0) : 0,
        "days"
      )
      .endOf("day");

    let rangeStartDate, rangeEndDate;
    try {
      [rangeStartDate, rangeEndDate] = parseDateRangeParam(
        newNamedRange,
        refDate
      );
    } catch (err) {
      console.error(err.message);
      newNamedRange = DEFAULT_NAMED_DATE_RANGE;
      [rangeStartDate, rangeEndDate] = parseDateRangeParam(
        newNamedRange,
        refDate
      );
    }

    dispatchSetDataDateRange(
      rangeStartDate.format(QUERY_STRING_DATE_FORMAT),
      rangeEndDate.format(QUERY_STRING_DATE_FORMAT),
      newNamedRange,
      compareTo
    );
  };

  const handleCompareTo = (_event, { value }) => {
    let newCompareTo = null;
    if (value === COMPARE_TO_PREVIOUS) {
      newCompareTo = COMPARE_TO_PREVIOUS;
    } else if (value === COMPARE_STARTING_AT) {
      newCompareTo = compareRangeStartDate;
    }

    dispatchSetDataDateRange(startDate, endDate, namedRange, newCompareTo);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "1em"
      }}
    >
      <div>
        <DatePickerLabel>Start Date</DatePickerLabel>
        <DateInput
          name="startDate"
          placeholder="start date"
          dateFormat={DISPLAY_FORMAT}
          value={displayStartDate}
          maxDate={displayEndDate}
          iconPosition="left"
          closable={true}
          onChange={handleSelectStartDate}
        />
      </div>

      <SimpleTooltip
        position="top center"
        tooltip={getIncompleteDataMessage()}
        wide={true}
      >
        <div>
          <DatePickerLabel>End Date</DatePickerLabel>
          <DateInput
            name="endDate"
            placeholder="end date"
            dateFormat={DISPLAY_FORMAT}
            value={displayEndDate}
            minDate={displayStartDate}
            iconPosition="left"
            closable={true}
            onChange={handleSelectEndDate}
          />
        </div>
      </SimpleTooltip>

      <div>
        <DatePickerLabel>Range</DatePickerLabel>
        <Dropdown
          style={{ minWidth: "12em" }}
          value={namedRange}
          placeholder={pluralize(diffDays + 1, "day")}
          selection
          options={METRIC_DATE_RANGE_OPTIONS}
          onChange={handleSelectNamedRange}
        />
      </div>

      {offerCompare && (
        <>
          <div>
            <DatePickerLabel>Compare To</DatePickerLabel>
            <div>
              <SimpleTooltip
                position="top center"
                tooltip={compareToTooltip}
                hoverable={true}
                wide={true}
              >
                <Dropdown
                  style={{ minWidth: "11em", display: "inline-block" }}
                  value={compareToValue}
                  selection
                  options={compareToOptions}
                  onChange={handleCompareTo}
                />
              </SimpleTooltip>
              {compareToValue === COMPARE_STARTING_AT && (
                <div style={{ display: "inline-block", marginLeft: 2 }}>
                  <DateInput
                    name="compareDate"
                    dateFormat={DISPLAY_FORMAT}
                    value={
                      compareToValue === COMPARE_STARTING_AT
                        ? internalFormatToCustomerFormat(compareRangeStartDate)
                        : ""
                    }
                    iconPosition="left"
                    closable={true}
                    onChange={handleSelectCompareDate}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function getIncompleteDataMessage() {
  const amazonIncompleteDataDate = moment()
    .startOf("day")
    .subtract(AMAZON_ATTRIBUTION_DELAY_DAYS + 1, "days");

  return `Data after ${amazonIncompleteDataDate.format(
    "MMM Do YYYY"
  )} is incomplete`;
}

export const setGlobalDateRange = (
  namedRange,
  excludeAmazonLagPeriod,
  dispatchSetDataDateRange,
  compareTo
) => {
  const refDate = moment()
    .subtract(
      excludeAmazonLagPeriod ? Math.max(AMAZON_ATTRIBUTION_DELAY_DAYS, 0) : 0,
      "days"
    )
    .endOf("day");

  let [rangeStartDate, rangeEndDate] = parseDateRangeParam(namedRange, refDate);
  dispatchSetDataDateRange(
    rangeStartDate.format(QUERY_STRING_DATE_FORMAT),
    rangeEndDate.format(QUERY_STRING_DATE_FORMAT),
    namedRange,
    compareTo
  );
};

const GlobalDatePicker = connect(state => {
  const startDate = state.dataStartDate;
  const endDate = state.dataEndDate;
  const namedRange = state.namedRange;
  const compareTo = state.dataCompareTo;

  return {
    startDate,
    endDate,
    namedRange,
    compareTo
  };
}, actions)(_GlobalDatePicker);

export default GlobalDatePicker;

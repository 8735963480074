// source: proto/edge/grpcwebPb/grpcweb_DashboardSession.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_amazon_pb = require('../../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
var proto_entity_site_pb = require('../../../proto/entity/site_pb.js');
goog.object.extend(proto, proto_entity_site_pb);
var proto_entity_siteManagerLink_pb = require('../../../proto/entity/siteManagerLink_pb.js');
goog.object.extend(proto, proto_entity_siteManagerLink_pb);
var proto_entity_user_pb = require('../../../proto/entity/user_pb.js');
goog.object.extend(proto, proto_entity_user_pb);
var proto_entity_billingAccount_pb = require('../../../proto/entity/billingAccount_pb.js');
goog.object.extend(proto, proto_entity_billingAccount_pb);
var proto_common_dataSourceInfo_pb = require('../../../proto/common/dataSourceInfo_pb.js');
goog.object.extend(proto, proto_common_dataSourceInfo_pb);
var proto_common_facebook_pb = require('../../../proto/common/facebook_pb.js');
goog.object.extend(proto, proto_common_facebook_pb);
goog.exportSymbol('proto.grpcwebPb.DashboardSite', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.BillingAccountType', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.BillingAccountType.Option', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardSite.DashboardFeatures', null, global);
goog.exportSymbol('proto.grpcwebPb.DashboardUser', null, global);
goog.exportSymbol('proto.grpcwebPb.GetDashboardSessionReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization', null, global);
goog.exportSymbol('proto.grpcwebPb.GetDashboardSessionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetDashboardSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetDashboardSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetDashboardSessionRequest.displayName = 'proto.grpcwebPb.GetDashboardSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetDashboardSessionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetDashboardSessionReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetDashboardSessionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetDashboardSessionReply.displayName = 'proto.grpcwebPb.GetDashboardSessionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.displayName = 'proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardUser.displayName = 'proto.grpcwebPb.DashboardUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.DashboardSite.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.displayName = 'proto.grpcwebPb.DashboardSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.DashboardFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.DashboardFeatures.displayName = 'proto.grpcwebPb.DashboardSite.DashboardFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.BillingAccountType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.BillingAccountType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.BillingAccountType.displayName = 'proto.grpcwebPb.DashboardSite.BillingAccountType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.displayName = 'proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.displayName = 'proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.displayName = 'proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.displayName = 'proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.displayName = 'proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetDashboardSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetDashboardSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetDashboardSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetDashboardSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetDashboardSessionRequest}
 */
proto.grpcwebPb.GetDashboardSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetDashboardSessionRequest;
  return proto.grpcwebPb.GetDashboardSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetDashboardSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetDashboardSessionRequest}
 */
proto.grpcwebPb.GetDashboardSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetDashboardSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetDashboardSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetDashboardSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetDashboardSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetDashboardSessionRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetDashboardSessionRequest} returns this
 */
proto.grpcwebPb.GetDashboardSessionRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetDashboardSessionReply.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetDashboardSessionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetDashboardSessionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetDashboardSessionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.grpcwebPb.DashboardUser.toObject(includeInstance, f),
    currentSite: (f = msg.getCurrentSite()) && proto.grpcwebPb.DashboardSite.toObject(includeInstance, f),
    authorizedSitesList: jspb.Message.toObjectList(msg.getAuthorizedSitesList(),
    proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.toObject, includeInstance),
    clientSitesList: jspb.Message.toObjectList(msg.getClientSitesList(),
    proto_entity_siteManagerLink_pb.SiteManagerLinkClient.toObject, includeInstance),
    managerSitesList: jspb.Message.toObjectList(msg.getManagerSitesList(),
    proto_entity_siteManagerLink_pb.SiteManagerLinkManager.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply}
 */
proto.grpcwebPb.GetDashboardSessionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetDashboardSessionReply;
  return proto.grpcwebPb.GetDashboardSessionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetDashboardSessionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply}
 */
proto.grpcwebPb.GetDashboardSessionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.DashboardUser;
      reader.readMessage(value,proto.grpcwebPb.DashboardUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.DashboardSite;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.deserializeBinaryFromReader);
      msg.setCurrentSite(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization;
      reader.readMessage(value,proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.deserializeBinaryFromReader);
      msg.addAuthorizedSites(value);
      break;
    case 4:
      var value = new proto_entity_siteManagerLink_pb.SiteManagerLinkClient;
      reader.readMessage(value,proto_entity_siteManagerLink_pb.SiteManagerLinkClient.deserializeBinaryFromReader);
      msg.addClientSites(value);
      break;
    case 5:
      var value = new proto_entity_siteManagerLink_pb.SiteManagerLinkManager;
      reader.readMessage(value,proto_entity_siteManagerLink_pb.SiteManagerLinkManager.deserializeBinaryFromReader);
      msg.addManagerSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetDashboardSessionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetDashboardSessionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetDashboardSessionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpcwebPb.DashboardUser.serializeBinaryToWriter
    );
  }
  f = message.getCurrentSite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.DashboardSite.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.serializeBinaryToWriter
    );
  }
  f = message.getClientSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_entity_siteManagerLink_pb.SiteManagerLinkClient.serializeBinaryToWriter
    );
  }
  f = message.getManagerSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_entity_siteManagerLink_pb.SiteManagerLinkManager.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adwordsAccountIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization;
  return proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdwordsAccountIds(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdwordsAccountIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_name = 2;
 * @return {string}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string adwords_account_ids = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.getAdwordsAccountIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.setAdwordsAccountIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.addAdwordsAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.clearAdwordsAccountIdsList = function() {
  return this.setAdwordsAccountIdsList([]);
};


/**
 * optional bool is_admin = 5;
 * @return {boolean}
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional DashboardUser user = 1;
 * @return {?proto.grpcwebPb.DashboardUser}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.getUser = function() {
  return /** @type{?proto.grpcwebPb.DashboardUser} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardUser, 1));
};


/**
 * @param {?proto.grpcwebPb.DashboardUser|undefined} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
*/
proto.grpcwebPb.GetDashboardSessionReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardSite current_site = 2;
 * @return {?proto.grpcwebPb.DashboardSite}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.getCurrentSite = function() {
  return /** @type{?proto.grpcwebPb.DashboardSite} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardSite, 2));
};


/**
 * @param {?proto.grpcwebPb.DashboardSite|undefined} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
*/
proto.grpcwebPb.GetDashboardSessionReply.prototype.setCurrentSite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.clearCurrentSite = function() {
  return this.setCurrentSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.hasCurrentSite = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SiteAuthorization authorized_sites = 3;
 * @return {!Array<!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization>}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.getAuthorizedSitesList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization>} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
*/
proto.grpcwebPb.GetDashboardSessionReply.prototype.setAuthorizedSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.addAuthorizedSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.grpcwebPb.GetDashboardSessionReply.SiteAuthorization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.clearAuthorizedSitesList = function() {
  return this.setAuthorizedSitesList([]);
};


/**
 * repeated entity.SiteManagerLinkClient client_sites = 4;
 * @return {!Array<!proto.entity.SiteManagerLinkClient>}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.getClientSitesList = function() {
  return /** @type{!Array<!proto.entity.SiteManagerLinkClient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_siteManagerLink_pb.SiteManagerLinkClient, 4));
};


/**
 * @param {!Array<!proto.entity.SiteManagerLinkClient>} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
*/
proto.grpcwebPb.GetDashboardSessionReply.prototype.setClientSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.entity.SiteManagerLinkClient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteManagerLinkClient}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.addClientSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.entity.SiteManagerLinkClient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.clearClientSitesList = function() {
  return this.setClientSitesList([]);
};


/**
 * repeated entity.SiteManagerLinkManager manager_sites = 5;
 * @return {!Array<!proto.entity.SiteManagerLinkManager>}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.getManagerSitesList = function() {
  return /** @type{!Array<!proto.entity.SiteManagerLinkManager>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_siteManagerLink_pb.SiteManagerLinkManager, 5));
};


/**
 * @param {!Array<!proto.entity.SiteManagerLinkManager>} value
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
*/
proto.grpcwebPb.GetDashboardSessionReply.prototype.setManagerSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.entity.SiteManagerLinkManager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteManagerLinkManager}
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.addManagerSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.entity.SiteManagerLinkManager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetDashboardSessionReply} returns this
 */
proto.grpcwebPb.GetDashboardSessionReply.prototype.clearManagerSitesList = function() {
  return this.setManagerSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardUser.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    familyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    id: jspb.Message.getFieldWithDefault(msg, 5, ""),
    globalRoleEnumOption: jspb.Message.getFieldWithDefault(msg, 6, 0),
    uiSettings: (f = msg.getUiSettings()) && proto_entity_user_pb.UISettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardUser}
 */
proto.grpcwebPb.DashboardUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardUser;
  return proto.grpcwebPb.DashboardUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardUser}
 */
proto.grpcwebPb.DashboardUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.GlobalRole.Option} */ (reader.readEnum());
      msg.setGlobalRoleEnumOption(value);
      break;
    case 7:
      var value = new proto_entity_user_pb.UISettings;
      reader.readMessage(value,proto_entity_user_pb.UISettings.deserializeBinaryFromReader);
      msg.setUiSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGlobalRoleEnumOption();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUiSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_entity_user_pb.UISettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string family_name = 1;
 * @return {string}
 */
proto.grpcwebPb.DashboardUser.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string given_name = 2;
 * @return {string}
 */
proto.grpcwebPb.DashboardUser.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.grpcwebPb.DashboardUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.grpcwebPb.DashboardUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.grpcwebPb.DashboardUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional entity.GlobalRole.Option global_role_enum_option = 6;
 * @return {!proto.entity.GlobalRole.Option}
 */
proto.grpcwebPb.DashboardUser.prototype.getGlobalRoleEnumOption = function() {
  return /** @type {!proto.entity.GlobalRole.Option} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.GlobalRole.Option} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.setGlobalRoleEnumOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional entity.UISettings ui_settings = 7;
 * @return {?proto.entity.UISettings}
 */
proto.grpcwebPb.DashboardUser.prototype.getUiSettings = function() {
  return /** @type{?proto.entity.UISettings} */ (
    jspb.Message.getWrapperField(this, proto_entity_user_pb.UISettings, 7));
};


/**
 * @param {?proto.entity.UISettings|undefined} value
 * @return {!proto.grpcwebPb.DashboardUser} returns this
*/
proto.grpcwebPb.DashboardUser.prototype.setUiSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardUser} returns this
 */
proto.grpcwebPb.DashboardUser.prototype.clearUiSettings = function() {
  return this.setUiSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardUser.prototype.hasUiSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.DashboardSite.repeatedFields_ = [10,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    domainName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hubSpotCompanyId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    minGmvUsd: jspb.Message.getFieldWithDefault(msg, 15, 0),
    organizationType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    billingAccountStatusEnumOption: jspb.Message.getFieldWithDefault(msg, 8, 0),
    billingAccountType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    ampdSubscription: (f = msg.getAmpdSubscription()) && proto_entity_billingAccount_pb.AmpdSubscription.toObject(includeInstance, f),
    features: (f = msg.getFeatures()) && proto.grpcwebPb.DashboardSite.DashboardFeatures.toObject(includeInstance, f),
    adwordsAccountsList: jspb.Message.toObjectList(msg.getAdwordsAccountsList(),
    proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.toObject, includeInstance),
    amazonAdvertisingAccountsList: jspb.Message.toObjectList(msg.getAmazonAdvertisingAccountsList(),
    proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.toObject, includeInstance),
    amazonSellerAccountsList: jspb.Message.toObjectList(msg.getAmazonSellerAccountsList(),
    proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.toObject, includeInstance),
    amazonAdvertisingProfilesList: jspb.Message.toObjectList(msg.getAmazonAdvertisingProfilesList(),
    proto_common_amazon_pb.Amazon.AdvertisingProfile.toObject, includeInstance),
    facebookAccount: (f = msg.getFacebookAccount()) && proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.toObject(includeInstance, f),
    clientBillingAccountMax: jspb.Message.getFieldWithDefault(msg, 17, 0),
    clientBillingAccountCount: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite}
 */
proto.grpcwebPb.DashboardSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite;
  return proto.grpcwebPb.DashboardSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite}
 */
proto.grpcwebPb.DashboardSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotCompanyId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinGmvUsd(value);
      break;
    case 16:
      var value = /** @type {!proto.entity.InternalSiteDetails.OrganizationType.Option} */ (reader.readEnum());
      msg.setOrganizationType(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.BillingAccount.Status.Option} */ (reader.readEnum());
      msg.setBillingAccountStatusEnumOption(value);
      break;
    case 19:
      var value = /** @type {!proto.grpcwebPb.DashboardSite.BillingAccountType.Option} */ (reader.readEnum());
      msg.setBillingAccountType(value);
      break;
    case 20:
      var value = new proto_entity_billingAccount_pb.AmpdSubscription;
      reader.readMessage(value,proto_entity_billingAccount_pb.AmpdSubscription.deserializeBinaryFromReader);
      msg.setAmpdSubscription(value);
      break;
    case 7:
      var value = new proto.grpcwebPb.DashboardSite.DashboardFeatures;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.DashboardFeatures.deserializeBinaryFromReader);
      msg.setFeatures(value);
      break;
    case 10:
      var value = new proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinaryFromReader);
      msg.addAdwordsAccounts(value);
      break;
    case 11:
      var value = new proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.deserializeBinaryFromReader);
      msg.addAmazonAdvertisingAccounts(value);
      break;
    case 12:
      var value = new proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.deserializeBinaryFromReader);
      msg.addAmazonSellerAccounts(value);
      break;
    case 13:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfile;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfile.deserializeBinaryFromReader);
      msg.addAmazonAdvertisingProfiles(value);
      break;
    case 21:
      var value = new proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.deserializeBinaryFromReader);
      msg.setFacebookAccount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientBillingAccountMax(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientBillingAccountCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHubSpotCompanyId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMinGmvUsd();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getOrganizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getBillingAccountStatusEnumOption();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getBillingAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getAmpdSubscription();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_entity_billingAccount_pb.AmpdSubscription.serializeBinaryToWriter
    );
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.grpcwebPb.DashboardSite.DashboardFeatures.serializeBinaryToWriter
    );
  }
  f = message.getAdwordsAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAdvertisingAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonSellerAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAdvertisingProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfile.serializeBinaryToWriter
    );
  }
  f = message.getFacebookAccount();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getClientBillingAccountMax();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getClientBillingAccountCount();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.DashboardFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.DashboardFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignsLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privateOnboarding: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ampdProtection: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ampdAutoBidding: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isManaged: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    canCreateGoogleAdsAccount: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    brandOverviewPageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    googleAdsHistoryEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    scrubCampaignNamesWithId: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    facebookIntegrationAccess: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    amazonVendorConnectionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    walmartUiEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    productsPageMisalignedDataEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.DashboardFeatures;
  return proto.grpcwebPb.DashboardSite.DashboardFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignsLimit(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateOnboarding(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmpdProtection(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmpdAutoBidding(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManaged(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanCreateGoogleAdsAccount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBrandOverviewPageEnabled(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoogleAdsHistoryEnabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScrubCampaignNamesWithId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFacebookIntegrationAccess(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmazonVendorConnectionEnabled(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWalmartUiEnabled(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProductsPageMisalignedDataEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.DashboardFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignsLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPrivateOnboarding();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAmpdProtection();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAmpdAutoBidding();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsManaged();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCanCreateGoogleAdsAccount();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getBrandOverviewPageEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getGoogleAdsHistoryEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getScrubCampaignNamesWithId();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getFacebookIntegrationAccess();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAmazonVendorConnectionEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getWalmartUiEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getProductsPageMisalignedDataEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int64 campaigns_limit = 1;
 * @return {number}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getCampaignsLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setCampaignsLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool private_onboarding = 2;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getPrivateOnboarding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setPrivateOnboarding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool ampd_protection = 3;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getAmpdProtection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setAmpdProtection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool ampd_auto_bidding = 4;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getAmpdAutoBidding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setAmpdAutoBidding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_managed = 5;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getIsManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setIsManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool can_create_google_ads_account = 6;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getCanCreateGoogleAdsAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setCanCreateGoogleAdsAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool brand_overview_page_enabled = 7;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getBrandOverviewPageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setBrandOverviewPageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool google_ads_history_enabled = 8;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getGoogleAdsHistoryEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setGoogleAdsHistoryEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool scrub_campaign_names_with_id = 9;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getScrubCampaignNamesWithId = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setScrubCampaignNamesWithId = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool facebook_integration_access = 10;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getFacebookIntegrationAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setFacebookIntegrationAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool amazon_vendor_connection_enabled = 11;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getAmazonVendorConnectionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setAmazonVendorConnectionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool walmart_ui_enabled = 12;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getWalmartUiEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setWalmartUiEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool products_page_misaligned_data_enabled = 13;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.getProductsPageMisalignedDataEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFeatures} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFeatures.prototype.setProductsPageMisalignedDataEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.BillingAccountType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.BillingAccountType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.BillingAccountType}
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.BillingAccountType;
  return proto.grpcwebPb.DashboardSite.BillingAccountType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.BillingAccountType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.BillingAccountType}
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.BillingAccountType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.BillingAccountType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.DashboardSite.BillingAccountType.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  STRIPE: 2,
  ENTERPRISE: 3,
  LINKED: 4,
  INTERNAL: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    invitation: (f = msg.getInvitation()) && proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo;
  return proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation;
      reader.readMessage(value,proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.deserializeBinaryFromReader);
      msg.setInvitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvitation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusEnumOption: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation;
  return proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.common.AdWordsAccount.Invitation.Status} */ (reader.readEnum());
      msg.setStatusEnumOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusEnumOption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.AdWordsAccount.Invitation.Status status_enum_option = 3;
 * @return {!proto.common.AdWordsAccount.Invitation.Status}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.getStatusEnumOption = function() {
  return /** @type {!proto.common.AdWordsAccount.Invitation.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.AdWordsAccount.Invitation.Status} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation.prototype.setStatusEnumOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency_code = 3;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AdwordsInvitation invitation = 4;
 * @return {?proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.getInvitation = function() {
  return /** @type{?proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation, 4));
};


/**
 * @param {?proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.AdwordsInvitation|undefined} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} returns this
*/
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.setInvitation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.clearInvitation = function() {
  return this.setInvitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo.prototype.hasInvitation = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regionEnumOption: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo;
  return proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegionEnumOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegionEnumOption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Amazon.Region.Option region_enum_option = 3;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.getRegionEnumOption = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.prototype.setRegionEnumOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sellingPartnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionEnumOption: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo;
  return proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellingPartnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegionEnumOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSellingPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionEnumOption();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string selling_partner_id = 1;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.prototype.getSellingPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.prototype.setSellingPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Region.Option region_enum_option = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.prototype.getRegionEnumOption = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo.prototype.setRegionEnumOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasAuthToken: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    authorizedAdAccountsList: jspb.Message.toObjectList(msg.getAuthorizedAdAccountsList(),
    proto_common_facebook_pb.Facebook.Account.AuthorizedAdAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo;
  return proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAuthToken(value);
      break;
    case 2:
      var value = new proto_common_facebook_pb.Facebook.Account.AuthorizedAdAccount;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.Account.AuthorizedAdAccount.deserializeBinaryFromReader);
      msg.addAuthorizedAdAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasAuthToken();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAuthorizedAdAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_common_facebook_pb.Facebook.Account.AuthorizedAdAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool has_auth_token = 1;
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.getHasAuthToken = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.setHasAuthToken = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated common.Facebook.Account.AuthorizedAdAccount authorized_ad_accounts = 2;
 * @return {!Array<!proto.common.Facebook.Account.AuthorizedAdAccount>}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.getAuthorizedAdAccountsList = function() {
  return /** @type{!Array<!proto.common.Facebook.Account.AuthorizedAdAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_facebook_pb.Facebook.Account.AuthorizedAdAccount, 2));
};


/**
 * @param {!Array<!proto.common.Facebook.Account.AuthorizedAdAccount>} value
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} returns this
*/
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.setAuthorizedAdAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Facebook.Account.AuthorizedAdAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.Account.AuthorizedAdAccount}
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.addAuthorizedAdAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Facebook.Account.AuthorizedAdAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} returns this
 */
proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo.prototype.clearAuthorizedAdAccountsList = function() {
  return this.setAuthorizedAdAccountsList([]);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contact_email = 4;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string domain_name = 6;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string hub_spot_company_id = 14;
 * @return {string}
 */
proto.grpcwebPb.DashboardSite.prototype.getHubSpotCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setHubSpotCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 min_gmv_usd = 15;
 * @return {number}
 */
proto.grpcwebPb.DashboardSite.prototype.getMinGmvUsd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setMinGmvUsd = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional entity.InternalSiteDetails.OrganizationType.Option organization_type = 16;
 * @return {!proto.entity.InternalSiteDetails.OrganizationType.Option}
 */
proto.grpcwebPb.DashboardSite.prototype.getOrganizationType = function() {
  return /** @type {!proto.entity.InternalSiteDetails.OrganizationType.Option} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.entity.InternalSiteDetails.OrganizationType.Option} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setOrganizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional entity.BillingAccount.Status.Option billing_account_status_enum_option = 8;
 * @return {!proto.entity.BillingAccount.Status.Option}
 */
proto.grpcwebPb.DashboardSite.prototype.getBillingAccountStatusEnumOption = function() {
  return /** @type {!proto.entity.BillingAccount.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.BillingAccount.Status.Option} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setBillingAccountStatusEnumOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional BillingAccountType.Option billing_account_type = 19;
 * @return {!proto.grpcwebPb.DashboardSite.BillingAccountType.Option}
 */
proto.grpcwebPb.DashboardSite.prototype.getBillingAccountType = function() {
  return /** @type {!proto.grpcwebPb.DashboardSite.BillingAccountType.Option} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.BillingAccountType.Option} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setBillingAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional entity.AmpdSubscription ampd_subscription = 20;
 * @return {?proto.entity.AmpdSubscription}
 */
proto.grpcwebPb.DashboardSite.prototype.getAmpdSubscription = function() {
  return /** @type{?proto.entity.AmpdSubscription} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.AmpdSubscription, 20));
};


/**
 * @param {?proto.entity.AmpdSubscription|undefined} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setAmpdSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearAmpdSubscription = function() {
  return this.setAmpdSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.prototype.hasAmpdSubscription = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional DashboardFeatures features = 7;
 * @return {?proto.grpcwebPb.DashboardSite.DashboardFeatures}
 */
proto.grpcwebPb.DashboardSite.prototype.getFeatures = function() {
  return /** @type{?proto.grpcwebPb.DashboardSite.DashboardFeatures} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardSite.DashboardFeatures, 7));
};


/**
 * @param {?proto.grpcwebPb.DashboardSite.DashboardFeatures|undefined} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearFeatures = function() {
  return this.setFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.prototype.hasFeatures = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated DashboardAdwordsAccountInfo adwords_accounts = 10;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>}
 */
proto.grpcwebPb.DashboardSite.prototype.getAdwordsAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo, 10));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setAdwordsAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo}
 */
proto.grpcwebPb.DashboardSite.prototype.addAdwordsAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearAdwordsAccountsList = function() {
  return this.setAdwordsAccountsList([]);
};


/**
 * repeated DashboardAmazonAdvertisingAccountInfo amazon_advertising_accounts = 11;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>}
 */
proto.grpcwebPb.DashboardSite.prototype.getAmazonAdvertisingAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, 11));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setAmazonAdvertisingAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo}
 */
proto.grpcwebPb.DashboardSite.prototype.addAmazonAdvertisingAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearAmazonAdvertisingAccountsList = function() {
  return this.setAmazonAdvertisingAccountsList([]);
};


/**
 * repeated DashboardAmazonSellerAccountInfo amazon_seller_accounts = 12;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>}
 */
proto.grpcwebPb.DashboardSite.prototype.getAmazonSellerAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo, 12));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setAmazonSellerAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo}
 */
proto.grpcwebPb.DashboardSite.prototype.addAmazonSellerAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearAmazonSellerAccountsList = function() {
  return this.setAmazonSellerAccountsList([]);
};


/**
 * repeated common.Amazon.AdvertisingProfile amazon_advertising_profiles = 13;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfile>}
 */
proto.grpcwebPb.DashboardSite.prototype.getAmazonAdvertisingProfilesList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfile, 13));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfile>} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setAmazonAdvertisingProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.grpcwebPb.DashboardSite.prototype.addAmazonAdvertisingProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.common.Amazon.AdvertisingProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearAmazonAdvertisingProfilesList = function() {
  return this.setAmazonAdvertisingProfilesList([]);
};


/**
 * optional DashboardFacebookAccountInfo facebook_account = 21;
 * @return {?proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo}
 */
proto.grpcwebPb.DashboardSite.prototype.getFacebookAccount = function() {
  return /** @type{?proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo, 21));
};


/**
 * @param {?proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo|undefined} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
*/
proto.grpcwebPb.DashboardSite.prototype.setFacebookAccount = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.clearFacebookAccount = function() {
  return this.setFacebookAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.DashboardSite.prototype.hasFacebookAccount = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional int32 client_billing_account_max = 17;
 * @return {number}
 */
proto.grpcwebPb.DashboardSite.prototype.getClientBillingAccountMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setClientBillingAccountMax = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 client_billing_account_count = 18;
 * @return {number}
 */
proto.grpcwebPb.DashboardSite.prototype.getClientBillingAccountCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.DashboardSite} returns this
 */
proto.grpcwebPb.DashboardSite.prototype.setClientBillingAccountCount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


goog.object.extend(exports, proto.grpcwebPb);
